import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import thumb from "../../assets/images/blogs/thumb5.webp";
import BannerImg from "../../assets/images/banner.webp";
import ScrollToTop from "../../components/ScrollToTop";
import { Link } from "react-router-dom";
import thumb9 from "../../assets/images/blogs/thumb9.webp";
export default function NextGenerationWaterIonizer() {
  return (
    <Layout>
      <Helmet>
        <title>
          Next generation water Ionizer machine India offers healthy drinking
          water.
        </title>
        <meta name="description" content="" />
      </Helmet>
      <ScrollToTop />
      <section className="inner__banner blog_detail_banner">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" />
        </picture>
        <Container>
          <Row>
            <Col>
              <h1>
                <span>Next generation water Ionizer machine:</span>India offers
                healthy drinking water.
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="p-0">
        <Container>
          <Row>
            <Col>
              <Breadcrumb className="blog_breadcrumb">
                <Breadcrumb.Item active>
                  <Link to="/blog" className="text-decoration-none">
                    Blog
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Next generation water Ionizer machine India offers healthy
                  drinking water.
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog_detail__wrapper">
        <Col
          md={8}
          className="shadow rounded-3 m-auto bg-white overflow-hidden"
        >
          <Row>
            <picture>
              <source srcSet={thumb9} type="image/webp" />
              <img src={thumb9} className="w-100" alt="" />
            </picture>
          </Row>
          <Row>
            <Col className="p-4">
              {/* COntent from here  */}
              <span className="tagline">
                Next generation water Ionizer machine
              </span>
              <h1 className="blog_title">
                India offers healthy drinking water.
              </h1>
              <div className="blog_info">
                <strong>Ionia Team - </strong>
                <span>February 22, 2023</span>
              </div>
              <div className="content">
                <p>
                  Growing environmental pollution has been a big concern for
                  developing countries like India. Pollution is spreading all
                  over the globe, making our drinking water contaminated. We may
                  eliminate the ground pollution occurring due to garbage and
                  chemicals, but air pollution cannot be restricted. It can
                  cross the borders and contaminate the water bodies.
                </p>
                <p>
                  Drinking alkaline water causes several health issues. The
                  government is still working on ensuring the water bodies stay
                  contamination-free, but the solution could be more effective
                  than expected. Therefore, the experts are advised to use the
                  water filtration process at home to remove the sediments
                  before drinking.
                </p>
                <p>
                  <a href="products"> Water Ionizer Machine India</a> is built
                  according to the conditions in the region. Asian subcontinents
                  are mainly affected by air and ground pollution due to the
                  rise in the population. Use of the water ionizer machine is a
                  must to maintain excellent health.
                </p>
                <h3>
                  Following are the benefits you get from the water Ionizer
                  Machine India.
                </h3>
                <ul>
                  <li>
                    <span>Clean Alkaline water: </span>The Water Ionizer Machine
                    India is the most potent equipment to purify water at home.
                    Any harmful substances such as chlorine and lead from the
                    water will be removed. All chemicals are easily removed,
                    offering you clean drinking water. Using the water
                    purification machine at home, you can keep your family safe
                    with pure, drinkable water.
                  </li>
                  <li>
                    Minerals: Water Ionizer Machine India performs the
                    purification of the water, removing unnatural substances and
                    sediments from the water. After water purification, fresh
                    minerals are added to the water to improve the water
                    quality.
                  </li>
                  <li>
                    Improve health: As we know, 75% of our body is made of
                    water. Drinking pure and contamination-free water increases
                    our health. The vital organ performs neatly and supports the
                    immune system.
                  </li>
                  <li>
                    Eliminate oxidation: The body performs various activities
                    during the day. Your guts produce numerous types of
                    chemicals in the process. Rising oxidation in your body is
                    harmful to health. Therefore, drinking clean water frees
                    your body from oxidation and relieves you from stress..
                  </li>
                  <li>
                    Repair Cell Damage: When it comes to improving the
                    cells&#39; health, water is an essential component. Drinking
                    mineral-rich water helps repair cells while adding extra
                    electrons to the body.
                  </li>
                </ul>
                <h3>In conclusion</h3>
                <p>
                  Water Ionizer Machine India is the perfect solution to all
                  your health problems caused by drinking contaminated water.
                  Get the best quality water ionizer machine from{" "}
                  <a href="/"> Ionia India</a> that purifies, adds minerals, and
                  significantly improves water quality. Try today and see the
                  difference yourself.
                </p>
              </div>
              {/* COntent END here  */}
            </Col>
          </Row>
        </Col>
      </section>
    </Layout>
  );
}
