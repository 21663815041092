import { Carousel, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import productBg from "../assets/images/performace-bg.webp";
import img2 from "../assets/images/products/x-blue/img2.webp";
import img4 from "../assets/images/products/en-7p/img4.webp";
import img3 from "../assets/images/products/io-400/img3.webp";
import superImg from "../assets/images/rnd/super-strong-img.webp";
import sterilized1 from "../assets/images/rnd/sterilized.webp";
import sterilized2 from "../assets/images/rnd/sterilized2.webp";
import superItem from "../assets/images/rnd/part1.webp";
import superItem2 from "../assets/images/rnd/part2.webp";
import superItem3 from "../assets/images/rnd/part3.webp";
import superItem4 from "../assets/images/rnd/part4.webp";
import superItem5 from "../assets/images/rnd/part5.webp";
import tftImg from "../assets/images/rnd/tft-img.webp";
import dualFilter from "../assets/images/rnd/dual-filter.webp";
import perfImg1 from "../assets/images/rnd/performance1.webp";
import perfImg2 from "../assets/images/rnd/performance2.webp";
import perfImg3 from "../assets/images/rnd/performance3.webp";
import RndLayout from "../components/RndLayout";
import x5p from "../assets/images/x5p.webp";
import ledouter from "../assets/images/ledouter.webp";
// product image slider images
import img6 from "../assets/images/products/sm-S230/img6.webp";
import img7 from "../assets/images/products/sm-S230/img7.webp";
import img5 from "../assets/images/products/sm-S230/img5.webp";
import sms1 from "../assets/images/products/sm-S230/sms1.webp";
import sms2 from "../assets/images/products/sm-S230/sms2.webp";
import sms3 from "../assets/images/products/sm-S230/sms3.webp";
import dftimage from "../assets/images/products/en-7p/dft-image.webp";
import tapfront from "../assets/images/products/io-400/tapfront.webp";
import en7pfront from "../assets/images/products/en-7p/en7pfront.webp";
import sevenplates from "../assets/images/products/en-7p/seven-plates.webp";
import smpspower from "../assets/images/products/en-7p/smps-power.webp";
import xbluefront from "../assets/images/products/x-blue/xblue-front.webp";
import performtap from "../assets/images/products/io-400/perform-tap.webp";
import xblue2 from "../assets/images/products/x-blue/xblue2.webp";
import xblue3 from "../assets/images/products/x-blue/xblue3.webp";
import xblue4 from "../assets/images/products/x-blue/xblue4.webp";
import xblue5 from "../assets/images/products/x-blue/xblue5.webp";
import xblue6 from "../assets/images/products/x-blue/xblue6.webp";
import en7p1 from "../assets/images/products/en-7p/en7p1.webp";
import en7p4 from "../assets/images/products/en-7p/en7p4.webp";
import en7p2 from "../assets/images/products/en-7p/en7p2.webp";
import en7p5 from "../assets/images/products/en-7p/en7p5.webp";
import iotap2 from "../assets/images/products/io-400/iotap2.webp";
import io4001 from "../assets/images/products/io-400/io4001.webp";
import io4002 from "../assets/images/products/io-400/io4002.webp";
import io4003 from "../assets/images/products/io-400/io4003.webp";
import io4004 from "../assets/images/products/io-400/io4004.webp";
//finish
import fiveplate from "../assets/images/products/x-blue/five-plate.webp";
import monitor from "../assets/images/products/x-blue/monitor.webp";
import oval from "../assets/images/products/x-blue/oval.webp";
import risearrow from "../assets/images/products/x-blue/rise-arrow.webp";
import filterimage from "../assets/images/products/x-blue/filter__image.webp";
import sevenp from "../assets/images/products/io-400/sevenp.webp";
import cylinder from "../assets/images/products/io-400/cylinder.webp";
import touch from "../assets/images/products/io-400/touch.webp";
import alkaline from "../assets/images/products/io-400/Alkaline.webp";
import tap from "../assets/images/products/io-400/tap.webp";
import filtration from "../assets/images/products/io-400/filtration.webp";
import colorled from "../assets/images/products/x-blue/color_led.webp";
import undersink from "../assets/images/products/io-400/undersink.webp";
import filtrationprocess from "../assets/images/products/io-400/filtration-process.webp";
import tapdescrip from "../assets/images/products/io-400/tap-descrip.webp";
import TechnologySlider from "../components/TechnologySlider";
export default function RnD() {
  return (
    <>
      <RndLayout>
        <section className="rnd_product__wrapper pt-3 pb-0">
          <Container fluid className="p-0">
            <Tabs
              defaultActiveKey="x-blue"
              id="research-devlepoment-tab-example"
              // className={`mb-4 ${headerClassName}`}
              className={`mb-4`}
            >
              {/* Xblue Detail  */}
              <Tab eventKey="x-blue" title="X-BLUE">
                <section
                  className="product_detail_info silder__wrapper"
                  style={{ backgroundImage: `url(${productBg})` }}
                >
                  <Container>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <Carousel indicators={false} pause={false}>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={img2} type="image/webp" />
                                    <img
                                      src={img2}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={xblue2} type="image/webp" />
                                    <img
                                      src={xblue2}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={xblue3} type="image/webp" />
                                    <img
                                      src={xblue3}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={xblue4} type="image/webp" />
                                    <img
                                      src={xblue4}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={xblue5} type="image/webp" />
                                    <img
                                      src={xblue5}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={xblue6} type="image/webp" />
                                    <img
                                      src={xblue6}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                        </Carousel>
                      </Col>
                      <Col md={6}>
                        <div className="title__wrap regular mb-5 mt-3 mt-md-0">
                          <h2>Ionia X-BLUE – Alkaline Water Machine</h2>
                        </div>
                        <div className="tag">
                          <h4>
                            <strong>Category</strong> : Water Ionizer
                          </h4>
                          <h4>
                            <strong>Product ID</strong> : 1734
                          </h4>
                        </div>
                        <ul className="product_detail_info_list">
                          <li>5 plate (Planized Titanium)</li>
                          <li>Luxury exterior design</li>
                          <li>
                            Automatic reversed cleaning system causes platinum
                            coated with titanium electrode to have at least 20
                            years longevity
                          </li>
                          <li>
                            Color LCD display easily identifies 3 different
                            functions
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <TechnologySlider />

                <section className="lemon_water__wrapper pb-0">
                  <Container>
                    <Row>
                      <Col md={7} className="m-auto text-center text-white">
                        <div className="title__wrap regular center_aligned">
                          <h2 className="text-white">Now a good water smart</h2>
                        </div>
                        <p>
                          More perfect x-blue for you Alkaline Ionic water, a
                          good living water ionizer
                        </p>
                        <Col md={12} className="mt-4">
                          <picture>
                            <source srcSet={xbluefront} type="image/webp" />
                            <img src={xbluefront} alt="" className="w-75" />
                          </picture>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="super_strong__wrapper">
                  <Container>
                    <Row>
                      <Col md={3}>
                        <div className="border rounded-2 text-center p-4 bg-white h-100">
                          <picture>
                            <source srcSet={fiveplate} type="image/webp" />
                            <img
                              src={fiveplate}
                              alt=""
                              className="w-50 mb-3 box__wrapper"
                            />
                          </picture>
                          <h5>
                            <strong>5P Platinum</strong>
                          </h5>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="border rounded-2 text-center p-4 bg-white h-100">
                          <picture>
                            <source srcSet={oval} type="image/webp" />
                            <img
                              src={oval}
                              alt=""
                              className="w-50 mb-3 box__wrapper"
                            />
                          </picture>
                          <h5>
                            <strong>
                              All-in-one type of water supply valve
                            </strong>
                          </h5>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="border rounded-2 text-center p-4 bg-white h-100">
                          <picture>
                            <source srcSet={monitor} type="image/webp" />
                            <img
                              src={monitor}
                              alt=""
                              className="w-50 mb-3 box__wrapper"
                            />
                          </picture>
                          <h5>
                            <strong>3 Color LED Display</strong>
                          </h5>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="border rounded-2 text-center p-4 bg-white h-100">
                          <picture>
                            <source srcSet={risearrow} type="image/webp" />
                            <img
                              src={risearrow}
                              alt=""
                              className="w-50 mb-3 box__wrapper"
                            />
                          </picture>
                          <h5>
                            <strong>
                              Generation of ionized water step-by-step
                            </strong>
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="performance__wrapper">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>Performance of Electrodes</h2>
                        </div>
                        <p>
                          In their latest models are five of the most advanced
                          platinum-titanium electrodes in the world.
                        </p>
                        <p>
                          When a cross section of an electrode is examined at
                          700 times magnification, you can see that the
                          electrodes are now covered in a super fine mesh with
                          very distinct points and valleys.
                        </p>
                        <p>
                          This greatly increases the surface area withut having
                          to increase the size.
                        </p>
                        <p>
                          We guarantee that no other models, regardless of
                          price, will produce under similar conditions, such a
                          high and low pH or ORP (Oxygen reduction Potentail).
                        </p>
                      </Col>
                    </Row>
                  </Container>
                  <picture>
                    <source srcSet={perfImg1} type="image/webp" />
                    <img src={perfImg1} alt="" className="img1" />
                  </picture>
                  <picture>
                    <source srcSet={perfImg2} type="image/webp" />
                    <img src={perfImg2} alt="" className="img2" />
                  </picture>
                  <picture>
                    <source srcSet={perfImg3} type="image/webp" />
                    <img src={perfImg3} alt="" className="img3" />
                  </picture>
                </section>

                <section className="tft__wrapper">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>5P Platinum titanium Plates</h2>
                        </div>
                        <p>
                          5 proprietary electrodes with 4 layers of Platinum
                          ensuring the best ionizer enriched water
                        </p>
                        <picture>
                          <source srcSet={x5p} type="image/webp" />
                          <img src={x5p} alt="" className="w-100 mt-3" />
                        </picture>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="color_led_display">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>Color LED Display</h2>
                        </div>
                        <p className="led_list">
                          It is designed to generated ionized water ranged at
                          stage from 1 through 4 by manipulating buttons in the
                          conntrol panel in the front side 3Color LED Display
                          easily identifies 3 different functions.
                        </p>
                        <Row className="align-items-center">
                          <Col md={6}>
                            <ul>
                              <li className="list-style-none">
                                <strong>Blue:</strong> Alkaline Water
                              </li>
                              <li className="list-style-none">
                                <strong>Orange:</strong> Acidic Water
                              </li>
                              <li className="list-style-none">
                                <strong>Green:</strong> Filtered Water
                              </li>
                            </ul>
                          </Col>
                          <Col md={6}>
                            <picture>
                              <source srcSet={colorled} type="image/webp" />
                              <img
                                src={colorled}
                                alt=""
                                className="w-100 mt-2"
                              />
                            </picture>
                          </Col>
                        </Row>
                        <picture>
                          <source srcSet={ledouter} type="image/webp" />
                          <img src={ledouter} alt="" className="w-50 mt-2" />
                        </picture>
                        <h2>
                          <strong>All-in-one type of water suply valve</strong>
                        </h2>
                        <p>
                          It is easy to use and convenient to control the fine
                          flow rate as the water suply valve
                          <strong>(valve to turn on/off)</strong> is integrated
                          to product body
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="super_strong__wrapper pb-0 mb-">
                  <Container>
                    <Row>
                      <Col md={7} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>
                            The difference of filter technology makes water
                            cleaner and healthier.
                          </h2>
                        </div>
                        <p>
                          It filter the harmful substances and keeps the
                          beneficial minerals in water to make water clean and
                          rich in minerals.
                        </p>
                        <Col>
                          <picture>
                            <source
                              srcSet={filtrationprocess}
                              type="image/webp"
                            />
                            <img
                              src={filtrationprocess}
                              alt=""
                              className="w-100 h-100"
                            />
                          </picture>
                        </Col>
                        <Col md={12} className="mt-4">
                          <h5>
                            <strong>High-Performance Composite Filter</strong>
                          </h5>
                          <picture>
                            <source srcSet={filterimage} type="image/webp" />
                            <img src={filterimage} alt="" className="w-100" />
                          </picture>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Tab>

              {/* En 7p  */}
              <Tab eventKey="en-7p" title="EN-7P">
                <section
                  className="product_detail_info silder__wrapper"
                  style={{ backgroundImage: `url(${productBg})` }}
                >
                  <Container>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <Carousel indicators={false} pause={false}>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={img4} type="image/webp" />
                                    <img
                                      src={img4}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={en7p2} type="image/webp" />
                                    <img
                                      src={en7p2}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={en7p5} type="image/webp" />
                                    <img
                                      src={en7p5}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={en7p4} type="image/webp" />
                                    <img
                                      src={en7p4}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={en7p1} type="image/webp" />
                                    <img
                                      src={en7p1}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                        </Carousel>
                      </Col>
                      <Col md={6}>
                        <div className="title__wrap regular mb-5 mt-3 mt-md-0">
                          <h2>Ionia EN-7P – Alkaline Water Machine</h2>
                        </div>
                        <div className="tag">
                          <h4>
                            <strong>Category</strong> : Water Ionizer
                          </h4>
                          <h4>
                            <strong>Product ID</strong> : 1735
                          </h4>
                        </div>
                        <ul className="product_detail_info_list">
                          <li>7 Platinum-Titanium Plates</li>
                          <li>Automatic Draining System</li>
                          <li>250W SMPS Power Control System</li>
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <TechnologySlider />

                <section className="fruit_water__wrapper pb-0">
                  <Container>
                    <Row>
                      <Col md={7} className="m-auto text-center text-white">
                        <div className="title__wrap regular center_aligned">
                          <h2 className="text-white">Now a good water smart</h2>
                        </div>
                        <p>
                          More perfect EN-7P for you Alkaline Ionic water, a
                          good living water ionizer
                        </p>
                        <Col md={12} className="mt-4">
                          <picture>
                            <source srcSet={en7pfront} type="image/webp" />
                            <img src={en7pfront} alt="" className="w-50" />
                          </picture>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="super_strong__wrapper">
                  <Container>
                    <Row>
                      <Col md={3}>
                        <div className="border rounded-2 text-center p-4 bg-white h-100">
                          <picture>
                            <source srcSet={sevenp} type="image/webp" />
                            <img src={sevenp} alt="" className=" mb-3" />
                          </picture>
                          <h5>7P Platinum</h5>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="border rounded-2 text-center p-4 bg-white h-100">
                          <picture>
                            <source srcSet={oval} type="image/webp" />
                            <img src={oval} alt="" className=" mb-3" />
                          </picture>
                          <h5>All-in-one type of water supply valve</h5>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="border rounded-2 text-center p-4 bg-white h-100">
                          <picture>
                            <source srcSet={cylinder} type="image/webp" />
                            <img src={cylinder} alt="" className=" mb-3" />
                          </picture>
                          <h5>Dual filtration</h5>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="border rounded-2 text-center p-4 bg-white h-100">
                          <picture>
                            <source srcSet={risearrow} type="image/webp" />
                            <img src={risearrow} alt="" className=" mb-3" />
                          </picture>
                          <h5>Generation of ionized water step by step</h5>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
                {/* done */}
                <section className="performance__wrapper">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>Performance of Electrodes</h2>
                        </div>
                        <p>
                          In their latest models are seven of the most advanced
                          platinum-titanium electrodes in the world.
                        </p>
                        <p>
                          When a cross section of an electrode is examined at
                          700 times magnification, you can see that the
                          electrodes are now covered in a super fine mesh with
                          very distinct points and valleys.
                        </p>
                        <p>
                          This greatly increases the surface area withut having
                          to increase the size.
                        </p>
                        <p>
                          We guarantee that no other models, regardless of
                          price, will produce under similar conditions, such a
                          high and low pH or ORP (Oxygen reduction Potentail).
                        </p>
                      </Col>
                    </Row>
                  </Container>
                  <picture>
                    <source srcSet={perfImg1} type="image/webp" />
                    <img src={perfImg1} alt="" className="img1" />
                  </picture>
                  <picture>
                    <source srcSet={perfImg2} type="image/webp" />
                    <img src={perfImg2} alt="" className="img2" />
                  </picture>
                  <picture>
                    <source srcSet={perfImg3} type="image/webp" />
                    <img src={perfImg3} alt="" className="img3" />
                  </picture>
                </section>

                <section className="tft__wrapper">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>7P Platinum - Titanium Plates</h2>
                        </div>
                        <p>
                          7 proprietary electrodes with 6 layers of platinum
                          ensuring the best ionizer enriched water.
                        </p>
                        <picture>
                          <source srcSet={sevenplates} type="image/webp" />
                          <img
                            src={sevenplates}
                            alt=""
                            className="w-100 mt-3"
                          />
                        </picture>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="super_strong__wrapper pb-0">
                  <Container>
                    <Row>
                      <Col md={7} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>SMPS Power Control System</h2>
                        </div>
                        <p>
                          Applied SMPS Power Equipment - more stable than
                          existing trans methods Appiled Maximum Output 250W-
                          maximum output existing trans method 150W. We applied
                          250W so that 7P machine can operate a stable state
                        </p>
                        <Col md={12} className="mt-4">
                          <picture>
                            <source srcSet={smpspower} type="image/webp" />
                            <img src={smpspower} alt="" className="w-100" />
                          </picture>
                          <br />
                          <h2>
                            <strong>
                              All-in-one type of water supply valve
                            </strong>
                          </h2>
                          <p>
                            It is easy to use and convenient to control the fine
                            flow rate as the water suply valve{" "}
                            <strong>(valve to turn on/off)</strong> is
                            integrated to product body
                          </p>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>
                {/*one image is left */}
                <section className="differ__water__image pb-0">
                  <Container>
                    <Row>
                      <Col md={7} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>
                            <stromg>Dual Filtration</stromg>
                          </h2>
                          <h2>
                            The difference of filter technology makes water
                            cleaner and healthier.
                          </h2>
                        </div>
                        <p>
                          It filter the harmful substances and keeps the
                          beneficial minerals in water to make water clean and
                          rich in minerals.
                        </p>
                        <Col md={12} className="mt-4">
                          <picture>
                            <source srcSet={dftimage} type="image/webp" />
                            <img src={dftimage} alt="" className="w-100" />
                          </picture>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="pt-5 pb-5 bg-light filtration__wrapper">
                  <Container>
                    <Row>
                      <Col md={12} className="mt-4 text-center">
                        <picture>
                          <source
                            srcSet={filtrationprocess}
                            type="image/webp"
                          />
                          <img
                            src={filtrationprocess}
                            alt=""
                            className="w-75"
                          />
                        </picture>
                      </Col>
                      <Col md={12} className="mt-4 text-center">
                        <h5>
                          <strong>High-Performance Composite Filter</strong>
                        </h5>
                        <picture>
                          <source srcSet={filterimage} type="image/webp" />
                          <img src={filterimage} alt="" className="" />
                        </picture>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Tab>

              {/* io 400  */}
              <Tab eventKey="io-400u" title="IO-400U">
                <section
                  className="product_detail_info silder__wrapper"
                  style={{ backgroundImage: `url(${productBg})` }}
                >
                  <Container>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <Carousel indicators={false} pause={false}>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    <source srcSet={img3} type="image/webp" />
                                    <img
                                      src={img3}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={io4001} type="image/webp" />
                                    <img
                                      src={io4001}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={iotap2} type="image/webp" />
                                    <img
                                      src={iotap2}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={io4002} type="image/webp" />
                                    <img
                                      src={io4002}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={io4003} type="image/webp" />
                                    <img
                                      src={io4003}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={io4004} type="image/webp" />
                                    <img
                                      src={io4004}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                        </Carousel>
                      </Col>
                      <Col md={6}>
                        <div className="title__wrap regular mb-5 mt-3 mt-md-0">
                          <h2>Ionia IO-400U – Alkaline Water Machine</h2>
                        </div>
                        <div className="tag">
                          <h4>
                            <strong>Category</strong> : Water Ionizer
                          </h4>
                          <h4>
                            <strong>Product ID</strong> : 503
                          </h4>
                        </div>
                        <ul className="product_detail_info_list">
                          <li>
                            DARC System - Automatic change of flow path (6
                            Overseas Patents)
                          </li>
                          <li>
                            Alarm message and ionization disabled during hot
                            water inflow.
                          </li>
                          <li>
                            Exact calculation of filter durability with Flux
                            control.
                          </li>
                          <li>Extended range of pH selection and control.</li>
                          <li>Modern Design.</li>
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <TechnologySlider />

                <section className="watry_water__wrapper pb-0">
                  <Container>
                    <Row>
                      <Col md={7} className="m-auto text-center text-white">
                        <div className="title__wrap regular center_aligned">
                          <h2 className="text-white">Now a good water smart</h2>
                        </div>
                        <p>
                          More perfect Io-400U for you Alkaline Ionic water, a
                          good living water ionizer
                        </p>
                        <Col md={12} className="mt-4">
                          <picture>
                            <source srcSet={tapfront} type="image/webp" />
                            <img src={tapfront} alt="" className="w-75" />
                          </picture>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="super_strong__wrapper">
                  <Container>
                    <Row className="justify-content-center">
                      <Col md={4} className="mb-3">
                        <div className="border rounded-2 text-center p-4 bg-white h-100 ">
                          <picture>
                            <source srcSet={touch} type="image/webp" />
                            <img src={touch} alt="" className=" mb-3" />
                          </picture>
                          <h5>
                            <strong>Touch Panel Type</strong>
                          </h5>
                          <p>
                            The touch control panel is the integral part of
                            ionia's advanced technology.
                          </p>
                          <p>Simple design focused on user's Convenience.</p>
                        </div>
                      </Col>
                      <Col md={4} className="mb-3">
                        <div className="border rounded-2 text-center p-4 bg-white h-100 ">
                          <picture>
                            <source srcSet={alkaline} type="image/webp" />
                            <img src={alkaline} alt="" className="mb-3" />
                          </picture>
                          <h5>
                            <strong>Module Display</strong>
                          </h5>
                          <p>
                            You can identifuy kind of water with your own eyes
                            through the indication lamp which water among
                            alkaline water, Acidic water and purified water is
                            being generated.
                          </p>
                        </div>
                      </Col>
                      <Col md={4} className="mb-3">
                        <div className="border rounded-2 text-center p-4 bg-white h-100 ">
                          <picture>
                            <source srcSet={tap} type="image/webp" />
                            <img src={tap} alt="" className="mb-3" />
                          </picture>
                          <h5>
                            <strong>Under Sink Style</strong>
                          </h5>
                          <p>
                            The space of current ionizer should be minimized in
                            the kitchen.
                          </p>
                          <p>Only Faucet can be installed on the skin.</p>
                          <p>Simplicity is done.</p>
                        </div>
                      </Col>
                      <Col md={4} className="mb-3">
                        <div className="border rounded-2 text-center p-4 bg-white h-100 ">
                          <picture>
                            <source srcSet={sevenp} type="image/webp" />
                            <img src={sevenp} alt="" className="mb-3" />
                          </picture>
                          <h5>
                            <strong>Solenoid Type+7P Water Cell</strong>
                          </h5>
                          <p>
                            Solenoid type (DARC system )technology with the
                            patent in 6 countries worldwide.(Korea, Japan, USA,
                            China, Singapore, Malasiya)Water cell in order
                            tomake the best alkaline water with 7 electrodes
                            plates.
                          </p>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="border rounded-2 text-center p-4 bg-white">
                          <picture>
                            <source srcSet={filtration} type="image/webp" />
                            <img src={filtration} alt="" className="mb-3" />
                          </picture>
                          <h5>
                            <strong>
                              Genuine Certificate High Performance Filter
                              (options)
                            </strong>
                          </h5>
                          <p>
                            The high performance and complex filter by the
                            filtering from harmful substance of raw water. It is
                            made by various materials according to the seller
                            and user.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="conveniently_wrapper pb-0">
                  <Container>
                    <Row>
                      <Col md={12} className="mt-4 text-center">
                        <div className=" title__wrap regular center_aligned">
                          <h2>Conveniently</h2>
                        </div>
                        <picture>
                          <source srcSet={tapdescrip} type="image/webp" />
                          <img src={tapdescrip} alt="" className="w-75" />
                        </picture>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="performance__wrapper">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>Performance of Electrodes</h2>
                        </div>
                        <p>
                          In their latest models are five of the most advanced
                          platinum-titanium electrodes in the world.
                        </p>
                        <p>
                          When a cross section of an electrode is examined at
                          700 times magnification, you can see that the
                          electrodes are now covered in a super fine mesh with
                          very distinct points and valleys.
                        </p>
                        <p>
                          This greatly increases the surface area withut having
                          to increase the size.
                        </p>
                        <p>
                          We guarantee that no other models, regardless of
                          price, will produce under similar conditions, such a
                          high and low pH or ORP (Oxygen reduction Potentail).
                        </p>
                      </Col>
                    </Row>
                  </Container>
                  <picture>
                    <source srcSet={perfImg1} type="image/webp" />
                    <img src={perfImg1} alt="" className="img1" />
                  </picture>
                  <picture>
                    <source srcSet={perfImg2} type="image/webp" />
                    <img src={perfImg2} alt="" className="img2" />
                  </picture>
                  <picture>
                    <source srcSet={perfImg3} type="image/webp" />
                    <img src={perfImg3} alt="" className="img3" />
                  </picture>
                </section>

                <section className="tft__wrapper">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>7P Platinum-Titanium Plates</h2>
                        </div>
                        <p>
                          7 proprietary electrodes with 6 layers of platinum
                          ensuring the best ionizer enriched water.
                        </p>
                        <picture>
                          <source srcSet={sevenplates} type="image/webp" />
                          <img
                            src={sevenplates}
                            alt=""
                            className="w-100 mt-3"
                          />
                        </picture>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="under__sink_wrapper">
                  <Container>
                    <Row>
                      <Col ms={12} className="mt-4 text-center">
                        <h2>
                          <strong>UNDER SINK Style</strong>
                        </h2>
                        <picture>
                          <source srcSet={undersink} type="image/webp" />
                          <img src={undersink} alt="" className="w-100" />
                        </picture>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="super_strong_wrapper">
                  <Container>
                    <Row>
                      <Col md={7} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>
                            The difference of filter technology makes water
                            cleaner and healthier.
                          </h2>
                        </div>
                        <p>
                          It filter the harmful substances and keeps the
                          beneficial minerals in water to make water clean and
                          rich in minerals
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="pt-5 pb-5 bg-light filtration__wrapper">
                  <Container>
                    <Row>
                      <Col md={12} className="mt-4 text-center">
                        <picture>
                          <source
                            srcSet={filtrationprocess}
                            type="image/webp"
                          />
                          <img
                            src={filtrationprocess}
                            alt=""
                            className="w-75"
                          />
                        </picture>
                      </Col>
                      <Col md={12} className="mt-4 text-center">
                        <h5>
                          <strong>High-Performance Composite Filter</strong>
                        </h5>
                        <picture>
                          <source srcSet={filterimage} type="image/webp" />
                          <img src={filterimage} alt="" className="" />
                        </picture>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Tab>

              {/* SM 230  */}
              <Tab eventKey="sm-230" title="SM-S230TL">
                <section
                  className="product_detail_info silder__wrapper"
                  style={{ backgroundImage: `url(${productBg})` }}
                >
                  <Container>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <Carousel indicators={false} pause={false}>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={img6} type="image/webp" />
                                    <img
                                      src={img6}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    {" "}
                                    <source srcSet={img7} type="image/webp" />
                                    <img
                                      src={img7}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    <source srcSet={sms1} type="image/webp" />
                                    <img
                                      src={sms1}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    <source srcSet={sms2} type="image/webp" />
                                    <img
                                      src={sms2}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    <source srcSet={sms3} type="image/webp" />
                                    <img
                                      src={sms3}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                          <Carousel.Item interval={4000}>
                            <Row className="justify-content-center">
                              <Col>
                                <div className="product_slide_image">
                                  <picture>
                                    <source srcSet={img5} type="image/webp" />
                                    <img
                                      src={img5}
                                      className="thumb__image"
                                      alt="Electrodes"
                                    />
                                  </picture>
                                </div>
                              </Col>
                            </Row>
                          </Carousel.Item>
                        </Carousel>
                      </Col>
                      <Col md={6}>
                        <div className="title__wrap regular mb-5 mt-3 mt-md-0">
                          <h2>Ionia SM-S230TL – Alkaline Water Machine</h2>
                        </div>
                        <div className="tag">
                          <h4>
                            <strong>Category</strong> : Water Ionizer
                          </h4>
                          <h4>
                            <strong>Product ID</strong> : 1805
                          </h4>
                        </div>
                        <ul className="product_detail_info_list">
                          <li>12(5P+7P) Platinum-Titanium Plates</li>
                          <li>Premium Dual Filtration</li>
                          <li>350W SMPS Power Control System</li>
                          <li>RFID Genuine filter chip</li>
                          <li>Super Strong Acidic (pH2.5~pH12)</li>
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <TechnologySlider />

                <section className="good_water__wrapper pb-0">
                  <Container>
                    <Row>
                      <Col md={7} className="m-auto text-center text-white">
                        <div className="title__wrap regular center_aligned">
                          <h2 className="text-white">Now a good water smart</h2>
                        </div>
                        <p>
                          More perfect SM-S230TL for you Alkaline Ionic water, a
                          good living water ionizer
                        </p>
                        <Col md={12} className="mt-4">
                          <picture>
                            <source srcSet={dualFilter} type="image/webp" />
                            <img src={dualFilter} alt="" className="w-100" />
                          </picture>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="super_strong__wrapper">
                  <Container>
                    <Row>
                      <Col md={4}>
                        <div className="border rounded-2 text-center p-4 bg-white">
                          <picture>
                            <source srcSet={superItem} type="image/webp" />
                            <img
                              src={superItem}
                              alt=""
                              className="w-100 mb-3"
                            />
                          </picture>
                          <h5>12P (5P+7P) Platinum</h5>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="border rounded-2 text-center p-4 bg-white">
                          <picture>
                            <source srcSet={superItem2} type="image/webp" />
                            <img
                              src={superItem2}
                              alt=""
                              className="w-100 mb-3"
                            />
                          </picture>
                          <h5>Super Strong Acidic pH2.5-pH12</h5>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="border rounded-2 text-center p-4 bg-white">
                          <picture>
                            <source srcSet={superItem3} type="image/webp" />
                            <img
                              src={superItem3}
                              alt=""
                              className="w-100 mb-3"
                            />
                          </picture>
                          <h5>350W SMPS Power Control System</h5>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="performance__wrapper">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>Performance of Electrodes</h2>
                        </div>
                        <p>
                          In their latest models are tweleve (5P+7P) of the most
                          advanced platinum-titanium electrodes in the world.
                        </p>
                        <p>
                          When a cross section of an electrode is examined at
                          700 times magnification, you can see that the
                          electrodes are now covered in a super fine mesh with
                          very distinct points and valleys.
                        </p>
                        <p>
                          This greatly increases the surface area withut having
                          to increase the size.
                        </p>
                        <p>
                          We guarantee that no other models, regardless of
                          price, will produce under similar conditions, such a
                          high and low pH or ORP (Oxygen reduction Potentail).
                        </p>
                      </Col>
                    </Row>
                  </Container>
                  <picture>
                    <source srcSet={perfImg1} type="image/webp" />
                    <img src={perfImg1} alt="" className="img1" />
                  </picture>
                  <picture>
                    <source srcSet={perfImg2} type="image/webp" />
                    <img src={perfImg2} alt="" className="img2" />
                  </picture>
                  <picture>
                    <source srcSet={perfImg3} type="image/webp" />
                    <img src={perfImg3} alt="" className="img3" />
                  </picture>
                </section>

                <section className="super_strong__wrapper pb-0">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>Super Strong Alkaline & Acidic pH2.5-pH12</h2>
                        </div>
                        <p>
                          By using 12 plates, maximized electrolysis from pH 2.5
                          to pH 12, so that strong alkaline and strong acidic
                          can be discharged.
                        </p>
                        <Col md={12} className="mt-4">
                          <picture>
                            <source srcSet={superImg} type="image/webp" />
                            <img src={superImg} alt="" className="w-100" />
                          </picture>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="tft__wrapper">
                  <Container>
                    <Row>
                      <Col md={6} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>3.2" TFT LCD</h2>
                        </div>
                        <h5 className="fw-bold">Convenience of USE</h5>
                        <p>
                          The one-touch water supply system (3.2" TFT LCD)
                          maximized the convenience of use. The water supply
                          system supplies a certain amount of water to maintain
                          constant alkaline water pH.
                        </p>
                        <br />
                        <h5 className="fw-bold">Convenience of USE</h5>
                        <p>
                          The one-touch water supply system (3.2" TFT LCD)
                          maximized the convenience of use. The water supply
                          system supplies a certain amount of water to maintain
                          constant alkaline water pH.
                        </p>
                        <picture>
                          <source srcSet={tftImg} type="image/webp" />
                          <img src={tftImg} alt="" className="w-100 mt-3" />
                        </picture>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="super_strong__wrapper pb-0">
                  <Container>
                    <Row>
                      <Col md={7} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>
                            The difference of filter technology makes water
                            cleaner and healthier.
                          </h2>
                        </div>
                        <p>
                          It filter the harmful substances and keeps the
                          beneficial minerals in water to make water clean and
                          rich in minerals
                        </p>
                        <Col md={12} className="mt-4">
                          <picture>
                            <source srcSet={dualFilter} type="image/webp" />
                            <img src={dualFilter} alt="" className="w-100" />
                          </picture>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="pt-5 pb-5 bg-light">
                  <Container>
                    <Row>
                      <Col md={4}>
                        <div className="border rounded-2 text-center p-4 bg-white">
                          <picture>
                            <source srcSet={superItem4} type="image/webp" />
                            <img
                              src={superItem4}
                              alt=""
                              className="w-75 mb-3"
                            />
                          </picture>
                          <h5>Activated cabon Filter</h5>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="border rounded-2 text-center p-4 bg-white">
                          <picture>
                            <source srcSet={superItem5} type="image/webp" />
                            <img
                              src={superItem5}
                              alt=""
                              className="w-75 mb-3"
                            />
                          </picture>
                          <h5>High-performance Composite Filter</h5>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className="border rounded-2 text-center p-4 bg-white">
                          <picture>
                            <source srcSet={superItem4} type="image/webp" />
                            <img
                              src={superItem4}
                              alt=""
                              className="w-75 mb-3"
                            />
                          </picture>
                          <h5>Composition Filter</h5>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>

                <section className="super_strong__wrapper pb-0 mb-5 ">
                  <Container>
                    <Row>
                      <Col md={8} className="m-auto text-center">
                        <div className="title__wrap regular center_aligned">
                          <h2>
                            An Alkaline water ionizer that simultaneously
                            generates sterilized water and alkaline water
                          </h2>
                        </div>
                        <Col
                          md={8}
                          className="m-auto mt-5 d-flex align-items-end gap-3"
                        >
                          <Row className="align-items-center">
                            <Col md={8}>
                              <picture>
                                <source
                                  srcSet={sterilized1}
                                  type="image/webp"
                                />
                                <img
                                  src={sterilized1}
                                  alt=""
                                  className="w-100"
                                />
                              </picture>
                            </Col>
                            <Col md={4}>
                              <picture>
                                <source
                                  srcSet={sterilized2}
                                  type="image/webp"
                                />
                                <img
                                  src={sterilized2}
                                  alt=""
                                  className="w-100"
                                />
                              </picture>
                            </Col>
                          </Row>
                        </Col>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Tab>
            </Tabs>
          </Container>
        </section>
      </RndLayout>
    </>
  );
}
