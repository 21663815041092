import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

export default function PatentModal({
  clickedImg,
  setClickedImg,
  handelRotationRight,
  handelRotationLeft,
}) {
  const handleClick = (e) => {
    if (e.target.classList.contains("dismiss")) {
      setClickedImg(null);
    }
  };
  return (
    <div className="patent__overlay dismiss" onClick={handleClick}>
      <picture>
        <source srcSet={clickedImg} type="image/webp" />
        <img src={clickedImg} className="modal_image" alt="" />
      </picture>
      {/* <div className="close" onClick={handleClick}>
        <FaXmark />
      </div> */}
      <div onClick={handelRotationRight} className="nextBtn">
        <FaAngleRight />
      </div>
      <div onClick={handelRotationLeft} className="prevBtn">
        <FaAngleLeft />
      </div>
    </div>
  );
}
