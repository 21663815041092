/* global gtag */
import React, { useState } from "react";
import classes from "./Header.module.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { FaPhone, FaEnvelope, FaCircleCheck } from "react-icons/fa6";
import SocialLinks from "./social-links";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/ionia-logo.webp";
import loader from "../assets/loader.gif";
import axios from "axios";

function Header() {
  const [sticky, setSticky] = useState(false);

  function stickyHeaderHandler() {
    if (window.scrollY >= 45) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }

  window.addEventListener("scroll", stickyHeaderHandler);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    pincode: "",
    address: "",
    header: "Book Live Demo Enquiry",
  });
  if (typeof gtag === 'function') {
    gtag('event', 'conversion', {
      send_to: 'AW-437309363/JRVdCMOlsMkDELOfw9AB',
    });
  } else {
    console.error('gtag is not defined. Make sure it is properly included in your project.');
  }
  const fetchAllocation = async () => {
    const obj = {
      notes: "From Website",
      processName: "Default Process",
      assignedTo: "+919654455530",
    };
    obj.customer = {
      name: formData.name,
      phoneNumber: formData.phone,
      company: {
        address: {
          street: formData.address,
          pincode: formData.pincode,
        },
      },
    };
    try {
      await axios
        .post("https://api.runo.in/v1/crm/allocation", obj, {
          headers: {
            "Content-Type": "application/json",
            "Auth-Key": "MWIybG41b2h5Mm9nNXFkM3U=",
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.phone === "" ||
      formData.pincode === "" ||
      formData.address === "" ||
      formData.phone.length < 10
    ) {
      setIsFormValid(false);
      return;
    }
    setIsDisable(true);
    fetchAllocation();
    // await axios
    //   .post(
    //     "https://api.interakt.ai/v1/public/message/",
    //     {
    //       countryCode: "+91",
    //       phoneNumber: formData.phone,

    //       callbackData: "hello",
    //       type: "Template",
    //       template: {
    //         name: "welcome_message",
    //         languageCode: "en",
    //         headerValues: [`${formData.name}`],
    //         fileName: "file_name.pdf",
    //         bodyValues: ["body_variable_value"],
    //       },
    //     },
    //     {
    //       headers: {
    //         Authorization:
    //           "Basic V1V0SE5wbW5RdmFsX24yMXppV0ttaGQzOWpnTTk1bnZ0Ym5iNjVuLTlrczo=",
    //       },
    //     }
    //   )
    //   .then((data) => {
    //     if (data.data.result) {
    //       setShow(true);
    //       setIsFormSubmitted(true);
    //       setFormData({
    //         name: "",
    //         phone: "",
    //         pincode: "",
    //         address: "",
    //       });
    //       setIsDisable(false);
    //     }
    //   });
    await axios.post("https://commonapi.quickgst.in/event/email", formData);
    setIsFormValid(true);
	setIsFormSubmitted(false);
	setShow(false)
        setShow(true);
          setIsFormSubmitted(true);
          setFormData({
            name: "",
            phone: "",
            pincode: "",
            address: "",
          });
          setIsDisable(false);
        
  };

  return (
    <>
      <header>
        <div className={classes.top__header}>
          <Container className={classes.top__bar}>
            <ul className={`${classes.contact__links} list-style-none p-0 m-0`}>
              <li>
                <NavLink to="tel:+91-9654455530">
                  <FaPhone /> <span>+91-9654455530</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="mailto:sales@ionia.co.in">
                  <FaEnvelope /> <span>sales@ionia.co.in</span>
                </NavLink>
              </li>
            </ul>
            <div className="right__bar">
              <NavLink
                className="text-white d-none d-md-block"
                to="/buy-products"
              >
                Buy now
              </NavLink>
              <Button className="header_popbtn" onClick={handleShow}>
                Book Live Demo
              </Button>
              <SocialLinks />
            </div>
          </Container>
        </div>
        <div className={`bottom__header ${sticky ? "sticky" : ""}`}>
          <Navbar expand="lg">
            <Container>
              <NavLink to="/" className="navbar-brand">
                <picture> 
                  <source srcSet={logo} type="image/webp" /> 
                  <img
                    src={logo}
                    alt="Fallback Image"
                    className="header__logo"
                  />
                 </picture>
              </NavLink>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <NavLink to="/" className="nav-link pe-0 ps-0">
                    Home
                  </NavLink>
                  <NavLink to="/about-us" className="nav-link pe-0 ps-0 ">
                    About us
                  </NavLink>
                  <NavLink to="/products" className="nav-link pe-0 ps-0 ">
                    Products
                  </NavLink>
                  <NavLink
                    to="/health-benefits"
                    className="nav-link pe-0 ps-0 "
                  >
                    Health Benefits
                  </NavLink>
                  <NavLink to="/rnd" className="nav-link pe-0 ps-0 ">
                    R&D
                  </NavLink>
                  {/* <NavLink
                    to="/franchise-opportunities"
                    className="nav-link pe-0 ps-0"
                  >
                    Franchises
                  </NavLink> */}
                  {/* <NavLink to="/blogs" className="nav-link pe-0 ps-0">
                    Blogs
                  </NavLink> */}
                  <NavLink to="/media" className="nav-link pe-0 ps-0">
                    Media
                  </NavLink>
                  <NavLink to="/contacts" className="nav-link btn">
                    Contact Us
                  </NavLink>
                  <NavLink
                    to="/buy-products"
                    className="d-block d-md-none nav-link"
                  >
                    Buy Now
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </header>

      <Modal show={show} onHide={handleClose} dialogClassName="book_demo_form">
        <Modal.Body className="p-4">
          {!isFormSubmitted ? (
            <>
              <h3>Book your Live Demo</h3>
              <Form onSubmit={handleSubmitForm}>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="demoName">
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                        value={formData.name}
                        name="name"
                      />
                      {!isFormValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">
                            Please enter your name
                          </span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Group className="mb-3" controlId="demoPhone">
                      <Form.Control
                        type="tel"
                        placeholder="Contact no."
                        maxLength="10"
                        onChange={(e) =>
                          setFormData({ ...formData, phone: e.target.value })
                        }
                        value={formData.phone}
                        name="phone"
                      />
                      {!isFormValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">
                            Please enter contact no
                          </span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="demoAreaPin">
                      <Form.Control
                        type="tel"
                        placeholder="Enter Pin Code"
                        onChange={(e) =>
                          setFormData({ ...formData, pincode: e.target.value })
                        }
                        value={formData.pincode}
                        name="pincode"
                      />
                      {!isFormValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">Enter pin code</span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="demoLocation">
                      <Form.Control
                        as="textarea"
                        placeholder="Enter address for live demo"
                        style={{
                          height: "100px",
                        }}
                        onChange={(e) =>
                          setFormData({ ...formData, address: e.target.value })
                        }
                        value={formData.address}
                        name="address"
                      />
                      {!isFormValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">
                            Enter complete address
                          </span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex align-items-center">
                  <Button className="submit" type="submit" disabled={isDisable}>
                    Book Now
                  </Button>
                  {isDisable && (
                    <picture>
                      <source srcSet={loader} type="gif/image" />
                      <img src={loader} alt="loader" className="loader ms-4" />
                    </picture>
                  )}
                </div>
              </Form>
            </>
          ) : (
            <div className="connect_box text-center">
              <h5 className="m-0">
                Thank you for choosing <span>IONIA!</span>
              </h5>
              <p>We will get back to your soon.</p>
              <FaCircleCheck
                style={{
                  color: "#12c665",
                  fontSize: "30px",
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
