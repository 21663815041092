import React from "react";
import Siemens from "../assets/images/siemens-logo.jpg";
import Logo from "../assets/images/logo_360.jpg";

const WipPrint = () => {
  return (
    <div className="center-table">
      <table border={2} style={{ width: "auto", height: "100%" }}>
        <tr>
          <th colspan="2">
            <img
              src={Siemens}
              alt="logo"
              style={{ width: "auto", height: "100px" }}
            />
          </th>
          <td
            colspan="8"
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: "34px",
              textAlign: "center",
            }}
          >
            WIP REPORTS
          </td>
          <th colspan="2">
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: "auto",
                height: "100px",
                alignItems: "end",
                textAlign: "end",
              }}
            />
          </th>
        </tr> 
        <tr>
          <td
            className="header_bg"
            style={{
              color: "black",
              fontWeight: "bold",
              border: "1px solid black",
            }}
            colSpan={4}
          >
            Bill to
          </td>
          <td colSpan={5} rowSpan={4}>
            <table border={2}>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Dc Number:
                </td>
                <td
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  DC232402000
                </td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  D.C. Date{" "}
                </td>
                <td>03/02/2024</td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  P.O. Number
                </td>
                <td>BY MAIL</td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  P.O. Date :
                </td>
                <td>15/01/2024</td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  O.C. Number :
                </td>
                <td>SO232400329</td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  O.C. Date :
                </td>
                <td>15/01/2024</td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Contact Person :
                </td>
                <td>Asha Kushwaha</td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Mobile
                </td>
                <td>asdf</td>
              </tr>
              <tr>
                <td
                  colSpan={4}
                  style={{
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  Email{" "}
                </td>
                <td>asha.kushwaha@addverb.in</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan={6}>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              Addverb Technologies Limited
            </strong>
            <br />
            Plot no. 5, sector 156 Phase II, New Okhla, <br />
            Industrial Development Area, Gautam Budh Nagar, <br />
            Uttar Pradesh, Greater Noida-201310, UP
            <br />
            <strong style={{ fontWeight: "bold" }}>GSTIN :</strong>{" "}
            09AAOCA5226F2ZL,{" "}
            <strong style={{ fontWeight: "bold" }}>State Code</strong> : 09
          </td>
        </tr>
        <tr>
          <td
            style={{ border: "1px solid black" }}
            className="header_bg"
            colSpan={4}
          >
            Ship to
          </td>
        </tr>
        <tr>
          <td colSpan={7}>
            <strong style={{ color: "black", fontWeight: "bold" }}>
              Addverb Technologies Limited
            </strong>
            <br />
            Plot no. 5, sector 156 Phase II, New Okhla, <br />
            Industrial Development Area, Gautam Budh Nagar, <br />
            Uttar Pradesh, Greater Noida-201310, UP <br />
            <strong style={{ fontWeight: "bold" }}>GSTIN :</strong>{" "}
            09AAOCA5226F2ZL,{" "}
            <strong style={{ fontWeight: "bold" }}>State Code</strong> : 09
          </td>
        </tr>
        <tr style={{ backgroundColor: "gray" }}>
          <td
            style={{
              border: "1px solid black",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Sr.No.
          </td>
          <td
            style={{
              border: "1px solid black",
              textAlign: "center",
              fontWeight: "bold",
            }}
            colSpan={2}
          >
            MLFB No.
          </td>
          <td
            style={{
              border: "1px solid black",
              textAlign: "center",
              fontWeight: "bold",
            }}
            colspan={4}
          >
            Description
          </td>
          <td
            style={{
              border: "1px solid black",
              textAlign: "center",
              fontWeight: "bold",
            }}
            colSpan={3}
          >
            Serial No.
          </td>
          <td
            style={{
              border: "1px solid black",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Qty
          </td>
        </tr>
        <tr>
          <td style={{ border: "1px solid black", textAlign: "center" }}>1</td>
          <td
            style={{ border: "1px solid black", textAlign: "center" }}
            colSpan={2}
          >
            1P6EP1334-1LB00 ()
          </td>
          <td
            style={{ border: "1px solid black", textAlign: "center" }}
            colspan={3}
          >
            SITOP PSU100L 120V_24VDC_10A
          </td>
          <td
            style={{ border: "1px solid black", textAlign: "center" }}
            colSpan={4}
          >
            SYSU/P5119475
          </td>
          <td style={{ border: "1px solid black", textAlign: "center" }}>1</td>
        </tr>
        <tr>
          <td style={{ border: "1px solid black", textAlign: "center" }}>1</td>
          <td
            style={{ border: "1px solid black", textAlign: "center" }}
            colSpan={2}
          >
            1P6EP1334-1LB00()
          </td>
          <td
            style={{ border: "1px solid black", textAlign: "center" }}
            colspan={4}
          >
            SITOP PSU100L 120V_24VDC_10A
          </td>
          <td
            style={{ border: "1px solid black", textAlign: "center" }}
            colSpan={3}
          >
            SYSU/P5119475
          </td>
          <td style={{ border: "1px solid black", textAlign: "center" }}>1</td>
        </tr>
        <tr>
          <td colSpan={6}>
            <strong style={{ fontWeight: "bold" }}>Dispatch Through:</strong>{" "}
            &nbsp; By hand{" "}
          </td>
          <td colSpan={5} style={{ border: "1px solid black" }}>
            <strong style={{ fontWeight: "bold" }}>Vehicle No.:</strong> &nbsp;
            By hand
          </td>
        </tr>
        <tr>
          <td colSpan={6} style={{ border: "1px solid black" }}>
            <strong style={{ fontWeight: "bold" }}>Dispatch Through:</strong>
            &nbsp; By hand
          </td>
          <td colSpan={5} style={{ border: "1px solid black" }}>
            <strong style={{ fontWeight: "bold" }}>Dispatch Through:</strong>
            &nbsp; By hand
          </td>
        </tr>

        <tr>
          <td
            colSpan={6}
            rowSpan={2}
            style={{ borderRight: "1px solid black" }}
          >
            <strong style={{ fontWeight: "bold" }}>Remarks :</strong>
            <br />
            447_TYPE2_MP01_MYER _0001 <br />
            (SO232400329_0001)
          </td>
        </tr>
        <tr>
          <td colSpan={6} style={{ textAlign: "center", fontWeight: "bold" }}>
            <br />
            Authorised Signatory
          </td>
        </tr>
        <tr>
          <td
            style={{
              textAlign: "center",
              backgroundColor: "gray",
              fontWeight: "bold",
              fontSize: "25px",
              border: "1px solid black",
            }}
            colspan={12}
          >
            Diagnostic Automation & Controls Private Limited
          </td>
        </tr>
        <tr>
          <td
            style={{ border: "1px solid black", textAlign: "center" }}
            colspan={4}
          >
            Y-45, Okhla Industrial Area ,
            <br />
            Phase-II , New Delhi - 110 020.
          </td>
          <td
            style={{ border: "1px solid black", textAlign: "center" }}
            colSpan={3}
          >
            Phone : +91-11-43104310
            <br />
            E-Mail : info@diag.in
          </td>
          <td
            style={{ border: "1px solid black", textAlign: "center" }}
            colspan={4}
          >
            CIN : U74999DL2016PTC292312
            <br />
            GSTIN : 07AAFCD6270P1Z5
          </td>
        </tr>
      </table>
    </div>
  );
};

export default WipPrint;
