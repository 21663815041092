/* global gtag */
import React from "react";
import Layout1 from "../../components/SubPageLayout";
import ScrollToTop from "../../components/ScrollToTop";
import {
  Carousel,
  Col,
  Row,
  Container,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import slide1 from "../../assets/images/banner1.webp";
import slide2 from "../../assets/images/banner2.webp";
import slide3 from "../../assets/images/banner4.webp";
import slide4 from "../../assets/images/banner3.webp";
import { useState, useEffect } from "react";
import loader from "../../assets/loader.gif";
import axios from "axios";
import wave from "../../assets/images/wave.webp";

//ph levels

import RelatedProducts from "../../components/RelatedProducts";
import Patent from "../../components/Patent";
import Certificates from "../../components/Certificates";
import phscalesec from "../../assets/images/aboutus/ph-scale-sec.webp";
import whoWeAreBg from "../../assets/images/about-bg.webp";
import { FaCircleCheck } from "react-icons/fa6";
import PhLevel from "../../components/PhLevel";
import SocialIcons from "../../components/SocialIcons";
import SubPageFooter from "../../components/SubPageFooter";
import { useAddress } from "./AddressContext";

function Ahmedabad() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    comment: "",
    header: "Enquiry Form Ahemdabad",
  });
  const [isInputValid, setIsInputValid] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const handleClose = () => {
    setModalShow(false);
  };
  const fetchAllocation = async () => {
    const obj = {
      notes: "From Website",
      processName: "Default Process",
      assignedTo: "+919654455530",
    };
    obj.customer = {
      name: formData.name,
      phoneNumber: formData.phone,
    };
    try {
      await axios
        .post("https://api.runo.in/v1/crm/allocation", obj, {
          headers: {
            "Content-Type": "application/json",
            "Auth-Key": "MWIybG41b2h5Mm9nNXFkM3U=",
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.phone === "" ||
      formData.comment === "" ||
      formData.phone.length < 10
    ) {
      setIsInputValid(false);
      return;
    }
    setIsDisable(true);

    fetchAllocation();

    //whatsapp api
    // await axios
    //   .post(
    //     "https://api.interakt.ai/v1/public/message/",
    //     {
    //       countryCode: "+91",
    //       phoneNumber: formData.phone,

    //       callbackData: "hello",
    //       type: "Template",
    //       template: {
    //         name: "welcome_message",
    //         languageCode: "en",
    //         headerValues: [`${formData.name}`],
    //         fileName: "file_name.pdf",
    //         bodyValues: ["body_variable_value"],
    //       },
    //     },
    //     {
    //       headers: {
    //         Authorization:
    //           "Basic V1V0SE5wbW5RdmFsX24yMXppV0ttaGQzOWpnTTk1bnZ0Ym5iNjVuLTlrczo=",
    //       },
    //     }
    //   )
    //   .then((data) => {
    //     if (data.data.result) {
    //       setModalShow(true);
    //       setFormData({
    //         name: "",
    //         phone: "",
    //         comment: "",
    //         header: "Enquiry Form Ahendabad",
    //       });
    //       setIsDisable(false);
    //     }
    //   });
    await axios.post("https://commonapi.quickgst.in/event/email", formData);
    setIsInputValid(true);
    setModalShow(true);
    setFormData({
      name: "",
      phone: "",
      comment: "",
      header: "Enquiry Form Ahendabad",
    });
    setIsDisable(false);
  };
  return (
    <Layout1
      name="Ahmedabad"
      number="+91-9624084084"
      address={[
        {
          address:
            "124, First Floor, Raviza Plaza, Thaltej - Shilaj Rd, opp. Rambag Nr. Bagban Party plot, Thaltej, Ahmedabad, Gujarat Pincode-380059",
        },
        { address: "Contact person-Yangnesh" },
        { address: "Mob. No: +91-9624084084" },
      ]}
    >
      <ScrollToTop />
      <SocialIcons />
      <section className="video__banner">
        <Carousel
          indicators={false}
          navigators={false}
          pause={false}
          arrows={false}
          autoplay={true}
        >
          <Carousel.Item interval={4000}>
            <Row className="justify-content-center">
              <Col xs={12} md={12}>
                <picture>
                  <source srcSet={slide1} type="image/webp" />
                  <img src={slide1} alt="" className="w-100" />
                </picture>
                <div className="bannerText1">
                  <h2 className="wow zoomIn animated" data-wow-delay="1s">
                    Unlock your hydration potential with IONIA, <br />
                    <span> the secret weapon</span> <br />
                    for a brighter, healthier future.
                    <br />
                  </h2>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item interval={4000}>
            <Row className="justify-content-center">
              <Col xs={12} md={12}>
                <picture>
                  <source srcSet={slide2} type="image/webp" />
                  <img src={slide2} alt="" className="w-100" />
                </picture>
                <div className="bannerText2">
                  <h2 className="wow fadeInLeft animated" data-wow-delay="1s">
                    Experience the transformative power of our products
                    <span
                      className="wow fadeInLeft animated"
                      data-wow-delay="1.8s"
                    >
                      and embrace a well-rounded.
                    </span>
                    approach to wellness.
                  </h2>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item interval={4000}>
            <Row className="justify-content-center">
              <Col xs={12} md={12}>
                <pitcure>
                  <source srcSet={slide3} type="image/webp" />
                  <img src={slide3} alt="" className="w-100" />
                </pitcure>
                <div className="bannerText3">
                  <h2 className="wow zoomIn animated" data-wow-delay="1s">
                    Give your body the refreshing boost of
                    <strong>antioxidants </strong>
                    <span className="wow zoomIn animated" data-wow-delay="1.8s">
                      it deserves with IONIA's alkaline water.
                    </span>
                  </h2>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item interval={4000}>
            <Row className="justify-content-center">
              <Col xs={12} md={12}>
                <picture>
                  <source srcSet={slide4} type="image/webp" />
                  <img src={slide4} alt="" className="w-100" />
                </picture>
                <div className="bannerText4">
                  <h2 className="wow fadeInLeft animated" data-wow-delay="1s">
                    With the perfect pH level and essential minerals, <br />
                    stay hydrated and
                    <span
                      className="wow fadeInLeft animated"
                      data-wow-delay="1.8s"
                    >
                      boost your health.
                    </span>
                  </h2>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </section>
      {/* second section about us */}
      {/* <section
            className="who_we_are__wrapper"
            style={{ backgroundImage: `url(${whoWeAreBg})` }}
          >
            <Container>
              <Row className="align-items-center">
                <Col md={6}>
                  <div className="title__wrap">
                    <h2>
                      Equip your whole family with the shield of accurate
                      <br />
                      <span className="text-center w-100">pH balanced water.​</span>
                    </h2>
                    <img src={wave} alt="Wave" className="wave__img" height={50} />
                  </div>
                  <p>
                    By providing your family with accurate pH balanced water through
                    the use of a water ionizer, you're offering them a proactive and
                    convenient way to experience these diverse health benefits.
                  </p>
                  <ul
                    id="image"
                    className="product_detail_info_list column_count_2"
                  >
                    <li>Optimal Hydration</li>
                    <li>Enhanced Nutrient Absorption</li>
                    <li>Improved Digestion</li>
                    <li>Boosted Immune System</li>
                    <li>Detoxification Support</li>
                    <li>Energy Boost</li>
                    <li>Bone Health</li>
                    <li>Reduced Acidity</li>
                    <li>Anti-Aging Benefits</li>
                    <li>Overall Wellness</li>
                  </ul>
                </Col>
                <Col
                  md={5}
                  className="offset-md-1
                  wow
                  zoomIn
                  center"
                  data-wow-delay="0.5s"
                >
                  <Carousel
                    indicators={false}
                    navigators={false}
                    pause={false}
                    arrows={true}
                    autoplay={true}
                  >
                    <Carousel.Item interval={5000}>
                      <Row>
                        <Col>
                          <picture>
                            <source srcSet={phscalesec} type="image/webp" />
                            <img
                              src={phscalesec}
                              alt="ionia"
                              width={"75%"}
                              height={"75%"}
                              className="w-100 h-100s about_image mt-5 mt-md-0"
                            />
                          </picture>
                        </Col>
                      </Row>
                    </Carousel.Item>
                    <Carousel.Item interval={5000}>
                      <Row>
                        <Col>
                          <picture>
                            <source srcSet={phscalesec} type="image/webp" />
                            <img
                              src={phscalesec}
                              alt="ionia"
                              width={"75%"}
                              height={"75%"}
                              className="w-100 h-100s about_image mt-5 mt-md-0"
                            />
                          </picture>
                        </Col>
                      </Row>
                    </Carousel.Item>
                    <Carousel.Item interval={5000}>
                      <Row>
                        <Col>
                          <picture>
                            <source srcSet={phscalesec} type="image/webp" />
                            <img
                              src={phscalesec}
                              alt="ionia"
                              width={"75%"}
                              height={"75%"}
                              className="w-100 h-100s about_image mt-5 mt-md-0"
                            />
                          </picture>
                        </Col>
                      </Row>
                    </Carousel.Item>
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </section> */}
      <RelatedProducts />
      {/* third section ph scale */}
      <PhLevel />
      <Patent />
      <Certificates />
      {/* forth section */}
      <section className="contact_us__wrapper">
        <Container>
          <Row>
            <Col md={6}>
              <div className="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.2642201559634!2d72.49247847531463!3d23.050772879153897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b9753cfe553%3A0x2cc51e2d3b507cd0!2sRedkraft%20Enterprise%20OPC%20Pvt%20Ltd%20-%20Ahmedabad%20-%20IONIA%20Alkaline%20Water%20Ionizer%20South%20Korea!5e0!3m2!1sen!2sin!4v1698214153926!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  title="google address"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </Col>
            <Col md={6}>
              <div className="title__wrap regular text-end">
                <span>Contact form</span>
                <h2>
                  Its Time to Protect <br />
                  Your Family
                </h2>
              </div>
              <Form onSubmit={handleSubmitForm}>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                        value={formData.name}
                        name="name"
                      />
                      {!isInputValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">
                            Please enter your name
                          </span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Control
                        type="tel"
                        maxLength="10"
                        placeholder="Enter contact no."
                        onChange={(e) =>
                          setFormData({ ...formData, phone: e.target.value })
                        }
                        value={formData.phone}
                        name="phone"
                      />
                      {!isInputValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">
                            Please enter valide contact no.
                          </span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="comment">
                      <Form.Control
                        as="textarea"
                        placeholder="Add Comments"
                        style={{ height: "100px" }}
                        value={formData.comment}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            comment: e.target.value,
                          })
                        }
                        name="comment"
                      />
                      {!isInputValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">
                            Please enter your comment
                          </span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  {/* <Col md={12}>
                      <Form.Group className="mb-3" controlId="agree">
                        <Form.Check
                          type="checkbox"
                          label="I agree that my submitted data is being collected and stored."
                        />
                      </Form.Group>
                    </Col> */}
                </Row>
                <div className="d-flex align-items-center">
                  <Button
                    variant="primary"
                    className="submit"
                    type="submit"
                    disabled={isDisable}
                    onClick={(e) => {
                      handleSubmitForm(e);
                      if (typeof gtag === "function") {
                        gtag("event", "conversion", {
                          send_to: "AW-437309363/JRVdCMOlsMkDELOfw9AB",
                        });
                      } else {
                        console.error(
                          "gtag is not defined. Make sure it is properly included in your project."
                        );
                      }
                    }}
                  >
                    Submit
                  </Button>
                  {isDisable && (
                    <picture>
                      <source srcSet={loader} type="gif/image" />
                      <img src={loader} alt="loader" className="loader ms-4" />
                    </picture>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
        size="md"
        aria-labelledby="connect-us"
        centered
        show={modalShow}
        onHide={handleClose}
        backdropClassName="connect__overlay"
      >
        <Modal.Body>
          <div className="connect_box w-100 text-center">
            <h5 className="m-0">
              Thank you for choosing <span>IONIA!</span>
            </h5>
            <p>We will get back to your soon.</p>
            <FaCircleCheck
              style={{
                color: "#12c665",
                fontSize: "30px",
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </Layout1>
  );
}

export default Ahmedabad;
