import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SocialLinks from "./social-links";
import classes from "./Footer.module.css";
import { FaAngleUp, FaEnvelope, FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";
import logo from "../assets/images/footer-logo.webp";
import { FaWhatsapp } from "react-icons/fa";

const KEYWORDS = [
	{
		id: 1,
		name: "Alkaline Water Ionizer in Bhilwara",
		link: "/bhilwara",
	},
	{
		id: 2,
		name: "Alkaline Water Ionizer in Ahemdabad",
		link: "/ahemdabad",
	},
	{
		id: 3,
		name: "Alkaline Water Ionizer in Ratlam",
		link: "/ratlam",
	},
	{
		id: 4,
		name: "Alkaline Water Ionizer in Rajkot",
		link: "/rajkot",
	},
	{
		id: 5,
		name: "Alkaline Water Ionizer in Dausa",
		link: "/dausa",
	},
	{
		id: 6,
		name: "Alkaline Water Ionizer in Alwar",
		link: "/alwar",
	},
	{
		id: 7,
		name: "Alkaline Water Ionizer in Jaipur",
		link: "/jaipur",
	},
	{
		id: 8,
		name: "Alkaline Water Ionizer in Gurugram",
		link: "/gurugram",
	},
	{
		id: 9,
		name: "Alkaline Water Ionizer in Noida",
		link: "/noida",
	},
	{
		id: 9,
		name: "Alkaline Water Ionizer in Gorakhpur",
		link: "/gorakhpur",
	},
];

export default function Footer() {
	const [scrollClass, setScrollClass] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY >= 50) {
				setScrollClass(true);
			} else {
				setScrollClass(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [scrollClass]);

	return (
		<>
			<section className="keywords_wrap">
				<Container>
					<Row>
						{KEYWORDS.map((el) => {
							return (
								<Col md={2} key={el.id}>
									<Link to={el.link} target="_blank">
										{el.name}
									</Link>
								</Col>
							);
						})}
					</Row>
				</Container>
			</section>
			<footer className={classes.footer__wrapper}>
				<Container>
					<Row>
						<Col md={4}>
							<picture>
								<source srcSet={logo} type="image/webp" />
								<img
									src={logo}
									height={30}
									className={classes.footer__logo}
									alt="Ionia"
								/>
							</picture>
							<p>
								Alkaline water has a higher PH level than regular drinking
								water. Scientists believe that it can neutralize the acid in
								your body. This water contains alkaline minerals and negative
								oxidation reduction potential(ORP).
							</p>
							<div className="mt-4">
								<SocialLinks />
							</div>
						</Col>
						<Col md={5}>
							<h4 className={classes.titles}>Quick Links</h4>
							<ul className={classes.quick__links}>
								<li>
									<Link to="/about-us">About Us</Link>
								</li>
								<li>
									<Link to="/products">Products</Link>
								</li>
								<li>
									<Link to="/health-benefits">Health benefits</Link>
								</li>
								<li>
									<Link to="/rnd">R&D</Link>
								</li>
								<li>
									<Link to="/blog">Blog</Link>
								</li>
								<li>
									<Link to="/contacts">Contact Us</Link>
								</li>
								<li>
									<Link to="/privacy-policy-2">Privacy & Policy</Link>
								</li>

								<li>
									<Link to="/terms-and-condition">Terms & Condition</Link>
								</li>
								<li>
									<Link to="/refunds-and-cancellations">
										Refunds / Cancellations
									</Link>
								</li>
								<li>
									<Link to="/shipping-and-delivery-policy">
										Shipping & Delivery Policy
									</Link>
								</li>
							</ul>
						</Col>
						<Col md={3}>
							<h4 className={`text-uppercase ${classes.titles}`}>
								Registered Address
							</h4>
							<ul className={classes.details__box}>
								<li>
									<span>
									5B/9, Behind Kotak Mahindra Bank,<br /> Block 6, Tilak Nagar, New Delhi, Delhi, 110018
									</span>
								</li>
								<li>
									<FaEnvelope />
									<Link to="mailto:sales@ionia.co.in">sales@ionia.co.in</Link>
								</li>
								<li>
									<FaPhone />{" "}
									<Link to="tel:+91-9654455530">+91-9654455530</Link>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</footer>
			<button
				className={`scroll_top_btn ${scrollClass ? "show" : "hide"}`}
				onClick={() => {
					window.scrollTo({
						top: 0,
						behavior: "smooth",
					});
				}}
			>
				<FaAngleUp />
			</button>
			<Link to="https://wa.me/919654455530" target="_blank" className="whatsapp_btn">
				<FaWhatsapp />
			</Link>
		</>
	);
}
