import { Col, Container, Row } from "react-bootstrap";
import image1 from "../assets/images/certifications/ce.webp";
import image2 from "../assets/images/certifications/emi.webp";
import image3 from "../assets/images/certifications/g-certz.webp";
import image4 from "../assets/images/certifications/gmp.webp";
import image5 from "../assets/images/certifications/hal.webp";
import image6 from "../assets/images/certifications/IC.webp";
import image7 from "../assets/images/certifications/innobiz.webp";
import image8 from "../assets/images/certifications/kfda.webp";
import image9 from "../assets/images/certifications/kipo.webp";
import image10 from "../assets/images/certifications/kita.webp";
import image11 from "../assets/images/certifications/kmdia.webp";
import image12 from "../assets/images/certifications/koita.webp";
import image13 from "../assets/images/certifications/korean.webp";
import image14 from "../assets/images/certifications/korean_water.webp";
import image15 from "../assets/images/certifications/lc.webp";
import image16 from "../assets/images/certifications/smba.webp";
import image17 from "../assets/images/certifications/tbv.webp";
import image18 from "../assets/images/certifications/tuv.webp";

const certificates = [
  {
    id: "ce",
    img: image1,
    text: "Europe Quality Standard Certificate",
  },
  {
    id: "emi",
    img: image2,
    text: "EMI",
  },
  {
    id: "g-certz",
    img: image3,
    text: "ISO 9001 International Quality Certification",
  },
  {
    id: "gmp",
    img: image4,
    text: "Standard for medical device manufacturing and quality management",
  },
  {
    id: "hal",
    img: image5,
    text: "HALAL",
  },
  {
    id: "ic",
    img: image6,
    text: "Korea Electricity Safety Certificate",
  },
  {
    id: "innobiz",
    img: image7,
    text: "Innovation Business Certification System",
  },
  {
    id: "kfda",
    img: image8,
    text: "Korea Food & Drugs Administration",
  },
  {
    id: "kipo",
    img: image9,
    text: "Korean Intellectural Property Office",
  },
  {
    id: "kita",
    img: image10,
    text: "Korea International Trade Association",
  },
  {
    id: "kmdia",
    img: image11,
    text: "Korea Medical Devices Industry Association",
  },
  {
    id: "koita",
    img: image12,
    text: "The Korea Industrial Technology Association Member",
  },
  {
    id: "korean",
    img: image13,
    text: "Small and medium sized businesses with promising exports",
  },
  {
    id: "korean-water",
    img: image14,
    text: "Water Quality Association",
  },
  {
    id: "ltc",
    img: image15,
    text: "Labtest Testing Certification",
  },
  {
    id: "smba",
    img: image16,
    text: "Excellent Venture enterprise",
  },
  {
    id: "tbv",
    img: image17,
    text: "Certified by Gyeonggi Province",
  },
  {
    id: "tuv",
    img: image18,
    text: "US, C Quality Standard Certificate",
  },
];
export default function Certificates() {
  return (
    <>
      <section className="certifications__wrapper">
        <Container>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular center_aligned">
                <h2>Certifications</h2>
              </div>
            </Col>
          </Row>
          <Row>
            {certificates.map((el) => {
              return (
                <Col md={2} xs={6} className="text-center mb-2" key={el.id}>
                  <picture>
                    <source srcSet={el.img} type="image/webp" />
                    <img src={el.img} alt={"Certificates"} className="icon" />
                  </picture>
                  <h4>{el.text}</h4>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
}
