import React, { useEffect, useState } from "react";
import {
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    FaYoutube
  } from "react-icons/fa6";
function SocialIcons (){
    const [showIcons, setShowIcons] = useState(false);
    useEffect (()=>{
            const timer= setInterval(()=>{
                setShowIcons(true);
             }, 10000  );
             return()=> clearInterval(timer);
    },[])
    const closeIcons = () => {
        setShowIcons(false);
      };
return(
    <div className="social__slider">
        {/* {showIcons &&( */}
            
            <div  className="social__icons">
        <a  href="https://www.facebook.com/ioniaindia" target="_blank" rel="noopener noreferrer">
            <FaFacebookF/>
        </a>
        <a href="https://www.instagram.com/ioniaindia/" target="_blank" rel="noopener noreferrer">
            <FaInstagram/>
        </a>
        <a href="https://www.linkedin.com/in/ionia-india/" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn/>
        </a>
        <a href="https://www.youtube.com/@ioniaindia9321" target="_blank" rel="noopener noreferrer">
            <FaYoutube/>
        </a>
        </div>
    {/* <button className="close__btn"  onClick={closeIcons}>
          &#10006;
    </button> */}
            
{/* )}  */}
  </div>
);
}
export default SocialIcons;