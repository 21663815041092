import { Col, Container, Row } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import rndtechimg1 from "../assets/images/technology/rnd-tech-img1.webp";
import rndtechimg2 from "../assets/images/technology/rnd-tech-img2.webp";
import rndtechimg3 from "../assets/images/technology/rnd-tech-img3.webp";
import rndtechimg4 from "../assets/images/technology/rnd-tech-img4.webp";
import rndtechimg5 from "../assets/images/technology/rnd-tech-img5.webp";
import rndtechimg6 from "../assets/images/technology/rnd-tech-img6.webp";

const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    navText: ["<", ">"],
    smartSpeed: 1000,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

export default function TechnologySlider() {
  return (
    <>
      <section className="testimonials__wrapper rnd_testimonial silder__wrapper">
        <Container>
          <Row>
            <Col xs={9} md={11} className="m-auto text-center">
              <div className="title__wrap regular center_aligned">
                <h2>Technology</h2>
              </div>
              <OwlCarousel className="slider-items owl-carousel" {...options}>
                <div className="item">
                  <picture>
                    <source srcSet={rndtechimg1} type="image/webp" />
                    <img
                      src={rndtechimg1}
                      alt="rndtechimg1"
                      className="w-100"
                    />
                  </picture>
                </div>
                <div className="item">
                  <picture>
                    <source srcSet={rndtechimg2} type="image/webp" />
                    <img
                      src={rndtechimg2}
                      alt="rndtechimg2"
                      className="w-100"
                    />
                  </picture>
                </div>
                <div className="item">
                  <picture>
                    <source srcSet={rndtechimg3} type="image/webp" />
                    <img
                      src={rndtechimg3}
                      alt="rndtechimg3"
                      className="w-100"
                    />
                  </picture>
                </div>
                <div className="item">
                  <picture>
                    <source srcSet={rndtechimg4} type="image/webp" />
                    <img
                      src={rndtechimg4}
                      alt=" rndtechimg4"
                      className="w-100"
                    />
                  </picture>
                </div>
                <div className="item">
                  <picture>
                    <source srcSet={rndtechimg5} type="image/webp" />
                    <img
                      src={rndtechimg5}
                      alt="rndtechimg5"
                      className="w-100"
                    />
                  </picture>
                </div>
                <div className="item">
                  <picture>
                    <source srcSet={rndtechimg6} type="image/webp" />
                    <img
                      src={rndtechimg6}
                      alt="rndtechimg6"
                      className="w-100"
                    />
                  </picture>
                </div>
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
