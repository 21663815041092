import Layout from "../components/Layout";
// import notfound from "../assets/images/error-404.webp";
import { Accordion, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import BannerImg from "../assets/images/banner.webp";
import antioxidant from "../assets/images/health-benefits/image1.webp";
import alkaline from "../assets/images/health-benefits/image2.webp";
import microCluster from "../assets/images/health-benefits/image3.webp";
import { Helmet } from "react-helmet";
import ScrollToTop from "../components/ScrollToTop";
import healthbenefits from "../assets/images/health_benefits.webp";
import thumb from "../assets/images/blogs/thumb.webp";
import ExportingForCountries from "../components/ExportingForCountries";
import { FaArtstation } from "react-icons/fa6";
import img4 from "../assets/images/products/en-7p/img4.webp";
import firsticon from "../assets/images/health-benefits/first-icon.webp";
import secondicon from "../assets/images/health-benefits/second-icon.webp";
import eighthicon from "../assets/images/health-benefits/eighth-icon.webp";
import seventhicon from "../assets/images/health-benefits/seventh-icon.webp";
import sixthicon from "../assets/images/health-benefits/sixth-icon.webp";
import fifthicon from "../assets/images/health-benefits/fifth-icon.webp";
import fourthicon from "../assets/images/health-benefits/fourth-icon.webp";
import thirdicon from "../assets/images/health-benefits/third-icon.webp";
import superItem from "../assets/images/rnd/part1.webp";
import lasticon from "../assets/images/health-benefits/last-icon.webp";

export default function HealthBenefit() {
  return (
    <>
      <Layout>
        <Helmet>
          <title>Water Ionizer Machine India | Alkaline Water Machine</title>
          <meta
            name="title"
            content="Alkaline Water Ionizer | Alkaline Water Machine India"
          />
          <meta
            name="description"
            content="Discover the advantages of Ionia's Alkaline Water Ioniser for your health. Improve your well-being, cleanse, and stay properly hydrated."
          />
          <meta
            name="keywords"
            content="Alkaline Water Ionizer, Alkaline Water Machine, Alkaline Water Machine Delhi "
          />
          <meta name="robots" content="index, follow" />
          <meta name="language" content="English" />
          <meta property="og:title" content="Alkaline Water Machine India" />
          <meta
            property="og:site_name"
            content="https://ionia.co.in/health-benefits"
          />
          <meta
            property="og:url"
            content="https://ionia.co.in/health-benefits"
          />
          <meta
            property="og:description"
            content="Discover the advantages of Ionia's Alkaline Water Ioniser for your health. Improve your well-being, cleanse, and stay properly hydrated"
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://ionia.co.in/health-benefits"
          />
        </Helmet>
        <ScrollToTop />
        <section className="inner__banner health_benefit_banner">
          <picture>
            <source srcSet={BannerImg} type="image/webp" />
            <img src={BannerImg} alt="Wave" />
          </picture>
          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <h1 className="healthyLife_title">
                  "Healthy Life Matlab <span>Happy Life with</span> IONIA Water
                  Ionizer"
                </h1>
                <p>
                  Embrace a Healthier Lifestyle: Discover the array of health
                  benefits that IONIA brings to your daily routine. From pH
                  balance to hydration, here's how our products can elevate your
                  well-being
                </p>
                {/* <Link to="" className="readmore__btn">
                  I DARE TO DATE HEALTH
                </Link> */}
              </Col>
              <Col md={6}>
                <div className="rounded-4 overflow-hidden lh-0 video_box">
                  {/* <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/sWKArcCegr0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe> */}
                  {/* new video */}
                  <iframe
                    width="100%"
                    height="280"
                    src="https://www.youtube.com/embed/wL5mAIxH-yQ?autoplay=1&mute=0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="how_can_it_help">
          <Container>
            <Row>
              <Col md={12}>
                <div className="title__wrap center_aligned">
                  <h2 className="primary-color text-uppercase">
                    {/* How can it help */}
                    Elevating Your Health and Hydration
                  </h2>
                </div>
              </Col>
              <Col md={10} className="m-auto mb-4">
                <div className="title__wrap text-center">
                  <h2 className="fs-3">
                    {/* Prevent Diabetes, Cholestrol & Cardiac Issues? */}
                    Embrace a Healthier Lifestyle
                  </h2>
                  <p>
                    {/* Research shows that visceral fat is an important factor in
                    how our hormones work. It's also linked to insulin
                    resistance, when cells in your muscles, fat and liver don't
                    respond well to insulin. */}
                    Discover a world of wellness with IONIA Alkaline Water
                    Ionizer, designed to transform the way you hydrate and
                    nourish your body. Let's delve into the remarkable health
                    benefits and properties that set IONIA apart
                  </p>
                </div>
              </Col>
              <Col md={12}>
                <Row>
                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={fifthicon} type="image/webp" />
                          <img src={fifthicon} alt="img" />
                        </picture>
                      </div>
                      <div>
                        <h3>Optimal Hydration</h3>
                        <p>
                          IONIA's advanced electrolysis technology creates water
                          with smaller clusters, facilitating rapid absorption
                          and optimal hydration at a cellular level.
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={secondicon} type="image/webp" />
                          <img src={secondicon} alt="" />
                        </picture>
                      </div>
                      <div>
                        <h3>pH Balance</h3>
                        <p>
                          Restore your body's natural pH equilibrium with
                          alkaline water. Maintaining proper pH levels supports
                          overall health, reducing the strain of acidity on your
                          system.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={firsticon} type="image/webp" />
                          <img src={firsticon} alt="" />
                        </picture>
                      </div>
                      <div>
                        <h3>Antioxidant Boost</h3>
                        <p>
                          IONIA's ionized water is rich in antioxidants, helping
                          neutralize harmful free radicals and enhancing your
                          body's ability to combat oxidative stress.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={thirdicon} type="image/webp" />
                          <img src={thirdicon} alt="" />
                        </picture>
                      </div>
                      <div>
                        <h3>Enhanced Nutrient Absorption</h3>
                        <p>
                          Ionized water aids in the absorption of essential
                          nutrients from your diet, maximizing the benefits of
                          your meals and promoting overall vitality.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={eighthicon} type="image/webp" />
                          <img src={eighthicon} alt="" />
                        </picture>
                      </div>
                      <div>
                        <h3>Digestive Harmony</h3>
                        <p>
                          Experience improved digestion as ionized water
                          supports a balanced internal environment, potentially
                          alleviating discomfort and bloating.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={fourthicon} type="image/webp" />
                          <img src={fourthicon} alt="" />
                        </picture>
                      </div>
                      <div>
                        <h3>Sustained Energy</h3>
                        <p>
                          Ionized water's potential to increase oxygen
                          availability may result in improved energy levels and
                          reduced feelings of fatigue.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={sixthicon} type="image/webp" />
                          <img src={sixthicon} alt="" />
                        </picture>
                      </div>
                      <div>
                        <h3>Radiant Skin</h3>
                        <p>
                          Ionized water's hydration and antioxidant properties
                          may contribute to healthier, more radiant skin,
                          helping you glow from within.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={seventhicon} type="image/webp" />
                          <img src={seventhicon} alt="" />
                        </picture>
                      </div>
                      <div>
                        <h3>Detoxification Support</h3>
                        <p>
                          Alkaline water can assist your body's natural
                          detoxification processes by aiding organs like the
                          kidneys in flushing out toxins.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={superItem} type="image/webp" />
                          <img src={superItem} alt="" />
                        </picture>
                      </div>
                      <div>
                        <h3>Scientifically Proven</h3>
                        <p>
                          IONIA's technology is backed by scientific research,
                          ensuring that you receive the highest quality,
                          accurately pH-balanced water.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="shadow list__single rounded-2">
                      <div className="left_img">
                        <picture>
                          <source srcSet={lasticon} type="image/webp" />
                          <img src={lasticon} alt="" />
                        </picture>
                      </div>
                      <div>
                        <h3>Environmentally Conscious</h3>
                        <p>
                          By choosing IONIA, you're not only benefiting your
                          health but also contributing to reducing plastic waste
                          associated with bottled water.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <p className="text-center">
                    IONIA Alkaline Water Ionizer is more than just a hydration
                    source; it's a key to unlocking a healthier, more vibrant
                    you. Elevate your wellness journey today with IONIA.
                    <strong>#IONIAWellness #HydrationElevated</strong>
                  </p>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="why_does_it_happen__wrapper">
          <Container>
            <Row>
              <Col md={12}>
                <div className="text-center">
                  <div className="title__wrap center_aligned">
                    <h2 className="text-uppercase primary-color">
                      UNLOCKING THE SECRETS OF IONIA'S IONIZED WATER PROPERTIES
                    </h2>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={3}>
                <div className="water_prop_img mt-5 mt-mb-0">
                  <picture>
                    <source srcSet={antioxidant} type="image/webp" />
                    <img
                      src={antioxidant}
                      alt="Antioxidant"
                      className="w-100"
                    />
                  </picture>
                </div>
              </Col>
              <Col md={9}>
                <div className="title__wrap regular mt-3 mt-mb-0">
                  <h2>Antioxidant-Rich Hydration</h2>
                </div>
                <p>
                  IONIA's water is a treasure trove of antioxidants, your body's
                  natural defense against oxidative stress. These antioxidants
                  neutralize harmful free radicals, promoting cellular health
                  and vitality.
                </p>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={3} className="order-md-2">
                <div className="water_prop_img mt-5 mt-mb-0">
                  <picture>
                    <source srcSet={alkaline} type="image/webp" />
                    <img src={alkaline} alt="Antioxidant" className="w-100" />
                  </picture>
                </div>
              </Col>
              <Col md={9} className="order-md-1">
                <div className="title__wrap regular  mt-3 mt-mb-0">
                  <h2>Alkalinity for Balance</h2>
                </div>
                <p>
                  IONIA's water is a treasure trove of antioxidants, your body's
                  natural defense against oxidative stress. These antioxidants
                  neutralize harmful free radicals, promoting cellular health
                  and vitality.
                </p>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={3}>
                <div className="water_prop_img mt-5 mt-mb-0">
                  <picture>
                    <source srcSet={microCluster} type="image/webp" />
                    <img
                      src={microCluster}
                      alt="Antioxidant"
                      className="w-100"
                    />
                  </picture>
                </div>
              </Col>
              <Col md={9}>
                <div className="title__wrap regular  mt-3 mt-mb-0">
                  <h2>Microclustering Magic</h2>
                </div>
                <p>
                  Regular water molecules cluster together, making absorption
                  challenging. IONIA's ionization process breaks down water into
                  smaller clusters, enhancing cellular hydration. This
                  microclustering allows for quicker, more efficient absorption,
                  nurturing your cells from the inside out.
                </p>
              </Col>
            </Row>
            <Row className="mt-4 text-center">
              <Col>
                Experience the synergy of these properties in every sip of IONIA
                water, nurturing your wellness journey holistically.
                <strong>#IONIAWellness #HydrationElevated</strong>
              </Col>
            </Row>
          </Container>
        </section>
        {/* <section className="live__ionia__water">
          <Container>
            <Row>
              <Col md={9} className="thumb_image m-auto">
                <picture>
                  <source srcSet={thumb} type="image/webp" />
                  <img src={thumb} alt="thumb" className="w-100" />
                </picture>
              </Col>
            </Row>
            <Row>
              <Col md={8} xs={10} className="m-auto">
                <div className="live_with_ionia text-center">
                  <h3>Live better with live water</h3>
                  <p>
                    Ionia Machines are developed in technical collaboration with
                    CSIR-IICT, Ministry of science & Technology, Govt of India
                    and over 5,00,000+ people are drinking ionia water everyday.
                  </p>
                  <p>
                    Ionia Machines are developed in technical collaboration with
                    CSIR-IICT, Ministry of science & Technology, Govt of India
                    and over 5,00,000+ people are drinking ionia water everyday.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}
        <section className="faq__wrapper">
          <Container className="rounded-4 shadow border pt-5 pb-5">
            <div className="title__wrap center_aligned">
              <h2>F&Qs</h2>
            </div>
            <Row>
              <Col md={12}>
                <div className="faq_tab">
                  <Tabs
                    defaultActiveKey="Ionia Alkaline Water"
                    id="faq-tabs"
                    className="mb-3"
                  >
                    <Tab
                      eventKey="Ionia Alkaline Water"
                      title="Ionia Alkaline Water"
                    >
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            What is IONIA Alkaline Water?
                          </Accordion.Header>
                          <Accordion.Body>
                            IONIA Alkaline Water is water that has been ionized
                            through an advanced electrolysis process. This
                            process enhances the water's properties, including
                            its pH level, antioxidant content, and
                            microclustering, offering potential health benefits.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            What are the benefits of drinking IONIA Alkaline
                            Water?
                          </Accordion.Header>
                          <Accordion.Body>
                            IONIA Alkaline Water may offer benefits such as
                            improved hydration, better nutrient absorption,
                            antioxidant support, pH balance, and enhanced
                            cellular hydration due to microclustering. These
                            benefits contribute to overall wellness.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            How does IONIA Alkaline Water maintain pH balance?
                          </Accordion.Header>
                          <Accordion.Body>
                            IONIA's ionizers use electrolysis to separate water
                            into alkaline and acidic components. Drinking
                            alkaline water can help balance your body's pH
                            levels, potentially mitigating the effects of
                            acidity.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            What is microclustering in IONIA Alkaline Water?
                          </Accordion.Header>
                          <Accordion.Body>
                            Microclustering refers to breaking down water
                            molecules into smaller clusters. IONIA's
                            microclustering facilitates quicker and more
                            efficient absorption at the cellular level, ensuring
                            optimal hydration.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            Does IONIA Alkaline Water have antioxidants?
                          </Accordion.Header>
                          <Accordion.Body>
                            Yes, IONIA Alkaline Water is rich in antioxidants
                            due to the ionization process. Antioxidants help
                            neutralize harmful free radicals in the body,
                            promoting cellular health and supporting your immune
                            system.
                          </Accordion.Body>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              Can I use IONIA Alkaline Water for cooking?
                            </Accordion.Header>
                            <Accordion.Body>
                              Absolutely! IONIA Alkaline Water is versatile and
                              can be used for cooking, making beverages, and
                              preparing meals. It can enhance the flavors of
                              your dishes while providing potential health
                              benefits.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="6">
                            <Accordion.Header>
                              Is IONIA Alkaline Water scientifically proven?{" "}
                            </Accordion.Header>
                            <Accordion.Body>
                              Yes, the benefits of ionized water, including its
                              antioxidant and alkaline properties, are supported
                              by scientific research. IONIA's technology is
                              designed to deliver consistent and accurate
                              results.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="7">
                            <Accordion.Header>
                              Can I use IONIA Alkaline Water if I have a medical
                              condition?{" "}
                            </Accordion.Header>
                            <Accordion.Body>
                              While IONIA Alkaline Water offers potential
                              benefits, it's important to consult your
                              healthcare provider before making any significant
                              changes to your diet or hydration routine,
                              especially if you have a medical condition.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="8">
                            <Accordion.Header>
                              How do I get IONIA Alkaline Water?
                            </Accordion.Header>
                            <Accordion.Body>
                              IONIA Alkaline Water can be easily obtained
                              through our range of water ionizers. These devices
                              are designed to transform tap water into ionized
                              alkaline water, ensuring you have access to its
                              potential benefits at your convenience.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="9">
                            <Accordion.Header>
                              Is IONIA committed to sustainability?
                            </Accordion.Header>
                            <Accordion.Body>
                              Yes, sustainability is a priority for us. By
                              choosing IONIA Alkaline Water, you're reducing the
                              use of single-use plastic bottles and promoting
                              eco-friendly hydration solutions.
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion.Item>
                      </Accordion>
                    </Tab>
                    <Tab eventKey="Ionia Products" title="Ionia Products">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            What are the different models of IONIA water
                            ionizers available?
                          </Accordion.Header>
                          <Accordion.Body>
                            IONIA offers a range of models tailored to various
                            needs. Our lineup includes models like X-blue (5
                            Plates), EN-7P (7 Plates), IO-400U (UC 7/9 Plates),
                            SM-230TL (12 Plates). for more information about
                            products check the link www.ionia.co.in/products
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            Do IONIA water ionizers come with a warranty?
                          </Accordion.Header>
                          <Accordion.Body>
                            Yes, all IONIA water ionizers come with a
                            manufacturer's warranty. The duration of the
                            warranty *3 Year* on the model and *5 Years* on
                            Titanium plates chamber. Please refer to the
                            specific product details for warranty information.
                            T&C Apply.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>
                            How do I choose the right IONIA water ionizer model
                            for me?
                          </Accordion.Header>
                          <Accordion.Body>
                            Selecting the right model depends on factors like
                            your budget, desired features, and water
                            consumption. Our website provides detailed
                            information on each model to help you make an
                            informed choice or you can just make a call on
                            +91-9654455530
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            What is included in the package when I purchase an
                            IONIA water ionizer?
                          </Accordion.Header>
                          <Accordion.Body>
                            When you purchase an IONIA water ionizer, the
                            package typically includes the ionizer unit,
                            installation kit, user manual, and any additional
                            accessories that come with the specific model
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            How often do I need to replace filters, and where
                            can I get replacements?
                          </Accordion.Header>
                          <Accordion.Body>
                            Filter replacement frequency varies based on water
                            quality and usage. Generally, filters are replaced
                            every 12 to 18 months. You can purchase replacement
                            filters directly from IONIA or authorized
                            distributors.
                          </Accordion.Body>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              Are replacement parts readily available for IONIA
                              products?
                            </Accordion.Header>
                            <Accordion.Body>
                              Yes, replacement parts and accessories are
                              available for all IONIA products. You can order
                              them through our official website or authorized
                              dealers.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="6">
                            <Accordion.Header>
                              How user-friendly are IONIA water ionizers?
                            </Accordion.Header>
                            <Accordion.Body>
                              IONIA water ionizers are designed for ease of use.
                              User-friendly interfaces and detailed user manuals
                              guide you through the installation and operation
                              process.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="7">
                            <Accordion.Header>
                              What does the warranty cover?
                            </Accordion.Header>
                            <Accordion.Body>
                              The warranty typically covers manufacturing
                              defects and malfunctions. Please refer to the
                              specific warranty terms provided with your chosen
                              model for detailed coverage information.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="8">
                            <Accordion.Header>
                              Can I extend the warranty on my IONIA water
                              ionizer?
                            </Accordion.Header>
                            <Accordion.Body>
                              Yes, some models offer the option to purchase an
                              extended warranty for additional peace of mind.
                              Check the product details for information on
                              extended warranty options or you can just make a
                              call on +91-9654455530
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="9">
                            <Accordion.Header>
                              Are there educational resources available for
                              proper maintenance?
                            </Accordion.Header>
                            <Accordion.Body>
                              Absolutely. Along with your user manual, IONIA
                              provides educational resources, guides, and videos
                              to help you maintain and get the most out of your
                              water ionizer.
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion.Item>
                      </Accordion>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <ExportingForCountries />
      </Layout>
    </>
  );
}
