import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import ScrollToTop from "../components/ScrollToTop";
import GetInTouch from "../components/GetInTouch";
import img4 from "../assets/images/products/en-7p/img4.webp";
import img3 from "../assets/images/products/io-400/img3.webp";
import img5 from "../assets/images/products/sm-S230/img5.webp";
import img2 from "../assets/images/products/x-blue/img2.webp";
import splash from "../assets/images/splash.webp";
import { Col, Container, Row } from "react-bootstrap";
import BannerImg from "../assets/images/banner.webp";
import bannerThumb from "../assets/images/products/banner-thumb.webp";
import { Link } from "react-router-dom";
import axios from "axios";

export default function BuyProducts() {

  const data = {
    name: "Sameer",
    amount: 1,
    number: "9358213928",
    MUID: "M1O1WGVHPZQ0",
    transactionId: "T" + Date.now(),
  };

  const handleCheckout = () => {
    try {
      axios
        .post(`https://trycamapi.quickgst.in/phone/payment`, { ...data })
        .then((data) => {
          console.log(data);
          if (data.data && data.data.data.instrumentResponse.redirectInfo.url) {
            window.location.href =
              data.data.data.instrumentResponse.redirectInfo.url;
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Buy Product | Ionia</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <ScrollToTop />
      <section className="inner__banner product_banner_wrapper">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" className="banner" />
        </picture>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1>Products</h1>
              <p>
                IONIA products are synonymous with innovation and wellness,
                designed to elevate your daily hydration experience. Crafted
                with precision and backed by advanced technology, IONIA products
                redefine the way you consume water.
              </p>
              <ul
                id="image"
                className="product_detail_info_list column_count_2 w-50"
              >
                <li>EN-7P</li>
                <li>X-blue</li>
                <li>SM-230TL</li>
                <li>IO-400U</li>
              </ul>
            </Col>
            <Col md={5} className="offset-md-1">
              <div className="thumb mt-4 mt-md-0">
                <picture>
                  <source srcSet={bannerThumb} type="image/webp" />
                  <img src={bannerThumb} className="w-100" alt="electrodes" />
                </picture>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product__wrapper">
        <Container>
          <section className="p-0 bg-none">
            <Container>
              <Row className="align-items-center">
                <Col md={6} className="first__image text-center">
                  <picture>
                    <source srcSet={img2} type="image/webp" />
                    <img
                      src={img2}
                      alt="ionia"
                      className="product_image mt-5 mt-md-0"
                    />
                  </picture>
                  <div className="splash">
                    <picture>
                      <source srcSet={splash} type="image/webp" />
                      <img
                        src={splash}
                        className="wow zoomIn splash__img"
                        alt="chinte"
                      />
                    </picture>
                  </div>
                </Col>
                <Col md={6} className="content__box">
                  <div className="title__wrap">
                    <h2>
                      Ionia X-BLUE – Alkaline <span>Water Machine</span>
                    </h2>
                  </div>
                  <ul id="image" className="product_detail_info_list">
                    <li>5 plate (Planized Titanium)</li>
                    <li>Luxury exterior design</li>
                    <li>
                      Automatic reversed cleaning system causes platinum coated
                      with titanium electrode to have at least 20 years
                      longevity
                    </li>
                    <li>
                      Color LCD display easily identifies 3 different functions
                    </li>
                  </ul>
                  <h2 className="primary-color fw-bold mt-3">
                    &#8377; 1,50,000
                  </h2>
                  <Link className="readmore__btn mt-2" to="/buy-products"  onClick={handleCheckout}>Buy now</Link>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="p-0 bg-none en7p__wrapper ">
            <Container>
              <Row className="align-items-center">
                <Col md={6} className="first__image text-center">
                  <picture>
                    {" "}
                    <source srcSet={img4} type="image/webp" />
                    <img
                      src={img4}
                      alt="ionia"
                      className="product_image mt-5 mt-md-0"
                    />
                  </picture>
                  <div className="splash">
                    <picture>
                      <source srcSet={splash} type="image/webp" />
                      <img
                        src={splash}
                        className="wow zoomIn splash__img"
                        alt="chinte"
                      />
                    </picture>
                  </div>
                </Col>
                <Col md={6} className="content__box">
                  <div className="title__wrap">
                    <h2>
                      Ionia EN-7P – Alkaline<span> Water Machine </span>
                    </h2>
                  </div>
                  <ul id="" className="product_detail_info_list">
                    <li>7 Platinum-Titanium Plates</li>
                    <li>Automatic Draining System</li>
                    <li>250W SMPS Power Control System</li>
                  </ul>
                  <h2 className="primary-color fw-bold mt-3">
                    &#8377; 1,80,000
                  </h2>
                  <Link className="readmore__btn mt-2" to="/buy-products">Buy now</Link>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="p-0 bg-none">
            <Container>
              <Row className="align-items-center">
                <Col
                  md={6}
                  className="first__image  text-center"
                >
                  <picture>
                    <source srcSet={img3} type="image/webp" />
                    <img
                      src={img3}
                      alt="ionia"
                      className="product_image mt-5 mt-md-0"
                    />
                  </picture>
                  <div className="splash">
                    <picture>
                      <source srcSet={splash} type="image/webp" />
                      <img
                        src={splash}
                        className="wow zoomIn splash__img"
                        alt="chinte"
                      />
                    </picture>
                  </div>
                </Col>
                <Col md={6} className="content__box">
                  <div className="title__wrap">
                    <h2>
                      Ionia IO-400U – Alkaline <span>Water Machine</span>
                    </h2>
                  </div>
                  <ul id="image" className="product_detail_info_list">
                    <li>
                      DARC System – Automatic change of flow path (6 Overseas
                      Patents)
                    </li>
                    <li>
                      Alarm message and ionization disabled during hot water
                      inflow.
                    </li>
                    <li>
                      Exact calculation of filter durability with Flux control.
                    </li>
                    <li>Extended range of pH selection and control.</li>
                    <li>Modern Design.</li>
                  </ul>  
                  <h2 className="primary-color fw-bold mt-3">
                    &#8377; 2,30,000
                  </h2>     
                  <Link className="readmore__btn mt-2" to="/buy-products">Buy now</Link>          
                </Col>
              </Row>
            </Container>
          </section>
          <section className="p-0 bg-none">
            <Container>
              <Row className="align-items-center">
                <Col
                  md={6}
                  className="first__image  text-center"
                >
                  <picture>
                    <source srcSet={img5} type="image/webp" />
                    <img
                      src={img5}
                      alt="ionia"
                      className="product_image mt-5 mt-md-0"
                    />
                  </picture>
                  <div className="splash">
                    <picture>
                      <source srcSet={splash} type="image/webp" />
                      <img
                        src={splash}
                        className="wow zoomIn splash__img "
                        alt="chinte"
                      />
                    </picture>
                  </div>
                </Col>
                <Col md={6} className="content__box">
                  <div className="title__wrap">
                    <h2>
                      Ionia SM-S230TL – Alkaline
                      <span>Water Machine</span>
                    </h2>
                  </div>
                  <ul id="image" className="product_detail_info_list">
                    <li>12(5P+7P) Platinum-Titanium Plates</li>
                    <li>Premium Dual Filtration</li>
                    <li>350W SMPS Power Control System</li>
                    <li>RFID Genuine filter chip</li>
                    <li>Super Strong Acidic (pH2.5~pH12)</li>
                  </ul>
                  <h2 className="primary-color fw-bold mt-3">
                    &#8377; 2,50,000
                  </h2>
                  <Link className="readmore__btn mt-2" to="/buy-products">Buy now</Link>
                </Col>
              </Row>
            </Container>
          </section>
        </Container>
      </section>
      <GetInTouch />
    </Layout>
  );
}
