import { Col, Container, Row } from "react-bootstrap";
import CommonBanner from "../components/CommonBanner";
import Layout from "../components/Layout";
import thumb from "../assets/images/blogs/thumb.webp";
import thumb2 from "../assets/images/blogs/thumb2.webp";
import thumb3 from "../assets/images/blogs/thumb3.webp";
import thumb4 from "../assets/images/blogs/thumb4.webp";
import thumb5 from "../assets/images/blogs/thumb5.webp";
import thumb6 from "../assets/images/blogs/thumb6.webp";
import thumb7 from "../assets/images/blogs/thumb7.webp";
import thumb9 from "../assets/images/blogs/thumb9.webp";
import thumb10 from "../assets/images/blogs/thumb10.webp";
import thumb11 from "../assets/images/blogs/thumb11.webp";
import thumb12 from "../assets/images/blogs/thumb12.webp";
import {
  FaAngleLeft,
  FaAngleRight,
  FaRegMessage,
  FaUserLarge,
} from "react-icons/fa6";
import { Link } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import Pagination from "react-bootstrap/Pagination";
import { useEffect, useState } from "react";
import Listcompo from "../components/Listcompo";

export default function Blogs() {
  const totalPages = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const PaginationComponent = () => { };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };
  return (
    <Layout>
      <CommonBanner>Blog</CommonBanner>
      <ScrollToTop />
      <section className="blog_main__wrapper pb-2">
        <Container>
          <Row>
            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb6} type="image/webp" />
                    <img
                      src={thumb6}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>

                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>January 5, 2023</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/use-of-ionizer-water-machine-good-or-bad">
                      Use of Ionizer Water Machine – Good or Bad?
                    </Link>
                  </h3>
                  <p>
                    The ionizer water machine is made of cutting-edge
                    technology. It is capable of reducing the high pH level of
                    the water.
                  </p>
                  <Link
                    to="/blog/use-of-ionizer-water-machine-good-or-bad"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb} type="image/webp" />
                    <img
                      src={thumb}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>January 18, 2023</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/top-5-alkaline-water-ionizer-machines-in-india">
                      Top 5 Alkaline Water Ionizer Machines in India
                    </Link>
                  </h3>
                  <p>
                    Drinking alkaline water is becoming a trend in the health
                    enthusiastic people. As awareness spread about Ionize
                    water’s benefits to the body, many people turn to alkaline
                    water.
                  </p>
                  <Link
                    to="/blog/top-5-alkaline-water-ionizer-machines-in-india"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb2} type="image/webp" />
                    <img
                      src={thumb2}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>January 29, 2023</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/the-benefits-of-alkaline-water-and-why-you-need-an-ionizer-machine-in-india">
                      The Benefits of Alkaline Water and Why You Need an Ionizer
                      Machine in India
                    </Link>
                  </h3>
                  <p>
                    Alkaline water consists of added minerals and nutrients that
                    enhance your health. Concentrated nutrients in the water
                    make it acidic, but it is available in a balanced form that
                    does not affect your health.
                  </p>
                  <Link
                    to="/blog/the-benefits-of-alkaline-water-and-why-you-need-an-ionizer-machine-in-india"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb3} type="image/webp" />
                    <img
                      src={thumb3}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>February 9, 2023</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/how-to-choose-the-besta-kaline-water-ionizer-machine-india">
                      How to choose the best Alkaline Water Ionizer Machine
                      India
                    </Link>
                  </h3>
                  <p>
                    From earthen pots to taps to UV RO machines, there comes the
                    alkaline water ionizer machine India.
                  </p>
                  <Link
                    to="/blog/how-to-choose-the-besta-kaline-water-ionizer-machine-india"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb5} type="image/webp" />
                    <img
                      src={thumb5}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>February 22, 2023</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/water-ionizer-machine-incredible-benefits">
                      Water Ionizer Machine: Incredible benefits
                    </Link>
                  </h3>
                  <p>
                    All that matters is consuming the good, may it be pure
                    water, fresh air, or healthy food. In the same context,
                    alkaline water is the new invention for the betterment of
                    the rest of our lives.
                  </p>
                  <Link
                    to="/blog/water-ionizer-machine-incredible-benefits"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb4} type="image/webp" />
                    <img
                      src={thumb4}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>March 19, 2023</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/how-does-an-alkaline-ionizer-machine-work">
                      How does an alkaline ionizer machine work?
                    </Link>
                  </h3>
                  <p>
                    Who doesn’t want clean and filtered water? At least, not
                    someone whom I know. With the same intent, an alkaline
                    ionizer machine comes into the picture.
                  </p>
                  <Link
                    to="/blog/how-does-an-alkaline-ionizer-machine-work"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb7} type="image/webp" />
                    <img
                      src={thumb7}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>March 19, 2023</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/benefits-of-alkaline-water">
                      Ionize Water Benefits: Separating Fact from Fiction
                    </Link>
                  </h3>
                  <p>
                    "Dive into the comprehensive guide on the benefits of
                    alkaline water & Ionize Water. Understand its health claims,
                    backed by scientific research, and practical considerations.
                  </p>
                  <Link
                    to="/blog/benefits-of-alkaline-water"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb10} type="image/webp" />
                    <img
                      src={thumb10}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>March 19, 2023</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/water-alkaline-franchise">
                      Alkaline Water Ionizer Franchise- Explore Glorious
                      Business Avenues!
                    </Link>
                  </h3>
                  <p>
                    Are you searching for water business prospects? Give it a
                    shot with the Ionia Alkaline water Ionizer Franchise and
                    pass on the power of good health to others. This form of
                    water ionizer has held unique significance for two decades
                    in countries such as Europe and the US. They provide
                    numerous health benefits and even revive the immune system
                    of users!
                  </p>
                  <Link
                    to="/blog/water-alkaline-franchise"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb11} type="image/webp" />
                    <img
                      src={thumb11}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>April 18, 2024</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/water-ionizer-machine-india">
                      Water Ionizer Machine India offers purity you can trust
                    </Link>
                  </h3>
                  <p>
                    Water Ionizer machines are gaining popularity in India because people are now more aware of the condition of drinking water. Changing climates have affected ground-based water bodies on a large scale. The population is at its peak in all the states of India.

                  </p>
                  <Link
                    to="/blog/water-ionizer-machine-india"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="blog__item">
                <div className="thumb">
                  <picture>
                    <source srcSet={thumb12} type="image/webp" />
                    <img
                      src={thumb12}
                      className="w-100 h-100 object-cover"
                      alt=""
                    />
                  </picture>
                </div>
                <div className="info">
                  <div className="dets">
                    <span>
                      <FaUserLarge />
                      Ionia Team
                    </span>
                    <span>April 18, 2024</span>
                    <span>
                      <FaRegMessage />3 comments
                    </span>
                  </div>
                  <h3 className="blogTitle">
                    <Link to="/blog/how-do-you-choose-perfect-alkaline-ionizer-machine">
                      How do you choose the perfect Alkaline Ionizer Machine?
                    </Link>
                  </h3>
                  <p>
                    Most of us live in areas where tap water is acidic, which is unsuitable for our health. Thus, purchasing an alkaline ionizer machine can assist in restoring the right pH balance of the fluids and making them safe to drink.
                  </p>
                  <Link
                    to="/blog/how-do-you-choose-perfect-alkaline-ionizer-machine"
                    className="readmore"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <div>
        <FaAngleLeft />
        <Pagination>
          <Pagination.Prev />
          <Pagination.Item active key={1}>
            {1}
          </Pagination.Item>
          <Pagination.Item key={2}>{2}</Pagination.Item>
          <Pagination.Item key={3}>{3}</Pagination.Item>
          <Pagination.Next />
        </Pagination>
        <FaAngleRight />
      </div> */}
      <section className="pagination_wrapper pt-0 pb-5">
        <div className="pagination_box">
          <button className="nav_btn" onClick={handlePreviousPage}>
            <FaAngleLeft />
          </button>
          <ul>
            {Array.from({ length: totalPages }, (_, index) => (
              <li
                key={index}
                link="/blog"
                value={index + 1}
                className={currentPage === index + 1 ? "active" : ""}
                onClick={() => handlePreviousPage(index + 1)}
              >
                {index + 1}
              </li>
            ))}
            {/* <li link="/blog" value="1" className="active">
              1
            </li>
            <li link="*" value="2">
              2
            </li>
            <li link="*" value="3">
              3
            </li> */}
          </ul>
          <button className="nav_btn" onClick={handleNextPage}>
            <FaAngleRight />
          </button>
        </div>
      </section>
    </Layout>
  );
}
