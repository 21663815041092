import Layout from "../../components/Layout";
import CommonBanner from "../../components/CommonBanner";
import { Col, Container, Row, Carousel, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import RelatedProducts from "../../components/RelatedProducts";
import ScrollToTop from "../../components/ScrollToTop";
import img1 from "../../assets/images/products/io-400/img1.webp";
import img2 from "../../assets/images/products/io-400/img2.webp";
import img3 from "../../assets/images/products/io-400/img3.webp";
import io4001 from "../../assets/images/products/io-400/io4001.webp";
import io4002 from "../../assets/images/products/io-400/io4002.webp";
import io4003 from "../../assets/images/products/io-400/io4003.webp";
import io4004 from "../../assets/images/products/io-400/io4004.webp";
import ReactPlayer from "react-player";
import ioniaio400 from "../../assets/images/products/io-400/ionia-io400u.mp4";
import { Link } from "react-router-dom";
import BannerImg from "../../assets/images/banner.webp";

export default function IoU() {
  return (
    <Layout>
      <Helmet>
        <title>
          Ionia IO-400U &#8211; Alkaline Water Machine &#8211; Ionia India
        </title>
        <meta
          name="description"
          content="DARC System - Automatic change of flow path (6 Overseas Patents)
 	Alarm message and ionization disabled during hot water inflow.
 	Exact calculation of filter durability with Flux control.
 	Extended range of pH selection and control.
 	Modern Design"
        />
      </Helmet>
      <ScrollToTop />
      <section className="inner__banner health_benefit_banner">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" />
        </picture>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1>Ionia IO-400U – Alkaline Water Machine</h1>
            </Col>
            <Col md={6}>
              <div className="rounded-4 overflow-hidden lh-0 video_box">
                <ReactPlayer
                  url={ioniaio400}
                  playing
                  loop
                  controls={false}
                  muted
                  width="100%"
                  height="100%"
                  autoPlay
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product_detail_info silder__wrapper">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <Carousel indicators={false} pause={false}>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={img3} type="image/webp" />
                          <img
                            src={img3}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={io4001} type="image/webp" />
                          <img
                            src={io4001}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={io4002} type="image/webp" />
                          <img
                            src={io4002}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={io4003} type="image/webp" />
                          <img
                            src={io4003}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={io4004} type="image/webp" />
                          <img
                            src={io4004}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={img2} type="image/webp" />
                          <img
                            src={img2}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col md={6}>
              <div className="title__wrap regular mb-5 mt-3 mt-md-0">
                <h2>Ionia IO-400U – Alkaline Water Machine</h2>
              </div>
              <div className="tag">
                <h4>
                  <strong>Category</strong> : Water Ionizer
                </h4>
                <h4>
                  <strong>Product ID</strong> : 503
                </h4>
              </div>
              <ul className="product_detail_info_list">
                <li>
                  DARC System - Automatic change of flow path (6 Overseas
                  Patents)
                </li>
                <li>
                  Alarm message and ionization disabled during hot water inflow.
                </li>
                <li>
                  Exact calculation of filter durability with Flux control.
                </li>
                <li>Extended range of pH selection and control.</li>
                <li>Modern Design.</li>
              </ul>
              <Link to="/rnd" className="readmore__btn ">
                Reseaerch On This Product
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product_detail_desc p-0">
        <Container>
          <Row className="mb-3">
            <Col md={12}>
              <div className="title__wrap regular mb-4">
                <h2 className="underline">Description</h2>
              </div>
              <p>
                <strong>Automatic Reversed Cleaning System:</strong>
                Automatic reversed cleaning system causes platinum coated with
                titanium electrode to have at least 20 years longevity.
              </p>
              <p>
                <strong>Performance of Electrodes:</strong> In their latest
                models are five of the most advanced platinum-titanium
                electrodes in the world. When a cross-section of an electrode is
                examined at 700 times magnification, you can see that the
                electrodes are now covered in a super fine mesh with very
                district points and valleys. This greatly increases the surface
                area without having to increase the size. We guarantee that no
                other models, regardless of price, will produce under similar
                conditions, such as high and low p or ORP (Oxidation-reduction
                potential).
              </p>
              <p>
                <strong>Titanium Plates:</strong> 7 Proprietary electrodes with
                6 layers of platinum ensuring the best Ionizer enriched water
              </p>
              <p>
                <strong>
                  <h4>3.2″ TFT LCD</h4>
                </strong>
              </p>
              <p>
                It is designed to generate ionized water range at stage from 1
                through 4 by manipulating
                <br />
                <strong>
                  The difference of filter technology makes water cleaner and
                  healthier (Option):
                </strong>{" "}
                It filters the harmful substance and keeps the beneficial
                mineral in water to make water clean and rich in minerals
              </p>
              <div className="title__wrap regular mb-4 mt-5">
                <h2 className="underline">Specification</h2>
              </div>
              <div className="speci">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th colSpan={4}>Specification</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Power Supply</strong>
                      </td>
                      <td>Transformer</td>
                      <td>
                        <strong>Power Consumption</strong>
                      </td>
                      <td>Max 250W</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Dimension</strong>
                      </td>
                      <td>265 x 380 x 140(WxHxD)mm</td>
                      <td>
                        <strong>Electrode</strong>
                      </td>
                      <td>9 plate (Planized Titanium)</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Electrolysis Setting</strong>
                      </td>
                      <td>
                        Alkaline (4 Level), Purified Water, Acidic (4 Level)
                      </td>
                      <td>
                        <strong>pH value</strong>
                      </td>
                      <td>pH 4.0(±0.5)~10.5(±0.5)</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Cleaning</strong>
                      </td>
                      <td>Automatic Cleaning</td>
                      <td>
                        <strong>Display Method</strong>
                      </td>
                      <td>One Module</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Number Of Filters</strong>
                      </td>
                      <td>One Filter</td>
                      <td>
                        <strong>Filter Media</strong>
                      </td>
                      <td>Activated Carbon Filter, UF Membrane Filter</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Filter Indicator</strong>
                      </td>
                      <td>LED Indicator</td>
                      <td>
                        <strong>ON/OFF Valve</strong>
                      </td>
                      <td>Manual Valve</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <RelatedProducts
        product1={false}
        product2={true}
        product3={false}
        product4={false}
      />
    </Layout>
  );
}
