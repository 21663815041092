import Layout from "../components/Layout";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import ExportingForCountries from "../components/ExportingForCountries";
import GetInTouch from "../components/GetInTouch";
import { Helmet } from "react-helmet";
import ScrollToTop from "../components/ScrollToTop";
import React, { useState } from "react";
import BannerImg from "../assets/images/banner.webp";
import wanjana from "../assets/images/aboutus/wan-ja-na.webp";
import missionimg from "../assets/images/aboutus/mission-img.webp";
import visionimg from "../assets/images/aboutus/vision-img.webp";

import {
  FaArtstation,
  FaBattleNet,
  FaMeta,
  FaBlackTie,
  FaCreativeCommons,
  FaReadme,
  FaProductHunt,
  FaD,
  FaE,
  FaHandshakeSimple,
} from "react-icons/fa6";

export default function About() {
  const history20122018 = [
    {
      date: "2018.11",
      content: "Attended in G-FAIR exhibition and franchise exhibition",
    },
    {
      date: "2018.02",
      content: "Selected State Prospective Medium & Small Enterprise",
    },
    {
      date: "2017.12",
      content: "Obtained EC certification (Model: IHD-1000)",
    },
    {
      date: "2017.10",
      content:
        "IONIA has been selected as a promising small and medium sized enterprise by the Gyeonggi Provincial",
    },
    {
      date: "2016.04",
      content: "CE certificate acquired (Model: SM-S230TL)",
    },
    {
      date: "2016.02",
      content: "CE certificate acquired (Model: SM-S230TL)",
    },
    {
      date: "2015.10",
      content:
        "Acquired of Patent for water ionizer that can supply sterilized water alkaline water simultaneously (No. 10 -1564594)",
    },
    {
      date: "2015.06",
      content: "Developed LCD (5”, 7”) ionizer",
    },
    {
      date: "2014.10",
      content:
        "Won “Future creation management awards of Korea” given by Korean program show",
    },
    {
      date: "2014.08",
      content: "Acquired CE Certification (Model : U-Blue)",
    },
    {
      date: "2014.06",
      content: "Acquired CE Certification (Model : SM-V112TL)",
    },
    {
      date: "2014.04",
      content: "Lunched New Model (I-110, I-210)",
    },
    {
      date: "2013.04",
      content: " Won”2013 Brand Power Awards” given by Korean program show",
    },
    {
      date: "2013.01",
      content: "Acquired CE Certification (Model : EN-7P) ",
    },
    {
      date: "2012.01",
      content: "Merged and acquired Korea Medi Co., Ltd.",
    },
  ];
  const history20102011 = [
    {
      date: "2011.08",
      content: "Developed large Naturally Sterilized Water Generator",
    },
    {
      date: "2011.05",
      content: "Participated in 107th Spring Canton Fair in Guangzhou, China",
    },
    {
      date: "2011.05",
      content:
        "Developed/supplied Sterilized Water Kit (Jointly developed with LG Electronics Inc.)",
    },
    {
      date: "2011.03",
      content:
        "High-frequency hair-removal device, launched developed IPL - Home beauty apparatus",
    },
    {
      date: "2010.12",
      content:
        "Launched 2 sorts of Ion Phase Apparatus (Stick Ion and Ion Mini)",
    },
    {
      date: "2010.11",
      content: "Extended IONIA building to the total area of 1052.09㎡",
    },
    {
      date: "2010.09",
      content:
        "Participated in Autumn Symposium of Korea Functional Mathematics Society",
    },
    {
      date: "2010.06",
      content: "Participated in 10th International Health Exhibition in China",
    },
    {
      date: "2010.05",
      content:
        "Participated in G-Fair (Korea Excellent Products Exhibition) in Mumbai Participated in Spring Symposium of Korea Functional Mathematics Society",
    },
    {
      date: "2010.04",
      content: "Acquired Tomato Bidet and established Bidet Div.",
    },
  ];
  const history20062009 = [
    {
      date: "2009.10",
      content: "KGMP regular Certification Test – KFDA",
    },
    {
      date: "2009.03",
      content: " Contracted Supply Agreement with WiniaMando Inc.",
    },
    {
      date: "2009.01",
      content: "Contracted Supply Agreement with LG Electronics Inc.",
    },
    {
      date: "2007.08",
      content:
        "Acquired the product permit of Medical Ion Phase Apparatus (The first time in the field of industry)",
    },
    {
      date: "2006.02",
      content:
        "Being appointed as INNO-BIZ Company (The first time in the field of industry)",
    },
    {
      date: "2006.01",
      content: "Changed the company name to IONIA Co.,Ltd.",
    },
  ];
  const history20051982 = [
    {
      date: "2005.04",
      content: "Registered to the U.S.FDA",
    },
    {
      date: "2004.06",
      content: " Acquired KGMP Certificate",
    },
    {
      date: "2003.09",
      content: "Acquired ISO 14001",
    },
    {
      date: "2002.08",
      content: "Being appointed as Promising Export S&M Company",
    },
    {
      date: "2002.08",
      content: "Being appointed as Venture Business (Gyeonggi Province)",
    },
    {
      date: "2001.06",
      content: "Acquired ISO 9001",
    },
    {
      date: "1999.06",
      content: "Changed the company name to Dongyang Science Co.,Ltd",
    },
    {
      date: "1982.05",
      content: "Established Korea Dongyang Science Co., Ltd.",
    },
  ];
  const [sticky, setSticky] = useState(false);

  function stickyHeaderHandler() {
    if (window.scrollY >= 45) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }
  window.addEventListener("scroll", stickyHeaderHandler);
  return (
    <Layout>
      <Helmet>
        <title>
          Water Ionizer India| Alkaline Water Ionizer India | Best Alkaline
          Water Ionizer In India
        </title>
        <meta
          name="title"
          content="Alkaline Water Ionizer | Alkaline Water Machine Delhi"
        />
        <meta
          name="description"
          content="With the Alkaline Water Ioniser from Ionia, experience the power of pure, healthy water. Improve your level of hydration using cutting-edge ionisation technology"
        />
        <meta
          name="keywords"
          content="Alkaline Water Ionizer, Alkaline Water Machine, Alkaline Water Machine in India"
        />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta property="og:title" content="Alkaline Water Ionizer" />
        <meta property="og:site_name" content="https://ionia.co.in/about-us" />
        <meta property="og:url" content="https://ionia.co.in/about-us" />
        <meta
          property="og:description"
          content="With the Alkaline Water Ioniser from Ionia, experience the power of pure, healthy water. Improve your level of hydration using cutting-edge ionisation technology"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://ionia.co.in/about-us" />
      </Helmet>
      <ScrollToTop />
      {/* <CommonBanner>About Us</CommonBanner> */}
      {/* <section className="switch__tabs pb-0 pt-4"> */}
      {/* <div className={`tabs__india_korea ${sticky ? "sticky" : ""}`}> */}
      {/* <Container>
          <Tabs
            defaultActiveKey="korea"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="korea" title="About Korea">
              <Row>
                <Col md={5} className="about_tab_image">
                  <picture>
                    <source srcSet={about2} />
                    <img src={about2} alt="" className="w-100" />
                  </picture>
                </Col>
                <Col md={8} className="ms-auto mb-3 mt-0 mt-md-5">
                  <picture>
                    <source srcSet={about1} />
                    <img src={about1} alt="" className="w-100" />
                  </picture>
                </Col>
                <Col md={12}>
                  <p>
                    <strong className="primary-color">
                      IONIA CO., LTD. is a company specialized in developing and
                      supplying a variety of echo friendly health appliances
                      including alkaline water ionizers, IPL's and cold
                      dispenser manufactured in the state-of-the-art facilities
                      equipped with highly reliable and globally competitive
                      manufacturing systems.
                    </strong>
                  </p>
                  <p>
                    We are striving to secure the independent R&D technology by
                    establishing a technology R&D center and shipping our
                    products not only to large and medium sized companies in
                    Korea on an OEM/ODM basis but a lso to the customers in more
                    than 40 countries in the world by making a ceaseless effort
                    in developing various kinds of new technologies and new
                    products.
                  </p>
                  <p>
                    We promise all of you that we will grow step by step to be a
                    world class company making an unlimited impression on
                    customers through constant technology innovation based on
                    environment products and health products under our own ionia
                    brand.
                  </p>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="india" title="About India">
              <Row>
                <Col md={5} className="about_tab_image">
                  <picture>
                    <source srcSet={about1} />
                    <img src={about1} alt="" className="w-100" />
                  </picture>
                </Col>
                <Col md={8} className="ms-auto mb-3 mt-0 mt-md-5">
                  <picture>
                    <source srcSet={about2} />
                    <img src={about2} alt="" className="w-100" />
                  </picture>
                </Col>
                <Col md={12}>
                  <p>
                    <strong className="primary-color">
                      IONIA CO., LTD. is a company specialized in developing and
                      supplying a variety of echo friendly health appliances
                      including alkaline water ionizers, IPL's and cold
                      dispenser manufactured in the state-of-the-art facilities
                      equipped with highly reliable and globally competitive
                      manufacturing systems.
                    </strong>
                  </p>
                  <p>
                    We are striving to secure the independent R&D technology by
                    establishing a technology R&D center and shipping our
                    products not only to large and medium sized companies in
                    Korea on an OEM/ODM basis but a lso to the customers in more
                    than 40 countries in the world by making a ceaseless effort
                    in developing various kinds of new technologies and new
                    products.
                  </p>
                  <p>
                    We promise all of you that we will grow step by step to be a
                    world class company making an unlimited impression on
                    customers through constant technology innovation based on
                    environment products and health products under our own ionia
                    brand.
                  </p>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container> */}
      {/* </div> */}
      {/* </section> */}
      <section className="inner__banner health_benefit_banner">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" />
        </picture>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1>About Us</h1>
              <p>
                IONIA CO., LTD. is a company specialised in developing and
                supplying a variety of echo friendly health appliances including
                alkaline water ionizers, IPL's and cold dispenser manufactured
                in the state-of-the-art facilities equipped with highly reliable
                and globally competitive manufacturing systems.
              </p>
              <p>
                We promise all of you that we will grow step by step to be a
                world class company making an unlimited impression on customers
                through constant technology innovation based on environment
                products and health products under our own ionia brand.
              </p>
            </Col>
            <Col md={6}>
              <div className="rounded-3 overflow-hidden lh-0 video_box">
                {/* <ReactPlayer
                  url={demoVideo}
                  playing={false}
                  loop
                  controls={true}
                  muted={false}
                  width="100%"
                  height="100%"
                  autoPlay={false}
                /> */}
                <iframe
                  src="https://www.youtube.com/embed/VtuISBFdVbI?autoplay=1&mute=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  height="275"
                  width="100%"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="about__korea__india">
        <Container>
          <Row>
            <Col md={12}>
              <div className="title_box">
                <picture>
                  <source srcSet={korea} />
                  <img src={korea} alt="" />
                </picture>
                Korea
              </div>
              <Row>
                <Col md={5} className="about_tab_image position-absolute">
                  <picture>
                    <source srcSet={about1} />
                    <img src={about1} alt="" className="w-100" />
                  </picture>
                </Col>
                <Col md={8} className="ms-auto mb-3 mt-0 mt-md-5">
                  <picture>
                    <source srcSet={about2} />
                    <img src={about2} alt="" className="w-100" />
                  </picture>
                </Col>
                <Col md={12}>
                  <p>
                    <strong className="primary-color">
                      IONIA CO., LTD. is a company specialized in developing and
                      supplying a variety of echo friendly health appliances
                      including alkaline water ionizers, IPL's and cold
                      dispenser manufactured in the state-of-the-art facilities
                      equipped with highly reliable and globally competitive
                      manufacturing systems.
                    </strong>
                  </p>
                  <p>
                    We are striving to secure the independent R&D technology by
                    establishing a technology R&D center and shipping our
                    products not only to large and medium sized companies in
                    Korea on an OEM/ODM basis but a lso to the customers in more
                    than 40 countries in the world by making a ceaseless effort
                    in developing various kinds of new technologies and new
                    products.
                  </p>
                  <p>
                    We promise all of you that we will grow step by step to be a
                    world class company making an unlimited impression on
                    customers through constant technology innovation based on
                    environment products and health products under our own ionia
                    brand.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-4">
              <div className="title_box">
                <picture>
                  <source srcSet={india} />
                  <img src={india} alt="" />
                </picture>{" "}
                India
              </div>
              <Row>
                <Col md={5} className="about_tab_image  position-absolute">
                  <picture>
                    <source srcSet={about2} />
                    <img src={about2} alt="" className="w-100" />
                  </picture>
                </Col>
                <Col md={8} className="ms-auto mb-3 mt-0 mt-md-5">
                  <picture>
                    <source srcSet={about1} />
                    <img src={about1} alt="" className="w-100" />
                  </picture>
                </Col>
                <Col md={12}>
                  <p>
                    <strong className="primary-color">
                      IONIA CO., LTD. is a company specialized in developing and
                      supplying a variety of echo friendly health appliances
                      including alkaline water ionizers, IPL's and cold
                      dispenser manufactured in the state-of-the-art facilities
                      equipped with highly reliable and globally competitive
                      manufacturing systems.
                    </strong>
                  </p>
                  <p>
                    We are striving to secure the independent R&D technology by
                    establishing a technology R&D center and shipping our
                    products not only to large and medium sized companies in
                    Korea on an OEM/ODM basis but a lso to the customers in more
                    than 40 countries in the world by making a ceaseless effort
                    in developing various kinds of new technologies and new
                    products.
                  </p>
                  <p>
                    We promise all of you that we will grow step by step to be a
                    world class company making an unlimited impression on
                    customers through constant technology innovation based on
                    environment products and health products under our own ionia
                    brand.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="who_we_are__wrapper">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="title__wrap">
                <h2>Who is Ionia India?</h2>
              </div>
              <p>
                IONIA India is a pioneering company at the forefront of the
                wellness industry, dedicated to revolutionizing the way we
                hydrate and nourish our bodies. With a firm commitment to
                enhancing people's lives through innovative solutions, IONIA
                India brings the transformative power of ionized water to
                households across the nation.
              </p>
              <p>
                Our journey is fueled by a passion for holistic wellness and a
                drive to provide sustainable solutions. IONIA India's water
                ionizers are meticulously designed to offer convenience without
                compromising on quality. Each product is a blend of advanced
                technology and exceptional craftsmanship, ensuring that every
                sip of ionized water is an invigorating experience.
              </p>
              <p>
                What sets IONIA India apart is our unwavering commitment to
                accuracy. We understand that pH balance is a critical factor in
                achieving optimum health. Therefore, our water ionizers are
                engineered to deliver accurate pH balanced water consistently,
                offering a shield of protection against health imbalances.
              </p>
              <p>
                With a strong focus on education and awareness, IONIA India
                strives to empower individuals with knowledge about the benefits
                of ionized water. Through engaging campaigns and informative
                resources, we aim to create a community that is well-informed
                and inspired to take charge of their well-being.
              </p>
              <p>
                IONIA India stands as a beacon of innovation, wellness, and
                empowerment. We invite you to join us on this journey towards a
                healthier, more vibrant life, backed by the transformative
                potential of ionized water.
              </p>
            </Col>

            <Col
              md={6}
              className="
              wow
              zoomIn
              center"
              data-wow-delay="0.5s"
            >
              <picture>
                <source srcSet={wanjana} type="image/webp" />
                <img
                  src={wanjana}
                  alt="ionia"
                  width={"100%"}
                  height={"100%"}
                  className="w-100 h-100s about_image mt-5 mt-md-0"
                />
              </picture>
            </Col>
          </Row>
        </Container>
      </section>
      {/* next section (four section) */}
      <section className="about_us_wrapper">
        <Container>
          <Row className="align-items-center pt-4 pb-4">
            <Col md={6}>
              <picture>
                <source srcSet={visionimg} type="image/webp" />
                <img
                  src={visionimg}
                  alt="ionia"
                  className="w-100 h-100 about_image mt-5 mt-md-1"
                />
              </picture>
            </Col>
            <Col md={6}>
              <div className="title__wrap regular mt-4 mt-md-0">
                <h2>MISSION OF IONIA</h2>
              </div>
              <p>
                "At IONIA Korea, our mission is to revolutionize the way people
                hydrate and experience water, empowering individuals to embrace
                a healthier lifestyle through the transformative power of
                ionized alkaline water. We are dedicated to delivering
                cutting-edge water ionizer technology that enhances overall
                well-being, fosters environmental responsibility, and inspires a
                profound commitment to wellness. With a focus on innovation,
                education, and sustainability, we aim to be the catalyst for
                positive change, transforming lives one drop at a time."
              </p>
            </Col>
          </Row>
          <Row className="align-items-center pt-4 pb-4">
            <Col md={6} className="first__image  text-center order-md-2">
              <picture>
                <source srcSet={missionimg} type="image/webp" />
                <img
                  src={missionimg}
                  alt="ionia"
                  className="w-75 about_image mt-5 mt-md-0"
                />
              </picture>
            </Col>
            <Col md={6} className="offset-md-0">
              <div className="title__wrap regular mt-4 mt-md-0">
                <h2>VISION OF IONIA</h2>
                <p>
                  "Our vision at IONIA Korea is to lead the global movement
                  towards healthier living through the widespread adoption of
                  ionized alkaline water. We aspire to be the world's foremost
                  provider of innovative water ionizer solutions,
                  revolutionizing the way people approach hydration, wellness,
                  and environmental sustainability. By continually pushing the
                  boundaries of technology and knowledge, we envision a future
                  where every individual has access to clean, nourishing water,
                  empowering them to thrive in optimal health and vitality.
                  Together, we strive to create a healthier, happier world, one
                  ionized drop at a time."
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-5">
            <Col md={12} className="mb-4">
              <div className="title__wrap regular center_aligned">
                <h2>Core Values of IONIA Korea</h2>
              </div>
            </Col>
            <Col md={6}>
              <div className="shadow list__single">
                <span
                  className="  wow
                       zoomIn
                       center"
                  data-wow-delay="0.5s"
                >
                  <FaArtstation />
                </span>
                <div>
                  <h3>Integrity</h3>
                  <p>
                    We uphold the highest ethical standards in all aspects of
                    our business. Transparency, honesty, and accountability are
                    at the heart of our operations, ensuring trust and respect
                    in every interaction with our customers, partners, and team
                    members.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="shadow list__single">
                <span>
                  <FaBattleNet />
                </span>
                <div>
                  <h3>Innovation</h3>
                  <p>
                    We embrace a culture of continuous innovation and
                    technological advancement. We are committed to pushing the
                    boundaries of water ionizer technology, seeking new ways to
                    improve our products, and staying at the forefront of
                    industry trends.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className="shadow list__single">
                <span>
                  <FaMeta />
                </span>
                <div>
                  <h3>Wellness</h3>
                  <p>
                    The well-being of our customers is our foremost priority. We
                    are passionate about promoting healthy living and providing
                    products that contribute to improved hydration, vitality,
                    and overall wellness.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className="shadow list__single">
                <span>
                  <FaBlackTie />
                </span>
                <div>
                  <h3>Sustainability</h3>
                  <p>
                    We are deeply committed to environmental responsibility. Our
                    dedication to sustainable practices extends to every aspect
                    of our business, from product design and manufacturing to
                    promoting eco-conscious choices among our customers.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="shadow list__single">
                <span>
                  <FaCreativeCommons />
                </span>
                <div>
                  <h3>Customer-Centricity</h3>
                  <p>
                    We listen to our customers and prioritize their needs. We
                    strive to deliver exceptional experiences, going above and
                    beyond to ensure customer satisfaction and building
                    long-lasting relationships.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="shadow list__single">
                <span>
                  <FaReadme />
                </span>
                <div>
                  <h3>Education</h3>
                  <p>
                    We believe that knowledge empowers individuals to make
                    informed choices. Through education and awareness, we aim to
                    promote a deeper understanding of the benefits of ionized
                    water and its positive impact on health and well-being.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className="shadow list__single">
                <span>
                  <FaProductHunt />
                </span>
                <div>
                  <h3>Passion</h3>
                  <p>
                    Our team is fueled by a genuine passion for health,
                    wellness, and innovation. We approach our work with
                    enthusiasm and dedication, inspired by the potential to
                    transform lives through our products and initiatives.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className="shadow list__single">
                <span>
                  <FaD />
                </span>
                <div>
                  <h3>Diversity and Inclusion</h3>
                  <p>
                    We value diversity and foster an inclusive environment that
                    respects and embraces individual differences. We believe
                    that diverse perspectives enrich our creativity and enable
                    us to better serve a global audience.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className="shadow list__single">
                <span>
                  <FaE />
                </span>
                <div>
                  <h3>Excellence</h3>
                  <p>
                    We are committed to excellence in everything we do. From
                    product design to customer service, we strive for the
                    highest standards of quality and continuously seek
                    opportunities for improvement.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              {" "}
              <div className="shadow list__single">
                <span>
                  <FaHandshakeSimple />
                </span>
                <div>
                  <h3>Community Impact</h3>
                  <p>
                    We aim to make a positive impact on the communities we
                    serve. Through philanthropic efforts and partnerships, we
                    support initiatives that promote health, wellness, and
                    sustainability on a local and global scale.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* history section tabs */}
      <section>
        <Container>
          <Row>
            <Col>
              <div className="title_wrap center_aligned regular">
                <h2>History</h2>
                <p>
                  <strong>
                    IONIA is the historical trail contributed to the development
                    of Korean ionized water industry.
                  </strong>
                </p>
                <p>
                  Since its establishment in 1982, the company has contributed
                  to the remarkable economic development of the nation for 30
                  years, built up the infrastructure of a new global network
                  achieving the export of US$4 millions at the markets in the
                  world, and realizes the real health culture to keep clients’
                  health by researching, developing and manufacturing the
                  essential materials for human beings.
                </p>
              </div>
              <Tabs
                defaultActiveKey="20122018"
                id="uncontrolled-tab-example"
                className="mb-3 tabs-history"
              >
                <Tab eventKey="20122018" title="2012 - 2018">
                  <ul className="list__about">
                    {history20122018.map((el) => {
                      return (
                        <li>
                          <strong>{el.date}</strong>
                          {el.content}
                        </li>
                      );
                    })}
                  </ul>
                </Tab>
                <Tab eventKey="20102011" title="2010 - 2011">
                  <ul className="list__about">
                    {history20102011.map((el) => {
                      return (
                        <li>
                          <strong>{el.date}</strong>
                          {el.content}
                        </li>
                      );
                    })}
                  </ul>
                </Tab>
                <Tab eventKey="20062009" title="2006 - 2009">
                  <ul className="list__about">
                    {history20062009.map((el) => {
                      return (
                        <li>
                          <strong>{el.date}</strong>
                          {el.content}
                        </li>
                      );
                    })}
                  </ul>
                </Tab>
                <Tab eventKey="20051982" title="1982 - 2005">
                  <ul className="list__about">
                    {history20051982.map((el) => {
                      return (
                        <li>
                          <strong>{el.date}</strong>
                          {el.content}
                        </li>
                      );
                    })}
                  </ul>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
      <ExportingForCountries />
      <GetInTouch />
    </Layout>
  );
}
