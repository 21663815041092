/* global gtag */
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { FaCircleCheck, FaEnvelope, FaPhone } from "react-icons/fa6";
import { Link } from "react-router-dom";
import contactBg from "../assets/images/contact-us-banner.webp";
import { Helmet } from "react-helmet";
import ScrollToTop from "../components/ScrollToTop";
import { useRef, useState } from "react";
import axios from "axios";
import loader from "../assets/loader.gif";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    comment: "",
    header: "Ionia Contact Us Enquiry",
  });
  // const [isDisabled, setIsDisabled] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const handleClose = () => {
    setModalShow(false);
  };
  const handleClick = () => {
    setIsDisable(true);
  };
  const fetchAllocation = async () => {
    const obj = {
      notes: "From Website",
      processName: "Default Process",
      assignedTo: "+919654455530",
    };
    obj.customer = {
      name: formData.name,
      phoneNumber: formData.phone,
      message: formData.comment,
    };
    try {
      await axios
        .post("https://api.runo.in/v1/crm/allocation", obj, {
          headers: {
            "Content-Type": "application/json",
            "Auth-Key": "MWIybG41b2h5Mm9nNXFkM3U=",
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.phone === "" ||
      formData.comment === "" ||
      formData.phone.length < 10
    ) {
      setIsFormValid(false);
      return;
    }
    setIsDisable(true);

    fetchAllocation();
    await axios.post("https://commonapi.quickgst.in/event/email", formData);
    setIsFormValid(true);
    setModalShow(true);
    setFormData({
      name: "",
      phone: "",
      comment: "",
    });
    setIsDisable(false);
  };

  const tagHandler = (event) => {
    handleSubmitForm(event);
    if (typeof gtag === "function") {
      gtag("event", "conversion", {
        send_to: "AW-437309363/JRVdCMOlsMkDELOfw9AB",
      });
    } else {
      console.error(
        "gtag is not defined. Make sure it is properly included in your project."
      );
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Contact us | Ionia</title>
        <meta name="title" content="Alkaline Water Purifier | Contact us" />
        <meta
          name="description"
          content="For superior alkaline water ionisers that enhance health and hydration, get in contact with Ionia. Our staff is here to assist you in selecting the ideal ioniser."
        />
        <meta
          name="keywords"
          content="Alkaline Water Ioniser, Alkaline Water Purifier, Alkaline Water Purifier  India"
        />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta property="og:title" content="Alkaline Water Purifier" />
        <meta property="og:site_name" content="https://ionia.co.in/contacts" />
        <meta property="og:url" content="https://ionia.co.in/contacts" />
        <meta
          property="og:description"
          content="For superior alkaline water ionisers that enhance health and hydration, get in contact with Ionia. Our staff is here to assist you in selecting the ideal ioniser."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://ionia.co.in/contacts" />
      </Helmet>
      <ScrollToTop />
      <Modal
        size="md"
        aria-labelledby="connect-us"
        centered
        show={modalShow}
        onHide={handleClose}
        onClick={handleClick}
        backdropClassName="connect__overlay"
      >
        <Modal.Body>
          <div className="connect_box w-100 text-center">
            <h5 className="m-0">
              Thank you for choosing <span>IONIA!</span>
            </h5>
            <p>We will get back to your soon.</p>
            <FaCircleCheck
              style={{
                color: "#12c665",
                fontSize: "30px",
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
      <section
        className="contactus_banner"
        style={{ backgroundImage: `url(${contactBg})` }}
      >
        <Container>
          <h1>Contact Us</h1>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          <Col className="contact_us__page_wrapper">
            <Row>
              <Col md={6} className="p-5">
                <div className="title__wrap">
                  <h2>
                    Contact Ionia
                    <br />
                    India
                  </h2>
                </div>
                <Form onSubmit={handleSubmitForm}>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="form_name">
                        <Form.Control
                          type="text"
                          placeholder="Enter Name"
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                          value={formData.name}
                          name="name"
                        />
                        {!isFormValid && (
                          <Form.Text className="text-muted">
                            <span className="text-danger">
                              Please enter your name
                            </span>
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="form_phone">
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone"
                          maxLength="10"
                          onChange={(e) =>
                            setFormData({ ...formData, phone: e.target.value })
                          }
                          value={formData.phone}
                          name="phone"
                        />
                        {!isFormValid && (
                          <Form.Text className="text-muted">
                            <span className="text-danger">
                              Please enter valid contact no.
                            </span>
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="form_comments">
                        <Form.Control
                          as="textarea"
                          placeholder="Add comments"
                          style={{ height: "120px" }}
                          value={formData.comment}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              comment: e.target.value,
                            })
                          }
                          name="comment"
                        />
                        {!isFormValid && (
                          <Form.Text className="text-muted">
                            <span className="text-danger">
                              Enter your comment
                            </span>
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <<Form>.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="I agree that my submitted data is being collected and stored."
                    />
                  </Form.Group> */}
                  <Form.Group>
                    <Button
                      disabled={isDisable}
                      onClick={(e) => tagHandler(e)}
                      variant="primary"
                      type="submit"
                      className="submit"
                    >
                      Submit
                    </Button>
                    {isDisable && (
                      <picture>
                        <source srcSet={loader} type="gif/image" />
                        <img
                          src={loader}
                          alt="loader"
                          className="loader ms-4"
                        />
                      </picture>
                    )}
                  </Form.Group>
                </Form>
              </Col>
              <Col md={6} className="right_infobar p-5">
                <h2>
                  Sales & Promotion by{" "}
                  <strong style={{ display: "block" }}>
                    <Link to="https://rohininfotech.com/" target="_blank">
                      ROHIN INFOTECH
                    </Link>
                  </strong>
                </h2>
                <span>
                  <h4>
                    <strong>Registered Address:</strong>
                  </h4>
                  <p>
                    5B/9, Behind Kotak Mahindra Bank, Block 6, Tilak Nagar, New
                    Delhi, Delhi, 110018
                  </p>
                </span>
                <span>
                  <h4>
                    <strong>IONIA Care</strong>
                  </h4>
                  <p>
                    J-34, Ground Floor, Ganga Ram Vatika, Near Daya Dutt Vasisht
                    Hospital, Tilak Nagar, New Delhi-110018
                  </p>
                </span>
                <span>
                  <h4>
                    <strong>Phone:</strong>
                  </h4>
                  <p>
                    <FaPhone />
                    <Link to="tel:+91-9654455530">+91-9654455530</Link>
                  </p>
                </span>
                <span>
                  {" "}
                  <h4>
                    <strong>Email:</strong>
                  </h4>
                  <p>
                    <FaEnvelope />
                    <Link to="mailto:sales@ionia.co.in">sales@ionia.co.in</Link>
                  </p>
                </span>
              </Col>
            </Row>
          </Col>
        </Container>
      </section>
      <section className="p-0 lh-0">
        <div className="map lh-0">
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.7294259338732!2d77.0977698!3d28.637870399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4ccf744ffe7%3A0xa1fc9e8b33fa563c!2sIonia%20India%20-%20Alkaline%20water%20ionizer%20South%20Korea!5e0!3m2!1sen!2sin!4v1688120167485!5m2!1sen!2sin"
            width="100%"
            height="375"
            title="googleaddress"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe> */}
          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1g9q2PZSCTKHrAkurXH3nyp2gJZTKz1s&ehbc=2E312F"
            width="100%"
            height="500"
          ></iframe>
        </div>
      </section>
    </Layout>
  );
}
