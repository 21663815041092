import React from "react";
import Layout from "./Layout";
import { Helmet } from "react-helmet";
import ScrollToTop from "./ScrollToTop";
import { Col, Container, Row } from "react-bootstrap";
import ExportingForCountries from "./ExportingForCountries";
import Patent from "./Patent";
import Certificates from "./Certificates";
import GetInTouch from "./GetInTouch";
import cleaningImg from "../assets/images/rnd/cleaning-function.webp";

function RndLayout({ children }) {
  return (
    <>
      <Layout>
        <Helmet>
          <title>R&#038;D &#8211; Ionia India</title>
          <meta
            name="title"
            content="Alkaline Water Ioniser | Alkaline Water Purifier Delhi"
          />
          <meta
            name="description"
            content="See how Ionia's Alkaline Water Ioniser was developed through sophisticated R&D. Discover the health benefits of our cutting-edge technology."
          />
          <meta
            name="keywords"
            content="Alkaline Water Ionizer, Alkaline Water Machine, Alkaline Water Machine India "
          />
          <meta name="robots" content="index, follow" />
          <meta name="language" content="English" />
          <meta property="og:title" content="Alkaline Water Purifier" />
          <meta property="og:site_name" content="https://ionia.co.in/rnd" />
          <meta property="og:url" content="https://ionia.co.in/rnd" />
          <meta
            property="og:description"
            content="See how Ionia's Alkaline Water Ioniser was developed through sophisticated R&D. Discover the health benefits of our cutting-edge technology"
          />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://ionia.co.in/rnd" />
        </Helmet>
        <ScrollToTop />
        <section
          className="inner__banner rnd_banner__wrapper rnd_inner_banner pt-5 pb-5"
          style={{
            backgroundImage: `url(${cleaningImg})`,
            // height: "325px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container>
            <Row className="align-items-center">
              <Col md={7}>
                {/* <h1 className="text-start">R&D</h1> */}
                {/* <picture>
                  <source srcSet={cleaningImg} type="image/webp" />
                  <img
                    src={cleaningImg}
                    alt=""
                    className="w-100 position-relative rounded-3"
                  />
                </picture> */}
              </Col>
              <Col md={5}></Col>
            </Row>
          </Container>
        </section>
        {children}
        <ExportingForCountries />
        <Patent />
        <Certificates />
        <GetInTouch />
      </Layout>
    </>
  );
}

export default RndLayout;
