import React from "react";
import PrintLayout from "../components/PrintLayout";

const WipTable = () => {
  return (
    <div>
      <PrintLayout>
        <table width={"100%"}>
          <thead>
            <tr>
              <th
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                Sr No.
              </th>
              <th
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                MLFB No.
              </th>
              <th
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                Description
              </th>
              <th
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                Serial No.
              </th>
              <th
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                Qty.
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Array repeat  start  ===================================================*/}
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
            <tr>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                P6EP1334-1LB00 (){" "}
              </td>
              <td
                className="tbl_col"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                SITOP PSU100L 120V_24VDC_10A
              </td>
              <td
                className="tbl_col"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                SYSU/P5119475
              </td>
              <td
                className="tbl_col"
                colSpan={1}
                style={{ border: "1px solid black" }}
              >
                1
              </td>
            </tr>
           
          
            {/* Array repeat end  ===========================================================*/}
            <tr>
              <td style={{ border: "1px solid black" }} colSpan={6}>
                <strong style={{ fontWeight: "bold" }}>
                  Dispatch Through:
                </strong>{" "}
                &nbsp; By hand
              </td>
              <td style={{ border: "1px solid black" }} colSpan={6}>
                <strong style={{ fontWeight: "bold" }}>Vehicle No.:</strong>{" "}
                &nbsp;By hand
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }} colSpan={6}>
                <strong style={{ fontWeight: "bold" }}>Destination:</strong>
                &nbsp; Deva sir
              </td>
              <td style={{ border: "1px solid black" }} colSpan={6}>
                <strong style={{ fontWeight: "bold" }}>Eway Bill No:</strong>
              </td>
            </tr>
            <tr>
              <td colSpan={6} rowSpan={6}>
                <strong style={{ fontWeight: "bold" }}>Remarks:</strong> <br />
                447_TYPE2_MP01_MYER _0001 <br />
                (SO232400329_0001)
              </td>
              <td
                colSpan={6}
                rowSpan={6}
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  borderLeft: "1px solid black",
                  alignItems: "flex-end",
                }}
              >
                Authorised Signatory
              </td>
            </tr>
          </tbody>
        </table>
      </PrintLayout>
    </div>
  );
};

export default WipTable;
