import React from "react";
import BannerImg from "../assets/images/banner.webp";
import { Col, Container, Row } from "react-bootstrap";

function CommonBanner({ children }) {
  return (
    <section className="inner__banner">
      <picture>
        <source srcSet={BannerImg} type="image/webp" />
        <img src={BannerImg} alt="Wave" />
      </picture>
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <h1>{children}</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default CommonBanner;
