/* global gtag */
import { useEffect, useState } from "react";
import Layout from "../components/Layout";
import {
  Button,
  Carousel,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCircleCheck, FaStar } from "react-icons/fa6";
import wave from "../assets/images/wave.webp";
import en7p from "../assets/images/products/en-7p/img4.webp";
import io400 from "../assets/images/products/io-400/img3.webp";
import smS230 from "../assets/images/products/sm-S230/img5.webp";
import XBlue from "../assets/images/products/x-blue/img2.webp";
import ioniaMachine from "../assets/images/products/x-blue/img2.webp";
import testimonialImg1 from "../assets/images/testimonials/user.webp";
import slide6 from "../assets/images/testimonials/slide6.webp";
import khushi from "../assets/images/testimonials/khushi.webp";
import sheetal from "../assets/images/testimonials/sheetal.webp";
import moveon from "../assets/images/testimonials/moveon.webp";
import akash from "../assets/images/testimonials/akash.webp";
import vijaysingh from "../assets/images/testimonials/vijaysingh.webp";
import phScale from "../assets/images/pH-scale.webp";
import WOW from "wowjs";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import slide1 from "../assets/images/banner1.webp";
import slide2 from "../assets/images/banner2.webp";
import slide3 from "../assets/images/banner4.webp";
import slide4 from "../assets/images/banner3.webp";
import GetInTouch from "../components/GetInTouch";
import whoWeAreBg from "../assets/images/about-bg.webp";
import whyIconDurable from "../assets/images/icons/durable.webp";
import whyIconInstall from "../assets/images/icons/easy-installation.webp";
import whyIconHealth from "../assets/images/icons/healthcare.webp";
import whyIconPhLevel from "../assets/images/icons/ph.webp";
import logo from "../assets/images/ionia-logo.webp";
import ExportingForCountries from "../components/ExportingForCountries";
import Certificates from "../components/Certificates";
import Patent from "../components/Patent";
import google from "../assets/images/google.webp";
import ScrollToTop from "../components/ScrollToTop";
import axios from "axios";
import phscalesec from "../assets/images/aboutus/ph-scale-sec.webp";
import loader from "../assets/loader.gif";
import PhLevel from "../components/PhLevel";
import banner1 from "../../src/assets/images/banner1.webp";
import { Helmet } from "react-helmet";

const options = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  autoplay: true,
  autoplayTimeout: 3000,
  navText: ["<", ">"],
  smartSpeed: 1000,
  loop: true,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 3,
    },
  },
};

export default function Home() {
  const [contactData, setContactData] = useState({
    name: "Dear",
    phone: "",
    header: "Ionia Enquiry Home Popup",
  });
  const [isContactValid, setIsContactValid] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    if (!sessionStorage.getItem("id")) {
      setTimeout(() => {
        setModalShow(true);
      }, 7000);
    }
  }, []);

  const handleClose = () => {
    setModalShow(false);
  };
  const fetchAllocation = async () => {
    const obj = {
      notes: "From Website",
      processName: "Default Process",
      assignedTo: "+919654455530",
    };
    obj.customer = {
      name: contactData.name,
      phoneNumber: contactData.phone,
    };
    try {
      await axios
        .post("https://api.runo.in/v1/crm/allocation", obj, {
          headers: {
            "Content-Type": "application/json",
            "Auth-Key": "MWIybG41b2h5Mm9nNXFkM3U=",
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
      console.log("Error submitting form", err);
    }
  };

  const handleSubmitform = async (e) => {
    e.preventDefault();
    if (contactData.phone === "" || contactData.phone.length < 10) {
      setIsContactValid(false);
      return;
    }
    setIsDisable(true);
    fetchAllocation();
    // await axios
    // 	.post(
    // 		// "https://api.interakt.ai/v1/public/message/",
    // 		{
    // 			countryCode: "+91",
    // 			phoneNumber: contactData.phone,

    // 			callbackData: "hello",
    // 			type: "Template",
    // 			template: {
    // 				name: "welcome_message",
    // 				languageCode: "en",
    // 				headerValues: ["Dear"],
    // 				fileName: "file_name.pdf",
    // 				bodyValues: ["body_variable_value"],
    // 			},
    // 		},
    // 		{
    // 			headers: {
    // 				Authorization:
    // 					"Basic V1V0SE5wbW5RdmFsX24yMXppV0ttaGQzOWpnTTk1bnZ0Ym5iNjVuLTlrczo=",
    // 			},
    // 		}
    // 	)
    // 	.then((data) => {
    // 		if (data.data.result) {
    // 			sessionStorage.setItem("id", data.data.id);
    // 			setIsFormSubmitted(true);
    // 			setIsDisable(true);
    // 		}
    // 	});
    setIsContactValid(true);
    await axios.post("https://commonapi.quickgst.in/event/email", contactData);
    // setIsContactValid(true);
    // setIsDisable(false);
    sessionStorage.setItem("id", true);
    setIsFormSubmitted(true);
    setIsDisable(true);
  };

  return (
    <Layout>
      <ScrollToTop />
      <Modal
        size="md"
        aria-labelledby="connect-us"
        centered
        show={modalShow}
        onHide={handleClose}
        backdropClassName="connect__overlay"
      >
        <Modal.Header closeButton>
          <picture>
            <source srcSet={logo} type="image/webp" />
            <img src={logo} alt="Fallback Image" className="connect_logo" />
          </picture>
        </Modal.Header>
        <Modal.Body>
          {!isFormSubmitted ? (
            <div className="connect_box">
              <h5>
                Want to know more about the product?
                <span>
                  Just enter your <strong>Whatsapp</strong> number
                </span>
              </h5>
              <Form onSubmit={handleSubmitform}>
                <div className="connect_input">
                  <Form.Group controlId="phone" className="inputbox">
                    <Form.Control
                      type="tel"
                      placeholder="Enter phone"
                      value={contactData.phone}
                      onChange={(e) =>
                        setContactData({
                          ...contactData,
                          phone: e.target.value,
                        })
                      }
                      maxLength="10"
                      name="phone"
                    />
                    {isDisable && (
                      <picture>
                        <source srcSet={loader} type="gif/image" />
                        <img
                          src={loader}
                          alt="loader"
                          className="loader ms-2"
                        />
                      </picture>
                    )}
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isDisable}
                    className="submit"
                    onClick={(e) => {
                      handleSubmitform(e);
                      if (typeof gtag === "function") {
                        gtag("event", "conversion", {
                          send_to: "AW-437309363/JRVdCMOlsMkDELOfw9AB",
                        });
                      } else {
                        console.error(
                          "gtag is not defined. Make sure it is properly included in your project."
                        );
                      }
                    }}
                  >
                    Connect
                  </Button>
                </div>
                {!isContactValid && (
                  <Form.Text className="text-muted">
                    <span className="text-danger">
                      Please enter valid number...
                    </span>
                  </Form.Text>
                )}
              </Form>
            </div>
          ) : (
            <div className="connect_box text-center">
              <FaCircleCheck
                style={{
                  color: "#12c665",
                  fontSize: "60px",
                  marginBottom: "20px",
                }}
              />
              <h5 className="m-0">
                Thank you for choosing <span>IONIA!</span>
              </h5>
              <p>We will get back to your soon.</p>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <section className="video__banner">
        <Carousel
          indicators={false}
          navigators={false}
          pause={false}
          arrows={false}
          autoplay={true}
        >
          <Carousel.Item interval={4000}>
            <Row className="justify-content-center">
              <Col xs={12} md={12}>
                <picture>
                  <source srcSet={banner1} type="image/webp" />
                  <img src={banner1} alt="" className="w-100" />
                </picture>
                <div className="bannerText1">
                  <h2 className="wow zoomIn animated" data-wow-delay="1s">
                    Don't just <br /> Hydrate, IONIA! <br />
                    Experience the difference
                    <br /> with <span>Ionia</span>
                  </h2>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item interval={4000}>
            <Row className="justify-content-center">
              <Col xs={12} md={12}>
                <picture>
                  <source srcSet={slide2} type="image/webp" />
                  <img src={slide2} alt="" className="w-100" />
                </picture>
                <div className="bannerText2">
                  <h2 className="wow fadeInLeft animated" data-wow-delay="1s">
                    Experience the pure essence of hydration with Ionia'a
                    <span
                      className="wow fadeInLeft animated"
                      data-wow-delay="1.8s"
                    >
                      {" "}
                      Alkaline Water
                    </span>{" "}
                    Ionizers...
                  </h2>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item interval={4000}>
            <Row className="justify-content-center">
              <Col xs={12} md={12}>
                <pitcure>
                  <source srcSet={slide3} type="image/webp" />
                  <img src={slide3} alt="" className="w-100" />
                </pitcure>
                <div className="bannerText3">
                  <h2 className="wow zoomIn animated" data-wow-delay="1s">
                    Start your Day with a refreshing glass of{" "}
                    <strong>Ionia Alkaline Water</strong>
                    <span className="wow zoomIn animated" data-wow-delay="1.8s">
                      Hydrate, Invigorate and Conquer
                    </span>
                  </h2>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
          <Carousel.Item interval={4000}>
            <Row className="justify-content-center">
              <Col xs={12} md={12}>
                <picture>
                  <source srcSet={slide4} type="image/webp" />
                  <img src={slide4} alt="" className="w-100" />
                </picture>
                <div className="bannerText4">
                  <h2 className="wow fadeInLeft animated" data-wow-delay="1s">
                    Unlock the power of Wellness with <br /> Ionia's
                    Transformative{" "}
                    <span
                      className="wow fadeInLeft animated"
                      data-wow-delay="1.8s"
                    >
                      Alkaline Water Ionizers
                    </span>
                  </h2>
                </div>
              </Col>
            </Row>
          </Carousel.Item>
        </Carousel>
      </section>

      <section
        className="who_we_are__wrapper"
        style={{ backgroundImage: `url(${whoWeAreBg})` }}
      >
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="title__wrap">
                <h2>
                  Equip your whole family with the shield of accurate
                  <br />
                  <span className="text-center w-100">pH balanced water.​</span>
                </h2>
                <img src={wave} alt="Wave" className="wave__img" height={50} />
              </div>
              <p>
                By providing your family with accurate pH balanced water through
                the use of a water ionizer, you're offering them a proactive and
                convenient way to experience these diverse health benefits.
              </p>
              <ul
                id="image"
                className="product_detail_info_list column_count_2"
              >
                <li>Optimal Hydration</li>
                <li>Enhanced Nutrient Absorption</li>
                <li>Improved Digestion</li>
                <li>Boosted Immune System</li>
                <li>Detoxification Support</li>
                <li>Energy Boost</li>
                <li>Bone Health</li>
                <li>Reduced Acidity</li>
                <li>Anti-Aging Benefits</li>
                <li>Overall Wellness</li>
              </ul>
            </Col>
            <Col
              md={5}
              className="offset-md-1
              wow
              zoomIn
              center"
              data-wow-delay="0.5s"
            >
              <picture>
                <source srcSet={phscalesec} type="image/webp" />
                <img
                  src={phscalesec}
                  alt="ionia"
                  width={"100%"}
                  height={"100%"}
                  className="w-100 h-100s about_image mt-5 mt-md-0"
                />
              </picture>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="performace_of_electrod__wrapper silder__wrapper">
        <Container>
          <Row>
            <Col>
              <div className="title__wrap center_aligned regular">
                <h2>Our Products</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Carousel indicators={false} pause={false}>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col xs={9} md={11}>
                      <Row className="align-items-center">
                        <Col md={4} className="offset-md-0">
                          <div className="en7p__image align-item-center">
                            <picture>
                              <source srcSet={en7p} type="image/webp" />
                              <img
                                src={en7p}
                                height={300}
                                className="thumb__image w-100 h-100"
                                alt="Electrodes"
                              />
                            </picture>
                          </div>
                        </Col>
                        <Col md={6} className="offset-md-1">
                          <div className="title__wrap">
                            <h2>
                              Ionia EN-7P – Alkaline<span> Water Machine </span>
                            </h2>
                          </div>
                          <ul id="image" className="first">
                            <li>7 Platinum-Titanium Plates</li>
                            <li>Automatic Draining System</li>
                            <li>250W SMPS Power Control System</li>
                          </ul>
                          <Link
                            to="/products/ionia-en-7p-alkaline-water-machine"
                            className="readmore__btn"
                          >
                            Read More
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col xs={9} md={11}>
                      <Row className="align-items-center">
                        <Col md={4}>
                          <div className="io400u_image align-item-center">
                            <picture>
                              <source srcSet={io400} type="image/webp" />
                              <img
                                src={io400}
                                height={300}
                                className="thumb__image w-100 h-100"
                                alt="Electrodes"
                              />
                            </picture>
                          </div>
                        </Col>
                        <Col md={6} className="offset-md-1">
                          <div className="title__wrap">
                            <h2>
                              Ionia IO-400U – Alkaline{" "}
                              <span>Water Machine</span>
                            </h2>
                          </div>
                          <ul id="image" className="first">
                            <li>
                              DARC System – Automatic change of flow path (6
                              Overseas Patents)
                            </li>
                            <li>
                              Alarm message and ionization disabled during hot
                              water inflow.
                            </li>
                            <li>
                              Exact calculation of filter durability with Flux
                              control.
                            </li>
                            <li>Extended range of pH selection and control.</li>
                            <li>Modern Design.</li>
                          </ul>
                          <Link
                            to="/products/ionia-iq-400u-alkaline-water-machine"
                            className="readmore__btn"
                          >
                            Read More
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col xs={9} md={11}>
                      <Row className="align-items-center">
                        <Col md={4}>
                          <div className="sms230_image align-item-center">
                            <picture>
                              <source srcSet={smS230} type="image/webp" />
                              <img
                                src={smS230}
                                height={300}
                                className="thumb__image w-100 h-100"
                                alt="Electrodes"
                              />
                            </picture>
                          </div>
                        </Col>
                        <Col md={6} className="offset-md-1">
                          <div className="title__wrap">
                            <h2>
                              Ionia SM-S230TL – Alkaline
                              <span>Water Machine</span>
                            </h2>
                          </div>
                          <ul id="image" className="first">
                            <li>12(5P+7P) Platinum-Titanium Plates</li>
                            <li>Premium Dual Filtration</li>
                            <li>350W SMPS Power Control System</li>
                            <li>RFID Genuine filter chip</li>
                            <li>Super Strong Acidic (pH2.5~pH12)</li>
                          </ul>
                          <Link
                            to="/products/ionia-sm-s230tl-alkaline-water-machine"
                            className="readmore__btn"
                          >
                            Read More
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col xs={9} md={11}>
                      <Row className="align-items-center">
                        <Col md={4}>
                          <div className="xblue_image align-item-center">
                            <picture>
                              <source srcSet={XBlue} type="image/webp" />
                              <img
                                src={XBlue}
                                height={300}
                                className="thumb__image w-100 h-100"
                                alt="Electrodes"
                              />
                            </picture>
                          </div>
                        </Col>
                        <Col md={6} className="offset-md-1">
                          <div className="title__wrap">
                            <h2>
                              Ionia X-BLUE – Alkaline <span>Water Machine</span>
                            </h2>
                          </div>
                          <ul id="image" className="first">
                            <li>5 plate (Planized Titanium)</li>
                            <li>Luxury exterior design</li>
                            <li>
                              Automatic reversed cleaning system causes platinum
                              coated with titanium electrode to have at least 20
                              years longevity
                            </li>
                            <li>
                              Color LCD display easily identifies 3 different
                              functions
                            </li>
                          </ul>
                          <Link
                            to="/products/ionia-x-blue-alkaline-water-machine"
                            className="readmore__btn"
                          >
                            Read More
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="different_ph_levels__wrapper">
        <Container>
          <Row>
            <Col>
              <div className="title__wrap center_aligned regular">
                <h2>Purpose Of Different pH level Water</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="p-3 bg-gray">
                <Row>
                  <Col className="text-center">
                    <h3 className="mt-0">
                      8 pH to 9 pH Drinking Water Helps in:
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Hydrate Your Body</h4>

                      <pitcure>
                        <source srcSet={hydrateBody} type="image/webp" />

                        <img
                          src={hydrateBody}
                          className="w-100 h-auto"
                          alt=""
                        />
                      </pitcure>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Anti-Oxidant (Anti aging)</h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={antiAging} type="image/webp" />

                          <img
                            src={antiAging}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </pitcure>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Weight Loss</h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={weightLoss} type="image/webp" />

                          <img
                            src={weightLoss}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </pitcure>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Improve Liver & Kidney Health </h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={kidney} type="image/webp" />

                          <img src={kidney} className="w-100 h-auto" alt="" />
                        </pitcure>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Maintain Viscosity of Blood</h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={blood} type="image/webp" />

                          <img src={blood} className="w-100 h-auto" alt="" />
                        </pitcure>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Improve Immune System</h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <picture>
                          <source srcSet={immunesystem} type="image/webp" />

                          <img
                            src={immunesystem}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </picture>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <picture>
                          <source srcSet={healthySkin} type="image/webp" />

                          <img
                            src={healthySkin}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </picture>
                      </div>
                      <h4>Healthy skin</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={muscle} type="image/webp" />

                          <img src={muscle} className="w-100 h-auto" alt="" />
                        </pitcure>
                      </div>
                      <h4>Reduce Muscle Fatigue</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={lowBate} type="image/webp" />

                          <img src={lowBate} className="w-100 h-auto" alt="" />
                        </pitcure>
                      </div>
                      <h4>Lowbate Constipation</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <picture>
                          <source srcSet={improveDigestion} type="image/webp" />
                          <img
                            src={improveDigestion}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </picture>
                      </div>
                      <h4>Improve Digestion</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <picture>
                          <source srcSet={lubricateJoint} type="image/webp" />
                          <img
                            src={lubricateJoint}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </picture>
                      </div>
                      <h4>Lubricates Joints</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={boneHealth} type="image/webp" />

                          <img
                            src={boneHealth}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </pitcure>
                      </div>
                      <h4>Improve Bone Health</h4>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="border p-3 mt-4">
                <Row>
                  <Col className="text-center">
                    <h3 className="mt-0">
                      4.5 pH to 6 pH Beauty Water Helps in:
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                       center"
                  >
                    <div className="item">
                      <h4>Smooth Moisturized Skin</h4>

                      <picture>
                        <source srcSet={moisturizedSKin} type="image/webp" />

                        <img
                          src={moisturizedSKin}
                          className="w-100 h-auto "
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                       center"
                  >
                    <div className="item">
                      <h4>Hair Care</h4>
                      <picture>
                        <source srcSet={haircare} type="image/webp" />

                        <img src={haircare} className="w-100 h-auto " alt="" />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                     
                       center"
                  >
                    <div className="item">
                      <h4>Relieves Dryness</h4>
                      <picture>
                        <source srcSet={releivesDryness} type="image/webp" />

                        <img
                          src={releivesDryness}
                          className="w-100 h-auto   "
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                    
                       center"
                  >
                    <div className="item">
                      <h4>Healthy Scalp</h4>
                      <picture>
                        <source srcSet={healthyscalp} type="image/webp" />

                        <img
                          src={healthyscalp}
                          className="w-100 h-auto    "
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                     
                       center"
                  >
                    <div className="item">
                      <h4>Tone up your skin</h4>
                      <picture>
                        <source srcSet={toneupskin} type="image/webp" />

                        <img
                          src={toneupskin}
                          className="w-100 h-auto   "
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg 
                       center"
                  >
                    <div className="item">
                      <h4>Cleaning EyeGlass / Mirror</h4>
                      <picture>
                        <source srcSet={cleaingEyeglass} type="image/webp" />

                        <img
                          src={cleaingEyeglass}
                          className="w-100 h-auto"
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                     
                       center"
                  >
                    <div className="item">
                      <h4>Cleaning Window Pane</h4>
                      <picture>
                        <source srcSet={windowpane} type="image/webp" />

                        <img src={windowpane} className="w-100 h-auto" alt="" />
                      </picture>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col md={4} className="offset-md-0 center">
              <div className="border">
                <picture>
                  <source srcSet={phScale} type="image/webp" />
                  <img src={phScale} className="w-100 h-auto" alt="" />
                </picture>
                <h4 className="m-0 mt-3 bg-custom-primary text-white fw-600 text-center p-2 fs-6">
                  pH-scale
                </h4>
              </div>
            </Col>
            <Col md={8}>
              <Row>
                <Col md={12}>
                  <div className="bg-gray p-3">
                    <Row>
                      <Col>
                        <h3 className="mt-0">
                          2.5 pH Strong Acidic Water Helps in:
                        </h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} xs={6}>
                        <div className="item">
                          <div className="offset-md-0 center">
                            <img
                              src={utensils}
                              className="w-100 h-auto"
                              alt=""
                            />
                          </div>
                          <h4>2.5 pH Sterilize Utensils</h4>
                        </div>
                      </Col>
                      <Col md={3} xs={6}>
                        <div className="item">
                          <div className="offset-md-0 center">
                            <img
                              src={antiBacterial}
                              className="w-100 h-auto"
                              alt=""
                            />
                          </div>
                          <h4>Anti-Bacterial</h4>
                        </div>
                      </Col>
                      <Col md={3} xs={6}>
                        <div className="item">
                          <div
                            className="offset-md-0
                                    
                       center"
                          >
                            <img
                              src={insectidel}
                              className="w-100 h-auto"
                              alt=""
                            />
                          </div>
                          <h4>Use as an Insecticidal</h4>
                        </div>
                      </Col>
                      <Col md={3} xs={6}>
                        <div className="item">
                          <div
                            className="offset-md-0
                                    
                       center"
                          >
                            <img
                              src={mouthFreshner}
                              className="w-100 h-auto"
                              alt=""
                            />
                          </div>
                          <h4>Mouth Freshner</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="bg-gray p-3 mt-4">
                    <Row>
                      <Col>
                        <h3 className="mt-0">
                          9.0 pH to 10.5 pH Cooking Water Helps in:
                        </h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={3}
                        xs={6}
                        className="offset-md-0
                                    
                       center"
                      >
                        <div className="item">
                          <h4>Cooking</h4>
                          <img src={cooking} className="w-100 h-auto" alt="" />
                        </div>
                      </Col>
                      <Col
                        md={3}
                        xs={6}
                        className="offset-md-0
                                   
                       center"
                      >
                        <div className="item">
                          <h4>Making Food Tasty</h4>
                          <img
                            src={tastyFood}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col
                        md={3}
                        xs={6}
                        className="offset-md-0
                                
                       center"
                      >
                        <div className="item">
                          <h4>Less Time to Cook</h4>
                          <img
                            src={lessTimeCook}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col
                        md={3}
                        xs={6}
                        className="offset-md-0
                              
                       center"
                      >
                        <div className="item">
                          <h4>Remove Unnatural Taste</h4>
                          <img
                            src={unaturalTase}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="p-3 border mt-4 h-100">
                <Row>
                  <Col>
                    <h3 className="mt-0">7.0 pH Neutral Water Helps in:</h3>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={6}
                    xs={6}
                    className="offset-md-0
                                 
                       center"
                  >
                    <div className="item">
                      <h4>Taking Medicine</h4>
                      <img
                        src={takingMedicine}
                        className="w-100 h-auto"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col
                    md={6}
                    xs={6}
                    className="offset-md-0
                                  
                       center"
                  >
                    <div className="item">
                      <h4>Baby Formula</h4>
                      <img
                        src={babyFormula}
                        className="w-100 h-auto   "
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              md={8}
              className="offset-md-0
                                 
                       center"
            >
              <div className="border p-3 mt-4 h-100">
                <Row>
                  <Col className="text-end">
                    <h3 className="mt-0">
                      11.5 pH Emulsifier/Strong Alkaline Helps in:
                    </h3>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Col
                    md={6}
                    xs={12}
                    className="offset-md-0 
                                  
                       center"
                  >
                    <Row>
                      <Col md={6} xs={6} className="offset-md-0 center">
                        <div className="item">
                          <img
                            src={emulsifier}
                            className="w-100 h-auto "
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col
                        md={6}
                        xs={6}
                        className="offset-md-0
                                
                       center"
                      >
                        <div className="item">
                          <img
                            src={emulsifier2}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col md={12} className="offset-md-0 center">
                        <div className="item">
                          <h4>
                            Emulsifier (Removes Colouring & Polish and
                            agriculture chemical in the vegetable and pulse
                            etc.)
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={3}
                    xs={6}
                    className="offset-md-0
                                   
                       center"
                  >
                    <div className="item">
                      <img
                        src={soakMeat}
                        className="w-100 h-auto     "
                        alt=""
                      />
                      <h4>
                        Soak Meat for 15 minutes to drain off blood & remove
                        smell
                      </h4>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    xs={6}
                    className="offset-md-0
                                    
                       center"
                  >
                    <div className="item">
                      <img
                        src={makeupRemoval}
                        className="w-100 h-auto"
                        alt=""
                      />
                      <h4>Can be used as makeup removal</h4>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <PhLevel />

      <section className="why_ionia__wrapper">
        <Container>
          <Row>
            <Col md={12}>
              <div className="title__wrap center_aligned regular mb-5">
                <h2>Why Ionia Alkaline Water Ionizer Machine</h2>
              </div>
            </Col>
            <Col md={7}>
              <div className="rounded-2 bg-white ionia_item">
                <div className="icon">
                  <picture>
                    <source srcSet={whyIconDurable} type="image/webp" />
                    <img src={whyIconDurable} alt="Unmatched Durability" />
                  </picture>
                </div>
                <div className="content">
                  <h3>Unmatched Durability</h3>
                  <p>
                    Our ionizers are built to withstand the test of time,
                    offering exceptional longevity of up to 20 to 25 years.
                    Invest in a durable solution that ensures consistent
                    performance for decades to come.
                  </p>
                </div>
              </div>
              <div className="rounded-2 bg-white ionia_item">
                <div className="icon">
                  <picture>
                    <source srcSet={whyIconPhLevel} type="image/webp" />
                    <img src={whyIconPhLevel} alt="Customizable pH Levels" />
                  </picture>
                </div>
                <div className="content">
                  <h3>Customizable pH Levels</h3>
                  <p>
                    Experience the ultimate versatility with our ionizers,
                    providing three types of water - Alkaline, Acidic, and
                    Filtered. From 2.5pH to 11.5pH, you have the power to
                    customize your water based on your unique needs and
                    preferences.
                  </p>
                </div>
              </div>
              <div className="rounded-2 bg-white ionia_item">
                <div className="icon">
                  <picture>
                    <source srcSet={whyIconInstall} type="image/webp" />
                    <img src={whyIconInstall} alt="Easy Installation" />
                  </picture>
                </div>
                <div className="content">
                  <h3>Easy Installation</h3>
                  <p>
                    Say goodbye to complex installations. Our user-friendly
                    ionizers are designed for effortless setup, allowing you to
                    start enjoying the benefits of alkaline water quickly and
                    conveniently.
                  </p>
                </div>
              </div>
              <div className="rounded-2 bg-white ionia_item">
                <div className="icon">
                  <picture>
                    <source srcSet={whyIconHealth} type="image/webp" />
                    <img src={whyIconHealth} alt="Countless Health Benefits" />
                  </picture>
                </div>
                <div className="content">
                  <h3>Countless Health Benefits</h3>
                  <p>
                    Unlock a world of health advantages with our ionizers.
                    Experience anti-aging properties, weight loss support,
                    enhanced immunity, improved digestion, better liver and
                    kidney health, stronger bones, joint lubrication, and relief
                    from constipation.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={5} className="ps-2 pt-5 pt-md-0 ps-md-5">
              <div className="title__wrap">
                <h2>
                  Protect your family with one of the best water lonizer system
                </h2>
              </div>
              <div className="text-center offset-md-0 wow zoomIn center">
                <picture>
                  <source srcSet={ioniaMachine} type="image/webp" />

                  <img src={ioniaMachine} className="w-100 h-auto   " alt="" />
                </picture>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Google reviews section */}
      <section className="testimonials__wrapper silder__wrapper pb-5">
        <Container>
          <Row>
            <Col>
              <div className="title__wrap regular">
                <h2 className="primary-color">Google Reviews</h2>
              </div>
            </Col>
            <Col className="text-end">
              <div className="rating">
                <picture>
                  <source srcSet={google} type="image/webp" />
                  <img src={google} alt="" />
                </picture>
                <span>
                  Google <strong> 4.9</strong>
                </span>
              </div>
            </Col>
            <Col md={12}>
              <div className="review_wrapper mt-4">
                <Row className="align-items-center">
                  <Col md={6} xs={6}>
                    <h4 className="fw-bold">Rating</h4>
                    <div className="d-block d-md-flex gap-3 align-items-end">
                      <p className="m-0 d-flex gap-2">
                        <span>4.9</span>
                        <span>
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                          <FaStar />
                        </span>
                      </p>
                      <div className="rate">22 Reviews</div>
                    </div>
                  </Col>
                  <Col md={6} xs={6} className="text-end">
                    <Link
                      to="https://g.page/r/CTxW-jOLnvyhEBM/review"
                      target="_blank"
                      className="readmore__btn m-0"
                    >
                      Write a review
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={12} className="text-end mt-4">
              {/* <div className="google_reviews">
                <strong>Google Rating</strong>
                <span>
                  4.9 <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </span>
              </div> */}
              <Row>
                <Col xs={9} md={11} className="m-auto">
                  <OwlCarousel
                    className="slider-items owl-carousel"
                    {...options}
                  >
                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>
                            “IONIA is a great company that cares about the
                            environment, and the quality of water that they
                            serve is impeccable! The customer service is very
                            welcoming and friendly. Would definitely recommend
                            to anyone who is interested in drinking completely
                            ionized water with alkaline minerals added for that
                            extra health boost!”
                          </p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={testimonialImg1}
                            width="100%"
                            alt=""
                            className="thumb"
                          />
                          <div className="info">
                            <h3>Mukul Sohlot</h3>
                            <p>5 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>
                            " Best water alkaline machine in India. We are using
                            it from last one year and got amazing health
                            results."
                          </p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={slide6}
                            width="100%"
                            alt=""
                            className="thumb"
                          />
                          <div className="info">
                            <h3>Neha Rajput</h3>
                            <p>6 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>
                            "Ionia is best alkaline machine in budget. My
                            acidity and constipation problem is solved after 5
                            month of using alkaline water"
                          </p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={testimonialImg1}
                            width="100%"
                            alt=""
                            className="thumb"
                          />
                          <div className="info">
                            <h3>Ashok Jain</h3>
                            <p>5 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>
                            "Very happy of using this Ionizer as my thyroid
                            level has also got cured with in last two months
                            .Thanks to you guys."
                          </p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={testimonialImg1}
                            width="100%"
                            alt=""
                            className="thumb"
                          />
                          <div className="info">
                            <h3>Nimit Creators</h3>
                            <p>5 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>"Nice product.. experience is very good."</p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={sheetal}
                            width="100%"
                            alt=""
                            className="thumb"
                          />
                          <div className="info">
                            <h3>Sheetal Mittal</h3>
                            <p>5 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>
                            "2 years ago Excellent product in budget much lower
                            than competitor' s brand almost half rate with same
                            quality, value for money and health. Using since
                            last 5 months my family feel energetic and my
                            stomach acidity problem is gone."
                          </p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={vijaysingh}
                            width="100%"
                            alt=""
                            className="thumb"
                          />
                          <div className="info">
                            <h3>Vijay Singh Yadav</h3>
                            <p>5 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>"Good machine for alkaline water."</p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={khushi}
                            width="100%"
                            alt=""
                            className="thumb"
                          />
                          <div className="info">
                            <h3>Khushi Rajput</h3>
                            <p>5 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>"Best Alkaline Water Ionizer."</p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={testimonialImg1}
                            width="100%"
                            alt=""
                            className="thumb"
                          />
                          <div className="info">
                            <h3>Bhopendra Panwar</h3>
                            <p>5 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>Quick setup and the water is clear and clean.</p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={akash}
                            width="100%"
                            alt=""
                            className="thumb"
                          />
                          <div className="info">
                            <h3>Aakash Kumar Singh</h3>
                            <p>5 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="item">
                      <div className="review_box">
                        <div className="comment">
                          <div className="ratings">
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                            <FaStar />
                          </div>
                          <p>One of the best product.</p>
                        </div>
                        <div className="bottom_info">
                          <img
                            src={moveon}
                            width="100%"
                            alt=""
                            className="thumb"
                          />

                          <div className="info">
                            <h3>MOVE ON - LIFE</h3>
                            <p>5 Months ago</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </Col>
              </Row>
              {/* <div class="elfsight-app-a1d68ca4-a5ce-4355-9f96-2497e1213def"></div> */}
              {/* <GoogleReviews /> */}
            </Col>
          </Row>
        </Container>
      </section>

      <section className="different_php_level">
        <Container>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular center_aligned">
                <h3>About Us</h3>
                <h2>Right Promise Better Tomorrow</h2>
              </div>
            </Col>
          </Row>
          <Row className=" justify-content-md-end justify-content-start position-relative">
            <Col md={5} className="content__box">
              <p>
                <strong>IONIA CO., LTD.</strong> is a company specialised in
                developing and supplying a variety of echo friendly health
                appliances including alkaline water ionizers, IPL's and cold
                dispenser manufactured in the state-of-the-art facilities
                equipped with highly reliable and globally competitive
                manufacturing systems.
              </p>
              <p>
                We are striving to secure the independent R&D technology by
                establishing a technology R&D center and shipping our products
                not only to large and medium sized companies in Korea on an
                OEM/ODM basis but a lso to the customers in more than 40
                countries in the world by making a ceaseless effort in
                developing various kinds of new technologies and new products.
              </p>
              <p>
                We promise all of you that we will grow step by step to be a
                world class company making an unlimited impression on customers
                through constant technology innovation based on environment
                products and health products under our own ionia brand.
              </p>
            </Col>
            <Col md={7}>
              <div className="video_box">
                <iframe
                  src="https://www.youtube.com/embed/VtuISBFdVbI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  height="100%"
                ></iframe>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <ExportingForCountries />

      <Patent />

      <Certificates />

      <GetInTouch />
    </Layout>
  );
}
