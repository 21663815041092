import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import thumb from "../../assets/images/blogs/thumb4.webp";
import BannerImg from "../../assets/images/banner.webp";
import ScrollToTop from "../../components/ScrollToTop";
import { Link } from "react-router-dom";

export default function Howdoesanalkalineionizer() {
  return (
    <Layout>
      <Helmet>
        <title>How does an alkaline ionizer machine work?</title>
        <meta name="description" content="" />
      </Helmet>
      <ScrollToTop />
      <section className="inner__banner blog_detail_banner">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" />
        </picture>
        <Container>
          <Row>
            <Col>
              <h1>How does an alkaline ionizer machine work?</h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="p-0">
        <Container>
          <Row>
            <Col>
              <Breadcrumb className="blog_breadcrumb">
                <Breadcrumb.Item active>
                  <Link to="/blog" className="text-decoration-none">
                    Blog
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  How does an alkaline ionizer machine work?
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog_detail__wrapper">
        <Col
          md={8}
          className="shadow rounded-3 m-auto bg-white overflow-hidden"
        >
          <Row>
            <picture>
              <source srcSet={thumb} type="image/webp" />
              <img src={thumb} className="w-100" alt="" />
            </picture>
          </Row>
          <Row>
            <Col className="p-4">
              {/* COntent from here  */}
              <span className="tagline">Ionizer Machine</span>
              <h1 className="blog_title">
                How does an alkaline ionizer machine work?
              </h1>
              <div className="blog_info">
                <strong>Ionia Team - </strong>
                <span>March 19, 2023</span>
              </div>
              <div className="content">
                <p>
                  Who doesn’t want clean and filtered water? At least, not
                  someone whom I know. With the same intent, an alkaline ionizer
                  machine comes into the picture. It provides us with alkaline
                  water with a balanced pH containing antioxidant properties and
                  hydrogen. But, do you know how this wonder happens?
                </p>
                <h3 style={{ fontWeight: 700, fontSize: "20px" }}>
                  Know below how alkaline ionizer functions.
                </h3>
                <p>
                  An appliance that we attach to sinks of our kitchens to get
                  alkaline water is generally known by the name alkaline
                  ionizer.
                </p>
                <p>
                  On the very first note, water passes within the filters of the
                  machine and it is considered the most significant step.
                </p>
                <p>
                  The filtration process leads to the removal of chemicals or
                  pesticides that might be present in the water. After that,
                  water in its filtered form goes to the chamber and roll over
                  the plates. The more the plates, the more the purity.
                </p>
                <p>
                  Plates offer negative and positive charges to water, which
                  means ionizing it. Once it turns acidic, it becomes ready to
                  come out from two places, one in alkaline form and another in
                  acidic form.
                </p>
                <p>
                  Use acidic one for cleaning or skin application as it is known
                  with the name beauty water and you can drink the alkaline one.
                </p>
                <p>
                  Among the important points to be considered, we should go for
                  ionizers with two filters as it does the filtration job to
                  immense satisfaction than with one filter ionizer.
                </p>
                <p>
                  If looking for the most supreme two filter alkaline ionizer
                  machine, Ionia is a great pick to have. With their
                  platinum-coated titanium plate electrodes and two filters, one
                  can surely expect purity.
                </p>
                <p>
                  It is necessary to check that the plates of the machine as
                  solid and mesh hybrid is better than solely solid or mesh.
                  Moreover, check the plate count as well as ensure the plates
                  are platinum-coated titanium ones.
                </p>
                <p>
                  With these snapshots in mind, you will never go for the wrong
                  ionizer.
                </p>
              </div>
              {/* COntent END here  */}
            </Col>
          </Row>
        </Col>
      </section>
    </Layout>
  );
}
