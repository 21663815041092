import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import "bootstrap/dist/css/bootstrap.min.css";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import HealthBenefit from "./pages/HealthBenefits";
import IoU from "./pages/products/IoU";
import En7p from "./pages/products/En7p";
import Sm230 from "./pages/products/Sm230";
import XBlue from "./pages/products/XBlue";
import UseOfIonizerWaterMachine from "./pages/blogs/UseOfIonizerWaterMachine";
import Blogs from "./pages/Blog";
import Top5AlkalineWaterIonizerMachines from "./pages/blogs/Top5AlkalineWaterIonizerMachines ";
import TheBenefitsofAlkalineWater from "./pages/blogs/TheBenefitsofAlkalineWater";
import HowtochoosethebestAlkaline from "./pages/blogs/HowtochoosethebestAlkaline";
import WaterIonizerMachine from "./pages/blogs/Water-Ionizer-Machine";
import Howdoesanalkalineionizer from "./pages/blogs/Howdoesanalkalineionizer";
import AlkalineWaterIonizerIndia from "./pages/blogs/AlkalineWaterIonizerIndia";
import Franchises from "./pages/Franchise";
import RnD from "./pages/RnD";
import Media from "./pages/Media";
import Privacyndpolicy from "./pages/Privacyndpolicy";
import BuyProducts from "./pages/BuyProducts";
import TermsndCondition from "./pages/TermsndCondition";
import Refundsndcancellations from "./pages/Refundsndcancellations";
import Shippingnddeliverypolicy from "./pages/Shippingnddeliverypolicy";
import Noida from "./pages/subdomain/Noida";
import BenefitsOfAlkalineWater from "./pages/blogs/BenefitsOfAlkalineWater";
import Print from "./pages/Print";
// import BlogPagination from "./pages/BlogPagination";
import GoogleReviews from "./components/Googlereview";
// import Jaipur from "./pages/subdomain/Jaipur";
import Gurugram from "./pages/subdomain/Gurgaon";
import QrCode from "./components/QrCode";
import DurgaPooja from "./components/DurgaPooja";
import Alwar from "./pages/subdomain/Alwar";
import Dausa from "./pages/subdomain/Dausa";
import Jaipur from "./pages/subdomain/Gorakhpur";
import Ratlam from "./pages/subdomain/Ratlam";
import Rajkot from "./pages/subdomain/Rajkot";
import Ahmedabad from "./pages/subdomain/Ahmedabad";
import Bhilwara from "./pages/subdomain/Bhilwara";
import Gorakhpur from "./pages/subdomain/Gorakhpur";
import NextGenerationWaterIonizer from "./pages/blogs/NextGenerationWaterIonizerMachine";
import WaterAlkalineFranchise from "./pages/blogs/WaterAlkalneFranchise";
import WipPrint from "./components/WipPrint";
import PrintLayout from "./components/PrintLayout";
import WipTable from "./pages/WipTable";
import WaterIonizerMachineIndia from "./pages/blogs/WaterIonizerMachineIndia";
import ChoosePerfectAlkalineMachine from "./pages/blogs/ChoosePerfectAlkalineMachine";

const router = createBrowserRouter([
  // SUBPAGES ROUTES START

  {
    path: "gorakhpur",
    element: <Gorakhpur />,
  },
  {
    path: "bhilwara",
    element: <Bhilwara />,
  },
  {
    path: "ahemdabad",
    element: <Ahmedabad />,
  },
  {
    path: "ratlam",
    element: <Ratlam />,
  },
  {
    path: "rajkot",
    element: <Rajkot />,
  },
  {
    path: "dausa",
    element: <Dausa />,
  },
  {
    path: "alwar",
    element: <Alwar />,
  },
  {
    path: "jaipur",
    element: <Jaipur />,
  },
  {
    path: "gurugram",
    element: <Gurugram />,
  },
  {
    path: "noida",
    element: <Noida />,
  },

  // SUBPAGES ROUTES END

  {
    path: "durgapooja",
    element: <DurgaPooja />,
  },
  {
    path: "mira-modal-school",
    element: <QrCode />,
  },
  {
    path: "franchises",
    element: <Franchises />,
  },
  {
    path: "googlereviews",
    element: <GoogleReviews />,
  },
  // {
  //   path: "blogPagination",
  //   element: <BlogPagination />,
  // },
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "about-us",
    element: <About />,
  },
  {
    path: "products",
    element: (
      <Products
        product1={false}
        product2={false}
        product3={false}
        product4={false}
      />
    ),
  },
  {
    path: "products/ionia-iq-400u-alkaline-water-machine",
    element: <IoU />,
  },
  {
    path: "products/ionia-en-7p-alkaline-water-machine",
    element: <En7p />,
  },
  {
    path: "products/ionia-sm-s230tl-alkaline-water-machine",
    element: <Sm230 />,
  },
  {
    path: "products/ionia-x-blue-alkaline-water-machine",
    element: <XBlue />,
  },
  {
    path: "contacts",
    element: <ContactUs />,
  },
  {
    path: "privacy-policy-2",
    element: <Privacyndpolicy />,
  },
  {
    path: "terms-and-condition",
    element: <TermsndCondition />,
  },
  {
    path: "refunds-and-cancellations",
    element: <Refundsndcancellations />,
  },
  {
    path: "shipping-and-delivery-policy",
    element: <Shippingnddeliverypolicy />,
  },
  {
    path: "franchises",
    element: <Franchises />,
  },
  {
    path: "media",
    element: <Media />,
  },
  {
    path: "rnd",
    element: <RnD />,
  },
  {
    path: "health-benefits",
    element: <HealthBenefit />,
  },
  {
    path: "franchise-opportunities",
    element: <Franchises />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "blog",
    element: <Blogs />,
  },
  {
    path: "blog/use-of-ionizer-water-machine-good-or-bad",
    element: <UseOfIonizerWaterMachine />,
  },
  {
    path: "blog/top-5-alkaline-water-ionizer-machines-in-india",
    element: <Top5AlkalineWaterIonizerMachines />,
  },
  {
    path: "blog/the-benefits-of-alkaline-water-and-why-you-need-an-ionizer-machine-in-india",
    element: <TheBenefitsofAlkalineWater />,
  },
  {
    path: "blog/how-to-choose-the-besta-kaline-water-ionizer-machine-india",
    element: <HowtochoosethebestAlkaline />,
  },
  {
    path: "blog/water-ionizer-machine-incredible-benefits",
    element: <WaterIonizerMachine />,
  },
  {
    path: "blog/how-does-an-alkaline-ionizer-machine-work",
    element: <Howdoesanalkalineionizer />,
  },
  {
    path: "blog/alkaline-water-ionizer-india-ionia-homeAll-posts",
    element: <AlkalineWaterIonizerIndia />,
  },
  {
    path: "blog/benefits-of-alkaline-water",
    element: <BenefitsOfAlkalineWater />,
  },
  {
    path: "blog/water-alkaline-franchise",
    element: <WaterAlkalineFranchise />,
  },
  {
    path: "blog/next-generation-water-ionizer",
    element: <NextGenerationWaterIonizer />,
  },
  {
    path: "blog/water-ionizer-machine-india",
    element: <WaterIonizerMachineIndia />,
  },
  {
    path: "blog/how-do-you-choose-perfect-alkaline-ionizer-machine",
    element: <ChoosePerfectAlkalineMachine />,
  },
  {
    path: "buy-products",
    element: <BuyProducts />,
  },
  {
    path: "Wip-Print",
    element: <WipPrint />,
  },
  {
    path: "print-layout",
    element: <PrintLayout />,
  },
  {
    path: "wip-table",
    element: <WipTable />,
  },
]);

function App() {
  // const  kiwi ="";
  // (function (w, d, s, c, r, a, m) {
  //   w["KiwiObject"] = r;
  //   w[r] =
  //     w[r] ||
  //     function () {
  //       (w[r].q = w[r].q || []).push(arguments);
  //     };
  //   w[r].l = 1 * new Date();
  //   a = d.createElement(s);
  //   m = d.getElementsByTagName(s)[0];
  //   a.async = 1;
  //   a.src = c;
  //   m.parentNode.insertBefore(a, m);
  // })(
  //   window,
  //   document,
  //   "script",
  //   "https://app.interakt.ai/kiwi-sdk/kiwi-sdk-17-prod-min.js?v=" +
  //     new Date().getTime(),
  //   "kiwi"

  // );
  // window.addEventListener("load", function () {
  //   kiwi.init("", "FvxpKq9OgE4jVy8p49MlFIlrLUtJlgqG", {});
  // });
  return <RouterProvider router={router} />;
}

export default App;
