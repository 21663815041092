import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import axios from "axios";
import loader from "../../src/assets/loader.gif";
import { FaCircleCheck } from "react-icons/fa6";
import { Link } from "react-router-dom";
import durga from "../../src/assets/images/durga.webp";

export default function DurgaPooja() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    header: "From Durga Pooja (shiv-o-durga mandir)",
  });
  const [isFormValid, setIsFormValid] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const handleClose = () => {
    setModalShow(false);
  };
  const handleClick = () => {
    setIsDisable(true);
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (
      // formData.name === "" ||
      formData.phone === "" ||
      formData.phone.length < 10
    ) {
      setIsFormValid(false);
      return;
    }
    setIsDisable(true);
    // await axios
    //   .post(
    //     "https://api.interakt.ai/v1/public/message/",
    //     {
    //       countryCode: "+91",
    //       phoneNumber: formData.phone,
    //       callbackData: "hello",
    //       type: "Template",
    //       template: {
    //         name: "welcome_message",
    //         languageCode: "en",
    //         headerValues: [`${formData.phone}`],
    //         fileName: "file_name.pdf",
    //         bodyValues: ["body_variable_value"],
    //       },
    //     },
    //     {
    //       headers: {
    //         Authorization:
    //           "Basic V1V0SE5wbW5RdmFsX24yMXppV0ttaGQzOWpnTTk1bnZ0Ym5iNjVuLTlrczo=",
    //       },
    //     }
    //   )
    //   .then((data) => {
    //     if (data.data.result) {
    //       setModalShow(true);
    //       setFormData({
    //         name: "",
    //         phone: "",
    //       });
    //       setIsDisable(false);
    //     }
    //   });

    await axios.post("https://commonapi.quickgst.in/event/email", formData);
    setIsFormValid(true);
  };
//   const pageChat = window.location;
//   console.log(pageChat.pathname);

//   const chatBox = document.getElementById("kiwi-big-iframe-wrapper");

//   const removeButton =

//   useEffect(() => {      
//     if (pageChat.pathname.slice(1) === "durgapooja") {
//       chatBox.style.display = "none";
//     }
//   }, [pageChat.pathname]);

  return (
    <section className="Whole__form__wrapper p-0 mt-0">
      <Container>
        <Row>
          <Col>
            <Row className="justify-content-center">
              <Col md={5} xs={10}>
                <div className=" overflow-hidden mt-3 mb-3">
                  <div className="top_banner border-1 border-bottom p-3">
                    <picture>
                      <source srcSet={durga} type="image/webp" />
                      <img src={durga} alt="" className="w-100" />
                    </picture>
                  </div>
                  {/* <div className="form__wrapper">
                    <h2>Navratri Form</h2>
                  </div> */}
                  <div className="form__input p-4 mt-3">
                    <h4 className="text-center fw-bold mb-4">Enter your details</h4>
                    <Form onSubmit={handleSubmitForm}>
                      <Form.Group className="mb-3" controlId="form_name">
                        <Form.Control
                          type="text"
                          placeholder="Enter Name"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              name: e.target.value,
                            })
                          }
                          value={formData.name}
                          name="name"
                        />
                        {/* {!isFormValid && (
                          <Form.Text className="text-muted">
                            <span className="text-danger">
                              Please enter your name
                            </span>
                          </Form.Text>
                        )} */}
                      </Form.Group>
                      <Form.Group className="mb-3 " controlId="form_phone">
                        <Form.Control
                          type="text"
                          placeholder="Enter Phone"
                          maxLength="10"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              phone: e.target.value,
                            })
                          }
                          value={formData.phone}
                          name="phone"
                        />
                        {!isFormValid && (
                          <Form.Text className="text-muted">
                            <span className="text-danger">
                              Please enter valid contact no.
                            </span>
                          </Form.Text>
                        )}
                      </Form.Group>
                      <Form.Group className="popup_gif_right">
                        <Button
                          disabled={isDisable}
                          // onClick={handleClick}
                          variant="primary"
                          type="submit"
                          className="submit w-100 p-2"
                        >
                          Submit
                        </Button>
                        {isDisable && (
                          <picture>
                            <source srcSet={loader} type="gif/image" />
                            <img
                              src={loader}
                              alt="loader"
                              className="loader ms-4"
                            />
                          </picture>
                        )}
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        size="md"
        aria-labelledby="connect-us"
        centered
        show={modalShow}
        onHide={handleClose}
        onClick={handleClick}
        backdropClassName="connect__overlay"
      >
        <Modal.Body>
          <div className="connect_box w-100 text-center">
            <h5 className="m-0">
              Thank you <span className="fs-3">Happy Navratri</span>
            </h5>
            <FaCircleCheck
              style={{
                color: "#12c665",
                fontSize: "30px",
                marginBottom: "30px",
              }}
            />
            <p>
              Visit our website
              <Link         
               to="/"
               className="bg-custom-primary text-white text-decoration-none d-inline m-2 p-3 pt-1 pb-1 rounded">
                Ionia.co.in
              </Link>
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}
