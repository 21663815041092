import Layout from "../../components/Layout";
import { Col, Container, Row, Carousel, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import RelatedProducts from "../../components/RelatedProducts";
import ScrollToTop from "../../components/ScrollToTop";
import img4 from "../../assets/images/products/en-7p/img4.webp";
import en7p1 from "../../assets/images/products/en-7p/en7p1.webp";
import en7p2 from "../../assets/images/products/en-7p/en7p2.webp";
import en7p4 from "../../assets/images/products/en-7p/en7p4.webp";
import en7p5 from "../../assets/images/products/en-7p/en7p5.webp";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import ioniaen7p from "../../assets/images/products/en-7p/ionia-en7p.mp4";
import BannerImg from "../../assets/images/banner.webp";

export default function En7p() {
  const value = true;
  return (
    <Layout>
      <Helmet>
        <title>
          Ionia EN-7P &#8211; Alkaline Water Machine &#8211; Ionia India
        </title>
        <meta
          name="description"
          content="7 Planum-Titanium Plates Automatic Draining System 250W SMPS Power Control System"
        />
      </Helmet>
      <ScrollToTop />
      <section className="inner__banner health_benefit_banner">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" />
        </picture>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1>Ionia EN-7P – Alkaline Water Machine</h1>
            </Col>
            <Col md={6}>
              <div className="rounded-4 overflow-hidden lh-0 video_box">
                <ReactPlayer
                  url={ioniaen7p}
                  playing
                  loop
                  controls={false}
                  muted
                  width="100%"
                  height="100%"
                  autoPlay
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product_detail_info silder__wrapper pb-4">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <Carousel indicators={false} pause={false}>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={img4} type="image/webp" />
                          <img
                            src={img4}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={en7p1} type="image/webp" />
                          <img
                            src={en7p1}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={en7p2} type="image/webp" />
                          <img
                            src={en7p2}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={en7p4} type="image/webp" />
                          <img
                            src={en7p4}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          <source srcSet={en7p5} type="image/webp" />
                          <img
                            src={en7p5}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col md={6}>
              <div className="title__wrap regular mb-5 mt-3 mt-md-0">
                <h2>Ionia EN-7P – Alkaline Water Machine</h2>
              </div>
              <div className="tag">
                <h4>
                  <strong>Category</strong> : Water Ionizer
                </h4>
                <h4>
                  <strong>Product ID</strong> : 1735
                </h4>
              </div>
              <ul className="product_detail_info_list">
                <li>7 Planum-Titanium Plates</li>
                <li>Automatic Draining System</li>
                <li>250W SMPS Power Control System</li>
              </ul>
              <Link to="/rnd" className="readmore__btn ">
                Reseaerch On This Product
              </Link>
            </Col>
          </Row>
          {/* </Container>
      </section>
      <section className="p-0">
        <Container> */}
          <Row className="mt-4">
            <Col>
              <div className="title__wrap regular mb-3">
                <h2 className="underline">Description</h2>
              </div>

              <p>
                <strong>Performance of Electrodes:</strong> In their latest
                models are five of the most advanced planum-titanium electrodes
                in the world. When a cross-section of an electrode is examined
                at 700 times magnification, you can see that the electrodes are
                now covered in a super fine mesh with very district points and
                valleys. This greatly increases the surface area without having
                to increase the size. We guarantee that no other models,
                regardless of price, will produce under similar conditions, such
                a high and low pH or ORP (Oxidation-reduction potential).
              </p>
              <p>
                <strong> 7P Planum – Titanium Plates:</strong> 7 Proprietary
                electrodes with 6 layers of planum ensuring the best ionizer
                enriched water
              </p>
              <p>
                <strong>SMPS Power Control System: </strong>Performance of
                Electrodes: Applied SMPS Power Equipment more stable than
                existing trans method Applied Maximum Output 250W maximum output
                existing trans method 150W. We applied 250W so that 7P machine
                can operate a stable state.
              </p>
              <p>
                <strong>All-in-one type of water supply valve:</strong> It is
                easy to use and convenient to control the fine flow rate as the
                water supply valve (valve to turn water on/off) is integrated
                into the product body.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product_detail_desc p-0">
        <Container>
          <Row>
            <Col>
              <div className="title__wrap regular mb-4 mt-5">
                <h2 className="underline">Dual Filtration</h2>
              </div>
              <p>
                <strong>
                  The difference of filter technology makes water cleaner and
                  healthier:
                </strong>
                It filters the harmful substance and keeps the beneficial
                mineral in water to make water clean and rich in minerals
              </p>
              <div className="speci">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th colSpan={4}>Specification</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Power Supply</strong>
                      </td>
                      <td>SMPS</td>
                      <td>
                        <strong>Dimension</strong>
                      </td>
                      <td>340 x 360 x 140 (W x H x D)mm</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Electrode</strong>
                      </td>
                      <td>7 plate (Planized Titanium)</td>
                      <td>
                        <strong>Electrolysis Setting</strong>
                      </td>
                      <td>
                        Alkaline (4 Level), Purified Water, Acidic (4 Level)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Cleaning</strong>
                      </td>
                      <td>Automatic Cleaning</td>
                      <td>
                        <strong>pH value</strong>
                      </td>
                      <td>pH 4.0(±0.5)~10.5(±0.5)</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Display Method</strong>
                      </td>
                      <td>Display Method</td>
                      <td>
                        <strong>Number Of Filters</strong>
                      </td>
                      <td>Two Filters</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Filter Media</strong>
                      </td>
                      <td>Activated Carbon Filter, UF Membrane Filter</td>
                      <td>
                        <strong>Filter Indicator</strong>
                      </td>
                      <td>LED Indicator</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <RelatedProducts
        product1={true}
        product2={false}
        product3={false}
        product4={false}
      />
    </Layout>
  );
}
