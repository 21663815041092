import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import ScrollToTop from "../components/ScrollToTop";
import CommonBanner from "../components/CommonBanner";
import GetInTouch from "../components/GetInTouch";
import img1 from "../assets/images/media/ionia-event/1ZEE09896.webp";
import img2 from "../assets/images/media/ionia-event/2ZEE09901.webp";
import img3 from "../assets/images/media/ionia-event/4ZEE09937.webp";
import img4 from "../assets/images/media/ionia-event/5ZEE09941.webp";
import img5 from "../assets/images/media/ionia-event/6ZEE09980.webp";
import img6 from "../assets/images/media/ionia-event/7ZEE09987.webp";
import img7 from "../assets/images/media/ionia-event/8ZEE00061.webp";
import img8 from "../assets/images/media/ionia-event/9ZEE00062.webp";
import img9 from "../assets/images/media/ionia-event/10ZEE00094.webp";
import img10 from "../assets/images/media/ionia-event/11ZEE00114.webp";
import img11 from "../assets/images/media/ionia-event/13ZEE00242.webp";
import img12 from "../assets/images/media/ionia-event/14ZEE00289.webp";
import img13 from "../assets/images/media/ionia-event/15ZEE00502.webp";
import img14 from "../assets/images/media/ionia-event/16ZEE00524.webp";
import img15 from "../assets/images/media/ionia-event/17ZEE00527.webp";
import img16 from "../assets/images/media/ionia-event/18ZEE00562.webp";
import img17 from "../assets/images/media/ionia-event/19ZEE00704.webp";
import img18 from "../assets/images/media/ionia-event/20ZEE00727.webp";
import img19 from "../assets/images/media/ionia-event/21ZEE00740.webp";
import img20 from "../assets/images/media/ionia-event/22ZEE00749.webp";
//ionia india images
import img21 from "../assets/images/media/2022/01IMG_8095.webp";
import img22 from "../assets/images/media/2022/02IMG_8091.webp";
import img23 from "../assets/images/media/2022/03IMG_8087.webp";
import img24 from "../assets/images/media/2022/4IMG_8097.webp";
import img25 from "../assets/images/media/2022/IMG_7702.webp";
import img26 from "../assets/images/media/2022/IMG_7869.webp";
import img27 from "../assets/images/media/2022/IMG_7899.webp";
import img28 from "../assets/images/media/2022/IMG_7939.webp";
import img29 from "../assets/images/media/2022/IMG_7978.webp";
import img30 from "../assets/images/media/2022/IMG_7990.webp";
import img31 from "../assets/images/media/2022/IMG_8007.webp";
import img32 from "../assets/images/media/2022/IMG_8038.webp";
import img33 from "../assets/images/media/2022/IMG_8063.webp";
import img34 from "../assets/images/media/2022/IMG_8085.webp";
import img35 from "../assets/images/media/2022/IMG_8088.webp";
import img36 from "../assets/images/media/2022/IMG_8091.webp";
import img37 from "../assets/images/media/2022/IMG_8093.webp";
import img38 from "../assets/images/media/2022/IMG_7856.webp";
import img39 from "../assets/images/media/2022/IMG_7879.webp";
import img40 from "../assets/images/media/2022/IMG_8020.webp";

import { useState } from "react";
import { Col, Container, Row, Tabs, Tab } from "react-bootstrap";

import PatentModal from "../components/PatentModal";

const photos = [
  {
    src: img1,
  },
  {
    src: img2,
  },
  {
    src: img3,
  },
  {
    src: img4,
  },
  {
    src: img5,
  },
  {
    src: img6,
  },
  {
    src: img7,
  },
  {
    src: img8,
  },
  {
    src: img9,
  },
  {
    src: img10,
  },
  {
    src: img11,
  },
  {
    src: img12,
  },
  {
    src: img13,
  },
  {
    src: img14,
  },
  {
    src: img15,
  },
  {
    src: img16,
  },
  {
    src: img17,
  },
  {
    src: img18,
  },
  {
    src: img19,
  },
  {
    src: img20,
  },
];
// const photos1 = [
//   {
//     src: img21,
//   },
//   {
//     src: img22,
//   },
//   {
//     src: img23,
//   },
//   {
//     src: img24,
//   },
//   {
//     src: img25,
//   },
//   {
//     src: img26,
//   },
//   {
//     src: img27,
//   },
//   {
//     src: img28,
//   },
//   {
//     src: img29,
//   },
//   {
//     src: img30,
//   },
//   {
//     src: img31,
//   },
//   {
//     src: img32,
//   },
//   {
//     src: img33,
//   },
//   {
//     src: img34,
//   },
//   {
//     src: img35,
//   },
//   {
//     src: img36,
//   },
//   {
//     src: img37,
//   },
//   {
//     src: img38,
//   },
//   {
//     src: img39,
//   },
//   {
//     src: img40,
//   },
// ];
export default function Media() {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item.src);
    console.log(item, index);
  };

  const handelRotationRight = () => {
    const totalLength = photos.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = photos[0].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = photos.filter((item) => {
      return photos.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
    console.log(currentIndex, clickedImg);
  };

  const handelRotationLeft = () => {
    const totalLength = photos.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = photos[totalLength - 1].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = photos.filter((item) => {
      return photos.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
    console.log(currentIndex, clickedImg);
  };

  return (
    <Layout>
      <Helmet>
        <title>Media</title>
        <meta
          name="title"
          content="Alkaline Water Ioniser | Alkaline Water Purifier India"
        />
        <meta
          name="description"
          content="Find out how Ionia's cutting-edge Alkaline Water Ioniser can benefit your health. Transform water into a potent hydration source that energises your body and improves skin health."
        />
        <meta
          name="keywords"
          content="Alkaline Water Ioniser, Alkaline Water Purifier, Alkaline Water Purifier  India"
        />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta property="og:title" content="Alkaline Water Ioniser " />
        <meta property="og:site_name" content="https://ionia.co.in/media" />
        <meta property="og:url" content="https://ionia.co.in/media" />
        <meta
          property="og:description"
          content="Find out how Ionia's cutting-edge Alkaline Water Ioniser can benefit your health. Transform water into a potent hydration source that energises your body and improves skin health."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://ionia.co.in/media" />
      </Helmet>
      <ScrollToTop />
      <CommonBanner>Media</CommonBanner>
      <section className="gallery__wrapper pt-5">
        <Container>
          <div className="gallery__tabs__wrapper">
            <Tabs defaultActiveKey="Ionia Event" id="media__page__wrapper">
              <Tab
                eventKey="Ionia Event"
                title="Ionia Event"
                // className="ionia__event"
              >
                <div className="first__section__wrapper">
                  <Row>
                    {photos.map((el, index) => {
                      return (
                        <Col xs={6} md={3} className="mb-4">
                          <div className="gallery_img">
                            <picture>
                              <source srcSet={el.src} type="image/webp" />
                              <img
                                src={el.src}
                                alt=""
                                className="w-100 h-100 border  "
                                onClick={() => handleClick(el, index)}
                              />
                            </picture>
                          </div>
                        </Col>
                      );
                    })}
                    {clickedImg && (
                      <PatentModal
                        clickedImg={clickedImg}
                        handelRotationRight={handelRotationRight}
                        setClickedImg={setClickedImg}
                        handelRotationLeft={handelRotationLeft}
                      />
                    )}
                  </Row>
                </div>
              </Tab>
              {/* <Tab eventKey="Ionia india" title="Ionia india">
                <div>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <div className="title__wrap">
                          <Row>
                            {photos.map((el, index) => {
                              return (
                                <Col xs={6} md={3} className="mb-4">
                                  <div className="gallery_img">
                                    <picture>
                                      <source
                                        srcSet={el.src}
                                        type="image/webp"
                                      />
                                      <img
                                        src={el.src}
                                        alt=""
                                        className="w-100 h-100 border  "
                                        onClick={() => handleClick(el, index)}
                                      />
                                    </picture>
                                  </div>
                                </Col>
                              );
                            })}
                            {clickedImg && (
                              <PatentModal
                                clickedImg={clickedImg}
                                handelRotationRight={handelRotationRight}
                                setClickedImg={setClickedImg}
                                handelRotationLeft={handelRotationLeft}
                              />
                            )}
                          </Row>
                          <h2>Ionia india</h2>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Tab> */}
            </Tabs>
          </div>
        </Container>
      </section>
      <GetInTouch />
    </Layout>
  );
}
