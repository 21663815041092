import Layout from "../components/Layout";
import notfound from "../assets/images/error-404.webp";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";

export default function NotFound() {
  return (
    <>
      <Layout>
        <ScrollToTop />
        <section>
          <Container className="text-center">
            <picture>
              <source srcSet={notfound} type="image/webp" />
              <img src={notfound} alt="" className="m-auto" width={200} />
            </picture>
            <div className="text__wrap regular">
              <h2 className="mt-3 fs-1">Not Found</h2>
              <Link to="/" className="readmore__btn mt-2">
                Go back to home
              </Link>
            </div>
          </Container>
        </section>
      </Layout>
    </>
  );
}
