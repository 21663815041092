import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import CommonBanner from "../components/CommonBanner";
import ScrollToTop from "../components/ScrollToTop";
import { Container } from "react-bootstrap";

function Refundsndcancellations() {
  return (
    <Layout>
      <Helmet>
        <title>Refunds and Cancellations</title>
        <meta name="description" content="refunds and cancellation" />
      </Helmet>
      <CommonBanner>Refunds/Cancellations</CommonBanner>
      <ScrollToTop />
      <section>
        <Container>
          <h2>
            <strong>Refunds/Cancellations Policy for IONIA INDIA</strong>
          </h2>
          <p>
            At IONIA, we are committed to providing you with exceptional
            products and services. We understand that occasionally,
            circumstances may require you to cancel an order or seek a refund.
            Below, we outline our policy to ensure a transparent and hassle-free
            experience:
          </p>
          <h3>
            <strong>1. Order Cancellation:</strong>
          </h3>
          <p>
            If you wish to cancel an order, please contact our customer support
            team as soon as possible. Orders can only be canceled before they
            have been processed and shipped.
          </p>
          <p>Once an order has been processed, it cannot be canceled.</p>
          <h3>
            <strong>2. Refunds for Product Returns:</strong>
          </h3>
          <p>
            We offer a 7-day money-back guarantee on most of our products. If
            you found any manufacturing defect or product not performing as per
            the mentioned parameters, you may return it within 7 days of
            receiving it for a refund after deducting the Delivery and
            Installation charges.
          </p>
          <p>
            To be eligible for a refund, the product must be in its original
            condition, including all accessories and packaging.
          </p>
          <p>
            You are responsible for the cost of return shipping unless the
            product arrived damaged or defective.
          </p>
          <p>
            Refunds will be processed within [7] business days after we receive
            and inspect the returned product.
          </p>
          <h3>
            <strong>3. Warranty Claims:</strong>
          </h3>
          <p>
            IONIA products come with a manufacturer's warranty and extended
            warranty (if you buy extended warranty). If your product develops a
            defect within the warranty period, please contact us to initiate a
            warranty claim.
          </p>
          <p>
            Warranty coverage varies by product, so please refer to the specific
            product's warranty documentation for details on what is covered.
          </p>
          <p>
            Warranty claims will be subject to an evaluation by our technical
            team to determine the nature of the defect and whether it is covered
            by the warranty.
          </p>
          <h3>
            <strong>4. Damaged or Defective Products:</strong>
          </h3>
          <p>
            If you receive a damaged or defective product, please notify us
            within [2] days of receiving the item.We will arrange for the return
            of the damaged/defective product and provide you with a replacement
            or a refund, including return shipping costs.
          </p>
          <h3>
            <strong>5. Refunds Processing:</strong>
          </h3>
          <p>
            Refunds will be issued to the original payment method used for the
            purchase.
          </p>
          <p>
            Depending on your financial institution, it may take [10] business
            days for the refund to appear in your account.
          </p>
          <h3>
            <strong>6. Contact Us:</strong>
          </h3>
          <p>
            If you have any questions or need assistance with returns,
            cancellations, or warranty claims, please contact our customer
            support team at info@ionia.co.in.
          </p>
          <p>
            Please note that this policy is subject to change, and the terms and
            conditions outlined here are the most recent as of 1st September,
            2023. We encourage you to review this policy periodically for any
            updates.
          </p>
          <p>
            Your satisfaction is of utmost importance to us, and we strive to
            ensure a smooth and fair process for refunds, cancellations, and
            warranty claims. Thank you for choosing IONIA.
          </p>
        </Container>
      </section>
    </Layout>
  );
}

export default Refundsndcancellations;
