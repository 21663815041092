import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa6";
import { NavLink } from "react-router-dom";

function SocialLinks() {
  const links = [
    {
      id: "ionia-facebook",
      name: "facebook",
      icon: <FaFacebookF />,
      link: "https://www.facebook.com/ioniaindia",
    },
    {
      id: "ionia-instagram",
      name: "instagram",
      icon: <FaInstagram />,
      link: "https://www.instagram.com/ioniaindia/",
    },
    {
      id: "ionia-linkedin",
      name: "linkedin",
      icon: <FaLinkedinIn />,
      link: "https://www.linkedin.com/company/ionia-india-pvt-ltd/",
    },
    {
      id: "ionia-youtube",
      name: "youtube",
      icon: <FaYoutube />,
      link: "https://www.youtube.com/@ioniaindia9321",
    },
  ];

  return (
    <>
      <ul className={`social__links list-style-none p-0 m-0`}>
        {links.map((el, id) => {
          return (
            <li key={id}>
              <NavLink to={el.link} target="_blank">
                {el.icon}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default SocialLinks;
