import { Col, Container, Row } from "react-bootstrap";
import img4 from "../assets/images/products/en-7p/img4.webp";
import img3 from "../assets/images/products/io-400/img3.webp";
import img5 from "../assets/images/products/sm-S230/img5.webp";
import img2 from "../assets/images/products/x-blue/img2.webp";
import splash from "../assets/images/splash.webp";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import { useEffect } from "react";
export default function RelatedProducts({
  product1,
  product2,
  product3,
  product4,
}) {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <section className="product__wrapper">
      <Container>
      {!product4 ? (
          <section className="p-0 bg-none">
            <Container>
              <Row className="align-items-center">
                <Col
                  md={6}
                  className={`first__image text-center ${!product4 ? "" : ""}`}
                >
                  <picture>
                    <source srcSet={img2} type="image/webp" />
                    <img
                      src={img2}
                      alt="ionia"
                      className="product_image mt-5 mt-md-0"
                    />
                  </picture>
                  <div className="splash">
                    <picture>
                      <source srcSet={splash} type="image/webp" />
                      <img
                        src={splash}
                        className="wow zoomIn splash__img"
                        alt="chinte"
                      />
                    </picture>
                  </div>
                </Col>
                <Col md={6} className="content__box">
                  <div className="title__wrap">
                    <h2>
                      Ionia X-BLUE – Alkaline <span>Water Machine</span>
                    </h2>
                  </div>
                  <ul id="image" className="product_detail_info_list">
                    <li>5 plate (Planized Titanium)</li>
                    <li>Luxury exterior design</li>
                    <li>
                      Automatic reversed cleaning system causes platinum coated
                      with titanium electrode to have at least 20 years
                      longevity
                    </li>
                    <li>
                      Color LCD display easily identifies 3 different functions
                    </li>
                  </ul>
                  <Link
                    to="/products/ionia-x-blue-alkaline-water-machine"
                    className="readmore__btn"
                  >
                    Read More
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        ) : (
          ""
        )}
        
        {!product1 ? (
          <section className="p-0 bg-none en7p__wrapper ">
            <Container>
              <Row className="align-items-center">
                <Col md={6} className="first__image text-center">
                  <picture>
                    {" "}
                    <source srcSet={img4} type="image/webp" />
                    <img
                      src={img4}
                      alt="ionia"
                      className="product_image mt-5 mt-md-0"
                    />
                  </picture>
                  <div className="splash">
                    <picture>
                      <source srcSet={splash} type="image/webp" />
                      <img
                        src={splash}
                        className="wow zoomIn splash__img"
                        alt="chinte"
                      />
                    </picture>
                  </div>
                </Col>
                <Col md={6} className="content__box">
                  <div className="title__wrap">
                    <h2>
                      Ionia EN-7P – Alkaline<span> Water Machine </span>
                    </h2>
                  </div>
                  <ul id="" className="product_detail_info_list">
                    <li>7 Platinum-Titanium Plates</li>
                    <li>Automatic Draining System</li>
                    <li>250W SMPS Power Control System</li>
                  </ul>
                  <Link
                    to="/products/ionia-en-7p-alkaline-water-machine"
                    className="readmore__btn"
                  >
                    Read More
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        ) : (
          ""
        )}
        {!product2 ? (
          <section className="p-0 bg-none">
            <Container>
              <Row className="align-items-center">
                <Col
                  md={6}
                  className={`first__image  text-center ${!product2 ? "" : ""}`}
                >
                  <picture>
                    <source srcSet={img3} type="image/webp" />
                    <img
                      src={img3}
                      alt="ionia"
                      className="product_image mt-5 mt-md-0"
                    />
                  </picture>
                  <div className="splash">
                    <picture>
                      <source srcSet={splash} type="image/webp" />
                      <img
                        src={splash}
                        className="wow zoomIn splash__img"
                        alt="chinte"
                      />
                    </picture>
                  </div>
                </Col>
                <Col md={6} className="content__box">
                  <div className="title__wrap">
                    <h2>
                      Ionia IO-400U – Alkaline <span>Water Machine</span>
                    </h2>
                  </div>
                  <ul id="image" className="product_detail_info_list">
                    <li>
                      DARC System – Automatic change of flow path (6 Overseas
                      Patents)
                    </li>
                    <li>
                      Alarm message and ionization disabled during hot water
                      inflow.
                    </li>
                    <li>
                      Exact calculation of filter durability with Flux control.
                    </li>
                    <li>Extended range of pH selection and control.</li>
                    <li>Modern Design.</li>
                  </ul>
                  <Link
                    to="/products/ionia-iq-400u-alkaline-water-machine"
                    className="readmore__btn"
                  >
                    Read More
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        ) : (
          ""
        )}
        {!product3 ? (
          <section className="p-0 bg-none">
            <Container>
              <Row className="align-items-center">
                <Col
                  md={6}
                  className={`first__image  text-center ${!product3 ? "" : ""}`}
                >
                  <picture>
                    <source srcSet={img5} type="image/webp" />
                    <img
                      src={img5}
                      alt="ionia"
                      className="product_image mt-5 mt-md-0"
                    />
                  </picture>
                  <div className="splash">
                    <picture>
                      <source srcSet={splash} type="image/webp" />
                      <img
                        src={splash}
                        className="wow zoomIn splash__img "
                        alt="chinte"
                      />
                    </picture>
                  </div>
                </Col>
                <Col md={6} className="content__box">
                  <div className="title__wrap">
                    <h2>
                      Ionia SM-S230TL – Alkaline
                      <span>Water Machine</span>
                    </h2>
                  </div>
                  <ul id="image" className="product_detail_info_list">
                    <li>12(5P+7P) Platinum-Titanium Plates</li>
                    <li>Premium Dual Filtration</li>
                    <li>350W SMPS Power Control System</li>
                    <li>RFID Genuine filter chip</li>
                    <li>Super Strong Acidic (pH2.5~pH12)</li>
                  </ul>
                  <Link
                    to="/products/ionia-sm-s230tl-alkaline-water-machine"
                    className="readmore__btn"
                  >
                    Read More
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        ) : (
          ""
        )}
        
      </Container>
    </section>
  );
}
