import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import hydrateBody from "../assets/images/ph-levels/hydrate-body.webp";
import antiAging from "../assets/images/ph-levels/anti-aging.webp";
import weightLoss from "../assets/images/ph-levels/weight-loss.webp";
import kidney from "../assets/images/ph-levels/kidney.webp";
import blood from "../assets/images/ph-levels/blood.webp";
import immunesystem from "../assets/images/ph-levels/immunesystem.webp";
import healthySkin from "../assets/images/ph-levels/healthy-skin.webp";
import muscle from "../assets/images/ph-levels/muscle.webp";
import lowBate from "../assets/images/ph-levels/lowbate.webp";
import improveDigestion from "../assets/images/ph-levels/improve-digestion.webp";
import lubricateJoint from "../assets/images/ph-levels/lubricate-joint.webp";
import boneHealth from "../assets/images/ph-levels/bone-health.webp";
import moisturizedSKin from "../assets/images/ph-levels/moisturized-skin.webp";
import haircare from "../assets/images/ph-levels/hair-care.webp";
import releivesDryness from "../assets/images/ph-levels/dryness.webp";
import healthyscalp from "../assets/images/ph-levels/healthy-scalp.webp";
import toneupskin from "../assets/images/ph-levels/tone-up-skin.webp";
import cleaingEyeglass from "../assets/images/ph-levels/eyeglass-clean.webp";
import windowpane from "../assets/images/ph-levels/window-clean.webp";
import utensils from "../assets/images/ph-levels/demo.webp";
import antiBacterial from "../assets/images/ph-levels/anti-bacterial.webp";
import insectidel from "../assets/images/ph-levels/insectidel.webp";
import mouthFreshner from "../assets/images/ph-levels/mouth-freshner.webp";
import cooking from "../assets/images/ph-levels/cooking.webp";
import tastyFood from "../assets/images/ph-levels/food-tasty.webp";
import lessTimeCook from "../assets/images/ph-levels/less-time-cook.webp";
import unaturalTase from "../assets/images/ph-levels/unnatural-taste.webp";
import takingMedicine from "../assets/images/ph-levels/taking-medicne.webp";
import babyFormula from "../assets/images/ph-levels/baby-formula.webp";
import emulsifier from "../assets/images/ph-levels/emulsifier.webp";
import emulsifier2 from "../assets/images/ph-levels/emulsifier2.webp";
import soakMeat from "../assets/images/ph-levels/soaked-meat.webp";
import makeupRemoval from "../assets/images/ph-levels/makeup-removal.webp";
import phScale from "../assets/images/pH-scale.webp";

function PhLevel() {
  return (
    <div>
      <section className="different_ph_levels__wrapper">
        <Container>
          <Row>
            <Col>
              <div className="title__wrap center_aligned regular">
                <h2>Purpose Of Different pH level Water</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="p-3 bg-gray">
                <Row>
                  <Col className="text-center">
                    <h3 className="mt-0">
                      8 pH to 9 pH Drinking Water Helps in:
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Hydrate Your Body</h4>

                      <pitcure>
                        <source srcSet={hydrateBody} type="image/webp" />

                        <img
                          src={hydrateBody}
                          className="w-100 h-auto"
                          alt=""
                        />
                      </pitcure>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Anti-Oxidant (Anti aging)</h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={antiAging} type="image/webp" />

                          <img
                            src={antiAging}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </pitcure>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Weight Loss</h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={weightLoss} type="image/webp" />

                          <img
                            src={weightLoss}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </pitcure>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Improve Liver & Kidney Health </h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={kidney} type="image/webp" />

                          <img src={kidney} className="w-100 h-auto" alt="" />
                        </pitcure>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Maintain Viscosity of Blood</h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={blood} type="image/webp" />

                          <img src={blood} className="w-100 h-auto" alt="" />
                        </pitcure>
                      </div>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <h4>Improve Immune System</h4>
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <picture>
                          <source srcSet={immunesystem} type="image/webp" />

                          <img
                            src={immunesystem}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </picture>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <picture>
                          <source srcSet={healthySkin} type="image/webp" />

                          <img
                            src={healthySkin}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </picture>
                      </div>
                      <h4>Healthy skin</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={muscle} type="image/webp" />

                          <img src={muscle} className="w-100 h-auto" alt="" />
                        </pitcure>
                      </div>
                      <h4>Reduce Muscle Fatigue</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={lowBate} type="image/webp" />

                          <img src={lowBate} className="w-100 h-auto" alt="" />
                        </pitcure>
                      </div>
                      <h4>Lowbate Constipation</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <picture>
                          <source srcSet={improveDigestion} type="image/webp" />
                          <img
                            src={improveDigestion}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </picture>
                      </div>
                      <h4>Improve Digestion</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <picture>
                          <source srcSet={lubricateJoint} type="image/webp" />
                          <img
                            src={lubricateJoint}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </picture>
                      </div>
                      <h4>Lubricates Joints</h4>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="item">
                      <div
                        className="offset-md-0
                       
                       center"
                      >
                        <pitcure>
                          <source srcSet={boneHealth} type="image/webp" />

                          <img
                            src={boneHealth}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </pitcure>
                      </div>
                      <h4>Improve Bone Health</h4>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="border p-3 mt-4">
                <Row>
                  <Col className="text-center">
                    <h3 className="mt-0">
                      4.5 pH to 6 pH Beauty Water Helps in:
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                       center"
                  >
                    <div className="item">
                      <h4>Smooth Moisturized Skin</h4>

                      <picture>
                        <source srcSet={moisturizedSKin} type="image/webp" />

                        <img
                          src={moisturizedSKin}
                          className="w-100 h-auto "
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                       center"
                  >
                    <div className="item">
                      <h4>Hair Care</h4>
                      <picture>
                        <source srcSet={haircare} type="image/webp" />

                        <img src={haircare} className="w-100 h-auto " alt="" />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                     
                       center"
                  >
                    <div className="item">
                      <h4>Relieves Dryness</h4>
                      <picture>
                        <source srcSet={releivesDryness} type="image/webp" />

                        <img
                          src={releivesDryness}
                          className="w-100 h-auto   "
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                    
                       center"
                  >
                    <div className="item">
                      <h4>Healthy Scalp</h4>
                      <picture>
                        <source srcSet={healthyscalp} type="image/webp" />

                        <img
                          src={healthyscalp}
                          className="w-100 h-auto    "
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                     
                       center"
                  >
                    <div className="item">
                      <h4>Tone up your skin</h4>
                      <picture>
                        <source srcSet={toneupskin} type="image/webp" />

                        <img
                          src={toneupskin}
                          className="w-100 h-auto   "
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg 
                       center"
                  >
                    <div className="item">
                      <h4>Cleaning EyeGlass / Mirror</h4>
                      <picture>
                        <source srcSet={cleaingEyeglass} type="image/webp" />

                        <img
                          src={cleaingEyeglass}
                          className="w-100 h-auto"
                          alt=""
                        />
                      </picture>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    xs={6}
                    className="offset-md-0 col-lg
                     
                       center"
                  >
                    <div className="item">
                      <h4>Cleaning Window Pane</h4>
                      <picture>
                        <source srcSet={windowpane} type="image/webp" />

                        <img src={windowpane} className="w-100 h-auto" alt="" />
                      </picture>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col md={4} className="offset-md-0 center">
              <div className="border">
                <picture>
                  <source srcSet={phScale} type="image/webp" />
                  <img src={phScale} className="w-100 h-auto" alt="" />
                </picture>
                <h4 className="m-0 mt-3 bg-custom-primary text-white fw-600 text-center p-2 fs-6">
                  pH-scale
                </h4>
              </div>
            </Col>
            <Col md={8}>
              <Row>
                <Col md={12}>
                  <div className="bg-gray p-3">
                    <Row>
                      <Col>
                        <h3 className="mt-0">
                          2.5 pH Strong Acidic Water Helps in:
                        </h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3} xs={6}>
                        <div className="item">
                          <div className="offset-md-0 center">
                            <img
                              src={utensils}
                              className="w-100 h-auto"
                              alt=""
                            />
                          </div>
                          <h4>2.5 pH Sterilize Utensils</h4>
                        </div>
                      </Col>
                      <Col md={3} xs={6}>
                        <div className="item">
                          <div className="offset-md-0 center">
                            <img
                              src={antiBacterial}
                              className="w-100 h-auto"
                              alt=""
                            />
                          </div>
                          <h4>Anti-Bacterial</h4>
                        </div>
                      </Col>
                      <Col md={3} xs={6}>
                        <div className="item">
                          <div
                            className="offset-md-0
                                    
                       center"
                          >
                            <img
                              src={insectidel}
                              className="w-100 h-auto"
                              alt=""
                            />
                          </div>
                          <h4>Use as an Insecticidal</h4>
                        </div>
                      </Col>
                      <Col md={3} xs={6}>
                        <div className="item">
                          <div
                            className="offset-md-0
                                    
                       center"
                          >
                            <img
                              src={mouthFreshner}
                              className="w-100 h-auto"
                              alt=""
                            />
                          </div>
                          <h4>Mouth Freshner</h4>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="bg-gray p-3 mt-4">
                    <Row>
                      <Col>
                        <h3 className="mt-0">
                          9.0 pH to 10.5 pH Cooking Water Helps in:
                        </h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={3}
                        xs={6}
                        className="offset-md-0
                                    
                       center"
                      >
                        <div className="item">
                          <h4>Cooking</h4>
                          <img src={cooking} className="w-100 h-auto" alt="" />
                        </div>
                      </Col>
                      <Col
                        md={3}
                        xs={6}
                        className="offset-md-0
                                   
                       center"
                      >
                        <div className="item">
                          <h4>Making Food Tasty</h4>
                          <img
                            src={tastyFood}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col
                        md={3}
                        xs={6}
                        className="offset-md-0
                                
                       center"
                      >
                        <div className="item">
                          <h4>Less Time to Cook</h4>
                          <img
                            src={lessTimeCook}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col
                        md={3}
                        xs={6}
                        className="offset-md-0
                              
                       center"
                      >
                        <div className="item">
                          <h4>Remove Unnatural Taste</h4>
                          <img
                            src={unaturalTase}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <div className="p-3 border mt-4 h-100">
                <Row>
                  <Col>
                    <h3 className="mt-0">7.0 pH Neutral Water Helps in:</h3>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={6}
                    xs={6}
                    className="offset-md-0
                                 
                       center"
                  >
                    <div className="item">
                      <h4>Taking Medicine</h4>
                      <img
                        src={takingMedicine}
                        className="w-100 h-auto"
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col
                    md={6}
                    xs={6}
                    className="offset-md-0
                                  
                       center"
                  >
                    <div className="item">
                      <h4>Baby Formula</h4>
                      <img
                        src={babyFormula}
                        className="w-100 h-auto   "
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              md={8}
              className="offset-md-0
                                 
                       center"
            >
              <div className="border p-3 mt-4 h-100">
                <Row>
                  <Col className="text-end">
                    <h3 className="mt-0">
                      11.5 pH Emulsifier/Strong Alkaline Helps in:
                    </h3>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Col
                    md={6}
                    xs={12}
                    className="offset-md-0 
                                  
                       center"
                  >
                    <Row>
                      <Col md={6} xs={6} className="offset-md-0 center">
                        <div className="item">
                          <img
                            src={emulsifier}
                            className="w-100 h-auto "
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col
                        md={6}
                        xs={6}
                        className="offset-md-0
                                
                       center"
                      >
                        <div className="item">
                          <img
                            src={emulsifier2}
                            className="w-100 h-auto"
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col md={12} className="offset-md-0 center">
                        <div className="item">
                          <h4>
                            Emulsifier (Removes Colouring & Polish and
                            agriculture chemical in the vegetable and pulse
                            etc.)
                          </h4>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={3}
                    xs={6}
                    className="offset-md-0
                                   
                       center"
                  >
                    <div className="item">
                      <img
                        src={soakMeat}
                        className="w-100 h-auto     "
                        alt=""
                      />
                      <h4>
                        Soak Meat for 15 minutes to drain off blood & remove
                        smell
                      </h4>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    xs={6}
                    className="offset-md-0
                                    
                       center"
                  >
                    <div className="item">
                      <img
                        src={makeupRemoval}
                        className="w-100 h-auto"
                        alt=""
                      />
                      <h4>Can be used as makeup removal</h4>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default PhLevel;
