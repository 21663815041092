import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import thumb from "../../assets/images/blogs/thumb.webp";
import BannerImg from "../../assets/images/banner.webp";
import ScrollToTop from "../../components/ScrollToTop";
import { Link } from "react-router-dom";

export default function AlkalineWaterIonizerIndia() {
  return (
    <Layout>
      <Helmet>
        <title>
          Alkaline Water Ionizer India- Ionia Home All Posts...Alkaline Water
          Ionizer India- Ionia
        </title>
        <meta name="description" content="" />
      </Helmet>
      <ScrollToTop />
      <section className="inner__banner blog_detail_banner">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" />
        </picture>
        <Container>
          <Row>
            <Col>
              <h1>
                Alkaline Water Ionizer India- Ionia HomeAll Posts...Alkaline
                Water Ionizer India- Ionia
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="p-0">
        <Container>
          <Row>
            <Col>
              <Breadcrumb className="blog_breadcrumb">
                <Breadcrumb.Item active>
                  <Link to="/blog" className="text-decoration-none">
                    Blog
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Use Of Ionizer Water Machine – Good Or Bad?
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog_detail__wrapper">
        <Col
          md={8}
          className="shadow rounded-3 m-auto bg-white overflow-hidden"
        >
          <Row>
            <picture>
              <source srcSet={thumb} type="image/webp" />
              <img src={thumb} className="w-100" alt="" />
            </picture>
          </Row>
          <Row>
            <Col className="p-4">
              {/* COntent from here  */}
              <span className="tagline">Ionizer Machine</span>
              <h1 className="blog_title">
                Alkaline Water Ionizer India- Ionia HomeAll Posts...Alkaline
                Water Ionizer India- Ionia
              </h1>
              <div className="blog_info">
                <strong>Ionia Team - </strong>
                <span>07, 2021</span>
              </div>
              <div className="content">
                <p>
                  Many health advantages are associated with drinking ionized
                  water, such as better bone health, reduced risk of cancer and
                  heart disease, and a slower ageing process. Ionized water is a
                  readily accessible commercial version of alkaline water. The
                  assertions are not well-supported by research, and it’s not
                  clear whether the advantages persist over time.
                </p>
                <p>
                  The pH scale ranges from 0 to 14, with 0 being neutral and 14
                  being acidic. The carbon dioxide-bicarbonate-carbonate
                  equilibrium system regulates the pH of natural water, which is
                  typically between 6.5 and 8.5. Acidic water is created when
                  the concentration of dissolved carbon dioxide in the solution
                  increases. Alkaline water is made when carbon dioxide levels
                  are reduced, which raises the pH.s
                </p>
                <p>
                  Alkaline Water Ionizer India may be made more potable by
                  adding pH-raising additions, such as filters, faucet
                  attachments, and filters, which elevate the pH level of
                  ordinary tap water to an alkaline state.
                </p>
                <h3 style={{ fontWeight: 700, fontSize: "20px" }}>
                  Ionized water has a variety of applications.
                </h3>
                <p>
                  Drinking alkaline water may have health advantages, but it may
                  also be used for various other things.
                </p>
                <p>
                  Taking a bath in it has been proven to decrease skin damage
                  caused by UV light, which may help with various skin issues.
                </p>
                <p>
                  A healthy balance of proinflammatory and anti-inflammatory
                  cytokines is maintained using alkaline water (chemicals
                  regulating inflammation).
                </p>
                <p>
                  According to research, hemodialysis side effects such as
                  increased free radical production in patients with end-stage
                  renal disease may be reduced with the use of alkaline water.
                </p>
                <p>
                  To avoid the development of bladder stones, alkaline water
                  helps to expedite the elimination of melamine and keep it from
                  building up in the bladder.
                </p>
                <h3 style={{ fontWeight: 700, fontSize: "20px" }}>
                  Conclusion
                </h3>
                <p>
                  Alkaline water has many nutritional advantages, but strict
                  quality control is needed to maintain a medically appropriate
                  pH. Excessive consumption may harm the stomach’s natural
                  acids, killing beneficial microorganisms as one side effect.
                  Toxic effects on the skin, eyes and mucous membranes have been
                  reported using alkaline water.
                </p>
              </div>
              {/* COntent END here  */}
            </Col>
          </Row>
        </Col>
      </section>
    </Layout>
  );
}
