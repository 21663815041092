import React, { useEffect, useState } from "react";
import axios from "axios";
const GoogleReviews = ({ placeId, apikey }) => {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    async function fetchReviews() {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=reviews&key=${apikey}`
        );
        if (response.data.result.reviews) {
          setReviews(response.data.result.reviews);
        }
      } catch (error) {
        console.error("Error fetching google reviews", error);
      }
    }
    fetchReviews();
  }, [placeId, apikey]);
  return (
    <div>
      <h2>Google Reviews</h2>
      <ul>
        {reviews.map((review) => (
          <li key={review.time}>
            <p>{review.text}</p>
            <p>Rating: {review.rating}</p>
            <p>Author: {review.author_name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default GoogleReviews;
