import { Col, Container, Row } from "react-bootstrap";

export default function ExportingForCountries() {
  return (
    <>
      <section className="exporting_countries__wrapper p-0">
        <Container>
          <Row>
            <Col>
              <div className="title">
                <h2>
                  Exporting <span> 60 countries</span> for
                  <br />
                  about 40 <span>years</span>
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="exporting_countries__wrapper bottom">
        <Container>
          <Row className="justify-content-center">
            <Col
              xs={6}
              md={2}
              className="offset-md-0
              wow
              rubberBand
              center"
              data-wow-delay="0.5s"
            >
              <div className="item">
                <h3>
                  50<span>%</span>
                </h3>
                <p>America</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={2}
              className="offset-md-0
              wow
              rubberBand
              center"
              data-wow-delay="0.5s"
            >
              <div className="item">
                <h3>
                  20<span>%</span>
                </h3>
                <p>Europe</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={2}
              className="offset-md-0
              wow
              rubberBand
              center"
              data-wow-delay="0.5s"
            >
              <div className="item">
                <h3>
                  02<span>%</span>
                </h3>
                <p>Middle East</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={2}
              className="offset-md-0
              wow
              rubberBand
              center"
              data-wow-delay="0.5s"
            >
              <div className="item">
                <h3>
                  10<span>%</span>
                </h3>
                <p>Australia</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              xs={6}
              md={2}
              className="offset-md-0
              wow
              rubberBand
              center"
              data-wow-delay="0.5s"
            >
              <div className="item">
                <h3>
                  08<span>%</span>
                </h3>
                <p>Africa</p>
              </div>
            </Col>
            <Col
              xs={6}
              md={2}
              className="offset-md-0
              wow
              rubberBand
              center"
              data-wow-delay="0.5s"
            >
              <div className="item">
                <h3>
                  15<span>%</span>
                </h3>
                <p>South America</p>
              </div>
            </Col>
            {/* <Col
              xs={6}
              md={2}
              className="offset-md-0
              wow
              rubberBand
              center"
              data-wow-delay="0.5s"
            >
              <div className="item">
                <h3>
                  50<span>%</span>
                </h3>
                <p>America</p>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
}
