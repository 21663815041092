import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import ScrollToTop from "../components/ScrollToTop";
import CommonBanner from "../components/CommonBanner";
import { Container } from "react-bootstrap";

function Shippingnddeliverypolicy() {
  return (
    <Layout>
      <Helmet>
        <title>Shipping and Delivery Policy</title>
        <meta name="description" content="Shipping and Delivery Policy" />
      </Helmet>
      <CommonBanner>Shipping and Delivery Policy</CommonBanner>
      <ScrollToTop />
      <section>
        <Container>
          <h2>
            <strong>Shipping and Delivery Policy for IONIA INDIA</strong>
          </h2>
          <p>
            At IONIA, we are dedicated to providing you with the best products
            and services, including a seamless shopping and delivery experience.
            Here's everything you need to know about our shipping and delivery
            policy:
          </p>
          <h3>
            <strong>1. Order Processing:</strong>
          </h3>
          <p>
            Once you place an order with us, our team works diligently to
            process it promptly. Most orders are processed within 2 business
            days from the date of order placement, subject to product
            availability and order volume.
          </p>
          <h3>
            <strong>2. Shipping Destinations:</strong>
          </h3>
          <p>
            We offer shipping to addresses within India. Please note that
            delivery times and charges may vary depending on the destination.
          </p>
          <h3>
            <strong>3. Shipping Methods:</strong>
          </h3>
          <p>
            We already have franchise or company outlet and We partner with
            reputable courier services and logistics providers to ensure safe
            and efficient delivery of your orders.
          </p>
          <p>
            We offer standard and expedited shipping options. The availability
            of these options may vary based on your location.
          </p>
          <h3>
            <strong>4. Estimated Delivery Times:</strong>
          </h3>
          <p>
            Estimated delivery times are provided during the checkout process
            and may vary based on your location and the selected shipping method
            and it may take (2-7 working days)
          </p>
          <p>
            Please note that delivery times may be affected by external factors,
            such as weather conditions and public holidays.
          </p>
          <h3>
            <strong>5. Shipping Charges:</strong>
          </h3>
          <p>
            Shipping charges are calculated based on the total order value,
            destination, and selected shipping method. The exact shipping cost
            will be displayed during the checkout process or mention in the
            estimate.
          </p>
          <h3>
            <strong>6. Tracking Your Order:</strong>
          </h3>
          <p>
            Once your order is shipped, you will receive a shipping confirmation
            email with a tracking number. You can use this tracking number to
            monitor the progress of your shipment.
          </p>
          <h3>
            <strong>7. Delivery Attempts:</strong>
          </h3>
          <p>
            Our shipping partners will make multiple attempts to deliver your
            order to the provided shipping address. If delivery is unsuccessful
            after several attempts, the package may be returned to us
          </p>
          <h3>
            <strong>8. Shipping Damage:</strong>
          </h3>
          <p>
            If you receive a package that appears to be damaged during transit,
            please contact our customer support team immediately. We will assist
            you in filing a shipping damage claim and arranging for a
            replacement.
          </p>
          <h3>
            <strong>9. Undeliverable Addresses:</strong>
          </h3>
          <p>
            It is the responsibility of the customer to provide accurate and
            complete shipping information. Orders with incorrect or
            undeliverable addresses may be subject to additional shipping
            charges.
          </p>
          <h3>
            <strong>10. Contact Us:</strong>
          </h3>
          <p>
            If you have any questions or concerns about your order's shipping
            and delivery, please do not hesitate to contact our customer support
            team at info@ionia.co.in.
          </p>
          <p>
            Please note that this shipping and delivery policy is subject to
            change, and the terms and conditions outlined here are the most
            recent as of 1st September, 2023. We recommend reviewing this policy
            periodically for any updates.
          </p>
          <p>
            Thank you for choosing IONIA. We look forward to serving you and
            ensuring your products are delivered safely and efficiently.
          </p>
        </Container>
      </section>
    </Layout>
  );
}

export default Shippingnddeliverypolicy;
