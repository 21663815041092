import { Col, Container, Row } from "react-bootstrap";
import PatentLightBox from "./PatentLightbox";

export default function Patent() {
  return (
    <>
      <section className="patent__wrapper pt-5 pb-5">
        <Container>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular center_aligned">
                <h2>Patent</h2>
              </div>
            </Col>
            <Col md={12} className="offset-md-0 center">
              <PatentLightBox />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
