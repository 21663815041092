import Siemens from "../assets/images/siemens-logo.jpg";
import Logo from "../assets/images/logo_360.jpg";
import React from "react";

const PrintLayout = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <table border={3} className="component_table">
        <thead style={{ border: "1px solid black" }}>
          <tr>
            <th colSpan={2}>
              <img
                src={Siemens}
                alt="logo"
                style={{ width: "auto", height: "100px" }}
              />
            </th>
            <td
              colSpan={7}
              style={{
                color: "black",
                fontWeight: "bold",
                fontSize: "40px",
                textAlign: "center",
              }}
            >
              WIP REPORTS
            </td>
            <th colSpan={3}>
              <img
                src={Logo}
                alt="Logo"
                style={{
                  width: "auto",
                  height: "100px",
                  alignItems: "end",
                  textAlign: "end",
                }}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                color: "black",
                border: "1px solid black",
              }}
              colSpan={12}
            >
              <table width={"100%"}>
                <tr>
                  <td style={{ verticalAlign: "top", width: "55%" }}>
                    <table width={"100%"}>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#B8B8B8",
                          }}
                        >
                          Bill to
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            Addverb Technologies Limited
                          </strong>
                          <br />
                          Plot no. 5, sector 156 Phase II, New Okhla, <br />
                          Industrial Development Area, Gautam Budh Nagar, <br />
                          Uttar Pradesh, Greater Noida-201310, UP
                          <br />
                          <strong style={{ fontWeight: "bold" }}>
                            GSTIN :
                          </strong>{" "}
                          09AAOCA5226F2ZL,{" "}
                          <strong style={{ fontWeight: "bold" }}>
                            State Code
                          </strong>{" "}
                          : 09
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#B8B8B8",
                          }}
                        >
                          Ship to
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            Addverb Technologies Limited
                          </strong>
                          <br />
                          Plot no. 5, sector 156 Phase II, New Okhla, <br />
                          Industrial Development Area, Gautam Budh Nagar, <br />
                          Uttar Pradesh, Greater Noida-201310, UP
                          <br />
                          <strong style={{ fontWeight: "bold" }}>
                            GSTIN :
                          </strong>{" "}
                          09AAOCA5226F2ZL,{" "}
                          <strong style={{ fontWeight: "bold" }}>
                            State Code
                          </strong>{" "}
                          : 09
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ width: "45%", verticalAlign: "top" }}>
                    <table width={"100%"} border={2}>
                      <tr>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ fontWeight: "bold", textAlign: "left" }}
                        >
                          Dc Number:
                        </td>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ textAlign: "right" }}
                        >
                          DC232402000
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ fontWeight: "bold", textAlign: "left" }}
                        >
                          D.C. Date{" "}
                        </td>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ textAlign: "right" }}
                        >
                          03/02/2024
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{
                            backgroundColor: "#B8B8B8",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          P.O. Number
                        </td>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{
                            backgroundColor: "#B8B8B8",
                            textAlign: "right",
                          }}
                        >
                          BY MAIL
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{
                            backgroundColor: "#B8B8B8",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          P.O. Date :
                        </td>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{
                            backgroundColor: "#B8B8B8",
                            textAlign: "right",
                          }}
                        >
                          15/01/2024
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ textAlign: "left", fontWeight: "bold" }}
                        >
                          D.C. Date{" "}
                        </td>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ textAlign: "right" }}
                        >
                          03/02/2024
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ fontWeight: "bold", textAlign: "left" }}
                        >
                          P.O. Number:
                        </td>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ textAlign: "right" }}
                        >
                          BY MAIL
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{
                            backgroundColor: "#B8B8B8",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          P.O. Date :
                        </td>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{
                            backgroundColor: "#B8B8B8",
                            textAlign: "right",
                          }}
                        >
                          15/01/2024
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{
                            backgroundColor: "#B8B8B8",
                            textAlign: "left",
                            fontWeight: "bold",
                          }}
                        >
                          P.O. Date :
                        </td>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{
                            backgroundColor: "#B8B8B8",
                            textAlign: "right",
                          }}
                        >
                          15/01/2024
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ fontWeight: "bold", textAlign: "left" }}
                        >
                          P.O. Date :
                        </td>
                        <td
                          width={"50%"}
                          className="tbl_col"
                          style={{ textAlign: "right" }}
                        >
                          asha.kushwaha@addverb.in
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={12} width={"100%"}>
              {children}
            </td>
          </tr>
        </tbody>
        {/* Footer Start */}
        <tfoot>
        <tr>
          <td
            colSpan={12}
            style={{
              textAlign: "center",
              backgroundColor: "#B8B8B8",
              fontWeight: "bold",
              fontSize: "25px",
              border: "1px solid black",
            }}
          >
            Diagnostic Automation & Controls Private Limited
          </td>
        </tr>
        <tr>
          <td
            colSpan={4}
            style={{ border: "1px solid black", textAlign: "center" }}
          >
            Y-45, Okhla Industrial Area,
            <br />
            Phase-II, New Delhi - 110 020.
          </td>
          <td
            colSpan={3}
            style={{ border: "1px solid black", textAlign: "center" }}
          >
            <strong style={{ fontWeight: "bold" }}> Phone:</strong>{" "}
            +91-11-43104310
            <br />
            <strong style={{ fontWeight: "bold" }}>E-Mail:</strong>
            info@diag.in
          </td>
          <td
            colSpan={4}
            style={{ border: "1px solid black", textAlign: "center" }}
          >
            <strong style={{ fontWeight: "bold" }}> CIN:</strong>
            U74999DL2016PTC292312
            <br />
            <strong style={{ fontWeight: "bold" }}>GSTIN:</strong>
            07AAFCD6270P1Z5
          </td>
        </tr>
        </tfoot>
        {/* Footer End */}
      </table>
    </div>
  );
};

export default PrintLayout;
