import React from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaPhone, FaEnvelope, FaArrowRight } from "react-icons/fa6";
import classes from "./Header.module.css";
import { Link } from "react-router-dom";
import logo from "../assets/images/white-logo.webp";
export default function SubPageHeader({ franchiseName, mobileNumber }) {
  return (
    <header className="sub_page_header">
      <Container className={classes.top__bar}>
        <div className="right_log__wrapper">
          <div className="logo">
            <picture>
              <source srcSet={logo} type="image/webp" />
              <img src={logo} alt="ionia" />
            </picture>
            <span>{franchiseName}</span>
          </div>
        </div>

        <div className="right__bar">
          <ul className={`${classes.contact__links} list-style-none p-0 m-0`}>
            <li>
              <NavLink to="+91-9654455530">
                <FaPhone /> 
                <span>{mobileNumber}</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="mailto:sales@ionia.co.in">
                <FaEnvelope /> <span>sales@ionia.co.in</span>
              </NavLink>
            </li>
          </ul>
          <Link
            to="/"
            className="bg-light text-dark d-none d-md-block p-3 pt-1 pb-1 rounded"
          >
            <FaArrowRight/>
          </Link>
        </div>
      </Container>
    </header>
  );
}
