import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./Footer.module.css";
import logo from "../assets/images/footer-logo.webp";
import { FaAngleUp, FaEnvelope, FaPhone } from "react-icons/fa6";
import SocialLinks from "./social-links";
import { useAddress } from "../pages/subdomain/AddressContext";

export default function SubPageFooter({ CityAddress }) {
  const [scrollClass, setScrollClass] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 50) {
        setScrollClass(true);
      } else {
        setScrollClass(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollClass]);
  return (
    <>
      <footer className={`${classes.footer__wrapper} subpage_footer pb-0`}>
        <Container>
          <Row>
            <Col md={12}>
              <div className="footer__item">
                <picture>
                  <source srcSet={logo} type="image/webp" />
                  <img
                    src={logo}
                    height={30}
                    className={`${classes.footer__logo} mb-0`}
                    alt="Ionia"
                  />
                </picture>
                <div className="d-flex justify-align-center mt-1">
                  <SocialLinks  />
                </div>
                {CityAddress && (
                  <div className="city__address ">
                    <ul>
                      {CityAddress.map((address, index) => (
                        <li key={index}>{address.address}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </Col>
            <Col
              md={12}
              className="pt-4 pb-4 text-center mt-5"
              style={{ borderTop: "solid 1px rgb(255 255 255 / 13%)" }}
            >
              Ionia. All Rights Reserved &#169; {new Date().getFullYear()}
            </Col>
          </Row>
        </Container>
      </footer>
      <button
        className={`scroll_top_btn ${scrollClass ? "show" : "hide"}`}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <FaAngleUp />
      </button>
    </>
  );
}
