import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import cepatent1 from "../assets/images/patent/cepatent1.webp";
import gmppatent2 from "../assets/images/patent/gmp-patent2.webp";
import gcertipatent3 from "../assets/images/patent/g-certi-patent3.webp";
import cesmpatent4 from "../assets/images/patent/ce-sm-patent4.webp";
import kfdapatent5 from "../assets/images/patent/kfda-patent5.webp";
import isopatent6 from "../assets/images/patent/iso-patent6.webp";
import rohspatent7 from "../assets/images/patent/rohs-patent7.webp";
import sdspatent8 from "../assets/images/patent/sds-patent8.webp";
import testpatent9 from "../assets/images/patent/test-patent9.webp";
import xbluepatent10 from "../assets/images/patent/x-blue-patent10.webp";
import iso2patent11 from "../assets/images/patent/iso2-patent11.webp";
import teirpatent12 from "../assets/images/patent/teir-patent12.webp";
import PatentModal from "./PatentModal";

export default function PatentLightBox() {
  const photos = [
    {
      src: cepatent1,
    },
    {
      src: gmppatent2,
    },
    {
      src: gcertipatent3,
    },
    {
      src: cesmpatent4,
    },
    {
      src: kfdapatent5,
    },
    {
      src: isopatent6,
    },
    {
      src: rohspatent7,
    },
    {
      src: sdspatent8,
    },
    {
      src: testpatent9,
    },
    {
      src: xbluepatent10,
    },
    {
      src: iso2patent11,
    },
    {
      src: teirpatent12,
    },
  ];

  const [clickedImg, setClickedImg] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (item, index) => {
    setCurrentIndex(index);
    setClickedImg(item.src);
    console.log(item, index);
  };

  const handelRotationRight = () => {
    const totalLength = photos.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = photos[0].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = photos.filter((item) => {
      return photos.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
    console.log(currentIndex, clickedImg);
  };

  const handelRotationLeft = () => {
    const totalLength = photos.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = photos[totalLength - 1].src;
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = photos.filter((item) => {
      return photos.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].src;
    setClickedImg(newItem);
    setCurrentIndex(newIndex);
    console.log(currentIndex, clickedImg);
  };

  return (
    <>
      <Row>
        {photos.map((el, index) => {
          return (
            <Col xs={6} md={2} className="mb-4">
              <picture>
                <source srcSet={el.src} type="image/webp" />
                <img
                  src={el.src}
                  alt=""
                  className="w-100 h-100 border  "
                  onClick={() => handleClick(el, index)}
                />
              </picture>
            </Col>
          );
        })}
        {clickedImg && (
          <PatentModal
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
          />
        )}
      </Row>
    </>
  );
}
