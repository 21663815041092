/* global gtag */
import Layout from "../components/Layout";
// import CommonBanner from "../components/CommonBanner";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import ExportingForCountries from "../components/ExportingForCountries";
import GetInTouch from "../components/GetInTouch";
import { Helmet } from "react-helmet";
import RelatedProducts from "../components/RelatedProducts";
import ScrollToTop from "../components/ScrollToTop";
import BannerImg from "../assets/images/banner.webp";
import bannerThumb from "../assets/images/products/banner-thumb.webp";
import { useState } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import axios from "axios";
export default function Products() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isInputValid, setIsInputValid] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    pincode: "",
    address: "",
    header: "Live Demo Booking Enquiry",
  });

  const fetchAllocation = async () => {
    const obj = {
      notes: "From Website",
      processName: "Default Process",
      assignedTo: "+919654455530",
    };
    obj.customer = {
      name: formData.name,
      phoneNumber: formData.phone,
      company: {
        address: {
          street: formData.address,
          pincode: formData.pincode,
        },
      },
    };
    try {
      await axios
        .post("https://api.runo.in/v1/crm/allocation", obj, {
          headers: {
            "Content-Type": "application/json",
            "Auth-Key": "MWIybG41b2h5Mm9nNXFkM3U=",
          },
        })
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.phone === "" ||
      formData.address === "" ||
      formData.pincode === "" ||
      formData.phone.length < 10
    ) {
      setIsInputValid(false);
      return;
    }
    if (typeof gtag === "function") {
      gtag("event", "conversion", {
        send_to: "AW-437309363/JRVdCMOlsMkDELOfw9AB",
      });
    } else {
      console.error(
        "gtag is not defined. Make sure it is properly included in your project."
      );
    }

    fetchAllocation();
    // await axios
    //   .post(
    //     "https://api.interakt.ai/v1/public/message/",
    //     {
    //       countryCode: "+91",
    //       phoneNumber: formData.phone,

    //       callbackData: "hello",
    //       type: "Template",
    //       template: {
    //         name: "welcome_message",
    //         languageCode: "en",
    //         headerValues: [`${formData.name}`],
    //         fileName: "file_name.pdf",
    //         bodyValues: ["body_variable_value"],
    //       },
    //     },
    //     {
    //       headers: {
    //         Authorization:
    //           "Basic V1V0SE5wbW5RdmFsX24yMXppV0ttaGQzOWpnTTk1bnZ0Ym5iNjVuLTlrczo=",
    //       },
    //     }
    //   )
    //   .then((data) => {
    //     if (data.data.result) {
    //       setShow(true);
    //       setIsFormSubmitted(true);
    //       setFormData({
    //         name: "",
    //         phone: "",
    //         pincode: "",
    //         address: "",
    //       });
    //     }
    //   });
    await axios.post("https://commonapi.quickgst.in/event/email", formData);
    setIsInputValid(true);
    setShow(true);
    setIsFormSubmitted(true);
    setFormData({
      name: "",
      phone: "",
      pincode: "",
      address: "",
    });
  };
  return (
    <Layout>
      <Helmet>
        <title>
          Alkaline Water Ionizer Machine In India | Alkaline Water Ionizer
          Machine India | Water Ionizer Machine
        </title>
        <meta name="title" content="Alkaline Water Ioniser | Ionia Products" />
        <meta
          name="description"
          content="The Alkaline Water Ioniser from Ionia will change your water. Take advantage of today's health benefits from ionised, purified water."
        />
        <meta
          name="keywords"
          content="Alkaline Water Ioniser, Alkaline Water Ioniser delhi, Alkaline Water Ioniser  india, Alkaline Water Ioniser near me"
        />
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <meta property="og:title" content="Alkaline Water Ioniser" />
        <meta property="og:site_name" content="https://ionia.co.in/products" />
        <meta property="og:url" content="https://ionia.co.in/products" />
        <meta
          property="og:description"
          content="The Alkaline Water Ioniser from Ionia will change your water. Take advantage of today's health benefits from ionised, purified water"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://ionia.co.in/products" />
      </Helmet>
      <ScrollToTop />
      <Modal show={show} onHide={handleClose} dialogClassName="book_demo_form">
        <Modal.Body>
          {!isFormSubmitted ? (
            <>
              <h3>Book your Live Demo</h3>
              <Form>
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="demoName">
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                        value={formData.name}
                        name="name"
                      />
                      {!isInputValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">
                            Please enter your name
                          </span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Group className="mb-3" controlId="demoPhone">
                      <Form.Control
                        type="tel"
                        placeholder="Contact no."
                        maxLength="10"
                        onChange={(e) =>
                          setFormData({ ...formData, phone: e.target.value })
                        }
                        value={formData.phone}
                        name="phone"
                      />
                      {!isInputValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">
                            Please enter contact no.
                          </span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="demoAreaPin">
                      <Form.Control
                        type="tel"
                        placeholder="Enter Pin Code"
                        onChange={(e) =>
                          setFormData({ ...formData, pincode: e.target.value })
                        }
                        value={formData.pincode}
                        name="picode"
                      />
                      {!isInputValid && (
                        <Form.Text className="text-muted">
                          <span className="text-danger">
                            Please enter your comment
                          </span>
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="demoLocation">
                      <Form.Control
                        as="textarea"
                        placeholder="Enter address for live demo"
                        style={{
                          height: "100px",
                        }}
                        onChange={(e) =>
                          setFormData({ ...formData, address: e.target.value })
                        }
                        value={formData.address}
                        name="address"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Button className="submit" onClick={handleSubmitForm}>
                  Book Now
                </Button>
              </Form>
            </>
          ) : (
            <div className="connect_box text-center">
              <h5 className="m-0">
                Thank you for choosing <span>IONIA!</span>
              </h5>
              <p>We will get back to your soon.</p>
              <FaCircleCheck
                style={{
                  color: "#12c665",
                  fontSize: "30px",
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
      <section className="inner__banner product_banner_wrapper">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" className="banner" />
        </picture>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1>Products</h1>
              <p>
                IONIA products are synonymous with innovation and wellness,
                designed to elevate your daily hydration experience. Crafted
                with precision and backed by advanced technology, IONIA products
                redefine the way you consume water.
              </p>
              <ul
                id="image"
                className="product_detail_info_list column_count_2 w-50"
              >
                <li>EN-7P</li>
                <li>X-blue</li>
                <li>SM-230TL</li>
                <li>IO-400U</li>
              </ul>
              <Button
                onClick={handleShow}
                style={{
                  backgroundColor: "#bf2e1a",
                  border: "none",
                  marginTop: "10px",
                }}
              >
                Book Live Demo
              </Button>
            </Col>
            <Col md={5} className="offset-md-1">
              <div className="thumb mt-4 mt-md-0">
                <picture>
                  <source srcSet={bannerThumb} type="image/webp" />
                  <img src={bannerThumb} className="w-100" alt="electrodes" />
                </picture>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <CommonBanner>Products</CommonBanner> */}
      <RelatedProducts />
      <ExportingForCountries />
      <GetInTouch />
    </Layout>
  );
}

const products = [
  {
    io400: {
      title: "",
      tagline: "",
      description: "",
      link: "",
      image: "",
    },
  },
];
