import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import ScrollToTop from "../components/ScrollToTop";
import CommonBanner from "../components/CommonBanner";
import { Col, Container, Row } from "react-bootstrap";
import thumb4 from "../assets/images/blogs/thumb4.webp";
import { Link } from "react-router-dom";

function Privacyndpolicy() {
  return (
    <Layout>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Privacy and Policy" />
      </Helmet>
      <ScrollToTop />
      <CommonBanner>Privacy Policy</CommonBanner>
      <section className="privacyandpolicy__wrapper">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="title__wrap">
                <h2>Introduction</h2>
                <p>
                  Welcome to <strong>IONIA India.</strong>
                </p>
                <p>
                  <strong>IONIA India</strong> (“us”, “we”, or “our”) operates{" "}
                  <strong>ionia.co.in</strong> (hereinafter referred to as{" "}
                  <strong>“Service”</strong>).
                </p>
                <p>
                  Our Privacy Policy governs your visit to{" "}
                  <strong>ionia.co.in,</strong> and explains how we collect,
                  safeguard and disclose information that results from your use
                  of our Service.
                </p>
                <p>
                  We use your data to provide and improve Service. By using
                  Service, you agree to the collection and use of information in
                  accordance with this policy. Unless otherwise defined in this
                  Privacy Policy, the terms used in this Privacy Policy have the
                  same meanings as in our Terms and Conditions.
                </p>
                <p>
                  Our Terms and Conditions <strong>(“Terms”)</strong> govern all
                  use of our Service and together with the Privacy Policy
                  constitutes your agreement with us
                  <strong>(“agreement”)</strong>.
                </p>
              </div>
            </Col>
            <Col
              md={6}
              className="
              wow
              zoomIn
              center"
              data-wow-delay="0.5s"
            >
              <picture>
                <source srcSet={thumb4} type="image/webp" />
                <img
                  src={thumb4}
                  alt="ionia"
                  width={"100%"}
                  height={"100%"}
                  className="w-100 h-100s about_image mt-5 mt-md-0"
                />
              </picture>
            </Col>
          </Row>
          <Row className="align-items-center mt-4">
            <Col md={12}>
              <div className="title__wrap">
                <h2 className="fw-bold">Definitions</h2>
                <p>
                  <strong>SERVICE</strong> means the ionia.co.in website
                  operated by IONIA India.
                </p>
                <p>
                  <strong> PERSONAL DATA</strong> means data about a living
                  individual who can be identified from those data (or from
                  those and other information either in our possession or likely
                  to come into our possession).
                </p>
                <p>
                  <strong> USAGE DATA</strong> is data collected automatically
                  either generated by the use of Service or from Service
                  infrastructure itself (for example, the duration of a page
                  visit).
                </p>
                <p>
                  <strong> COOKIES </strong>are small files stored on your
                  device (computer or mobile device).
                </p>
                <p>
                  <strong> DATA CONTROLLER</strong> means a natural or legal
                  person who (either alone or jointly or in common with other
                  persons) determines the purposes for which and the manner in
                  which any personal data are, or are to be, processed. For the
                  purpose of this Privacy Policy, we are a Data Controller of
                  your data.
                </p>
                <p>
                  <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means
                  any natural or legal person who processes the data on behalf
                  of the Data Controller. We may use the services of various
                  Service Providers in order to process your data more
                  effectively.
                </p>
                <p>
                  <strong>DATA SUBJECT</strong> is any living individual who is
                  the subject of Personal Data.
                </p>
                <p>
                  <strong>THE USER</strong> is the individual using our Service.
                  The User corresponds to the Data Subject, who is the subject
                  of Personal Data.
                </p>
                <h2 className="fw-bold mt-4">Information Collection and Use</h2>
                <p>
                  We collect several different types of information for various
                  purposes to provide and improve our Service to you.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-4">
            <Col md={12}>
              <div className="title__wrap">
                <h2 className="fw-bold">Types of Data Collected</h2>
                <h3>
                  <strong>Personal Data</strong>
                </h3>
                <p>
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you (“Personal Data”). Personally
                  identifiable information may include, but is not limited to:
                </p>
                <ul>
                  <li>
                    <strong>0.1.</strong> Email address
                  </li>
                  <li>
                    <strong>0.2.</strong> First name and last name
                  </li>
                  <li>
                    <strong>0.3.</strong> Phone number
                  </li>
                  <li>
                    <strong>0.4.</strong> Address, Country, State, Province,
                    ZIP/Postal code, City
                  </li>
                  <li>
                    <strong>0.5.</strong> Cookies and Usage Data
                  </li>
                </ul>
                <p>
                  We may use your Personal Data to contact you with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to you. You may opt out of receiving any,
                  or all, of these communications from us by following the
                  unsubscribe link.
                </p>
                <h3>
                  <strong>Usage Data</strong>
                </h3>
                <p>
                  We may also collect information that your browser sends
                  whenever you visit our Service or when you access Service by
                  or through any device <strong>(“Usage Data”).</strong>
                </p>
                <p>
                  This Usage Data may include information such as your
                  computer’s Internet Protocol address (e.g. IP address),
                  browser type, browser version, the pages of our Service that
                  you visit, the time and date of your visit, the time spent on
                  those pages, unique device identifiers and other diagnostic
                  data.
                </p>
                <p>
                  When you access Service with a device, this Usage Data may
                  include information such as the type of device you use, your
                  device unique ID, the IP address of your device, your device
                  operating system, the type of Internet browser you use, unique
                  device identifiers and other diagnostic data.
                </p>
                <h3>
                  <strong>Tracking Cookies Data</strong>
                </h3>
                <p>
                  We use cookies and similar tracking technologies to track the
                  activity on our Service and we hold certain information.
                </p>
                <p>
                  Cookies are files with a small amount of data which may
                  include an anonymous unique identifier. Cookies are sent to
                  your browser from a website and stored on your device. Other
                  tracking technologies are also used such as beacons, tags and
                  scripts to collect and track information and to improve and
                  analyze our Service.
                </p>
                <p>
                  You can instruct your browser to refuse all cookies or to
                  indicate when a cookie is being sent. However, if you do not
                  accept cookies, you may not be able to use some portions of
                  our Service.
                </p>
                <p>Examples of Cookies we use:</p>
                <ul>
                  <li>
                    <strong>0.1. Session Cookies:</strong> We use Session
                    Cookies to operate our Service.
                  </li>
                  <li>
                    <strong>0.2. Preference Cookies:</strong> We use Preference
                    Cookies to remember your preferences and various settings.
                  </li>
                  <li>
                    <strong>0.3. Security Cookies:</strong> We use Security
                    Cookies for security purposes.
                  </li>
                  <li>
                    <strong>0.4. Advertising Cookies:</strong> Advertising
                    Cookies are used to serve you with advertisements that may
                    be relevant to you and your interests.
                  </li>
                </ul>
                <h3>
                  <strong>Other Data</strong>
                </h3>
                <p>
                  While using our Service, we may also collect the following
                  information: sex, age, date of birth, place of birth, passport
                  details, citizenship, registration at place of residence and
                  actual address, telephone number (work, mobile), details of
                  documents on education, qualification, professional training,
                  employment agreements, NDA agreements, information on bonuses
                  and compensation, information on marital status, family
                  members, social security (or other taxpayer identification)
                  number, office location and other data.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-5">
            <Col md={12} className="mb-4">
              <div className="title__wrap regular mb-3">
                <h2> Use of Data</h2>
                <p>
                  IONIA India uses the collected data for various purposes:
                </p>
              </div>
              <Row>
                <Col>
                  <Col md={12}>
                    <p>
                      <strong>0.1.</strong> to provide and maintain our Service;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong>0.2.</strong> to notify you about changes to our
                      Service;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong> 0.3.</strong> to allow you to participate in
                      interactive features of our Service when you choose to do
                      so;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong>0.4. </strong>to provide customer support;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong> 0.5. </strong>to gather analysis or valuable
                      information so that we can improve our Service;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong> 0.6.</strong> to monitor the usage of our
                      Service;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong>0.7. </strong>to detect, prevent and address
                      technical issues;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong>0.8.</strong> to fulfil any other purpose for
                      which you provide it;
                    </p>
                  </Col>
                </Col>
                <Col>
                  <Col md={12}>
                    <p>
                      <strong>0.9.</strong> to carry out our obligations and
                      enforce our rights arising from any contracts entered into
                      between you and us, including for billing and collection;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong>0.10. </strong>to provide you with notices about
                      your account and/or subscription, including expiration and
                      renewal notices, email-instructions, etc.;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong>0.11. </strong>to provide you with news, special
                      offers and general information about other goods, services
                      and events which we offer that are similar to those that
                      you have already purchased or enquired about unless you
                      have opted not to receive such information;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong>0.12. </strong>in any other way we may describe
                      when you provide the information;
                    </p>
                  </Col>
                  <Col md={12}>
                    <p>
                      <strong> 0.13.</strong> for any other purpose with your
                      consent.
                    </p>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular">
                <h2> Retention of Data</h2>
              </div>
              <Col md={12}>
                <div>
                  <p>
                    We will also retain Usage Data for internal analysis
                    purposes. Usage Data is generally retained for a shorter
                    period, except when this data is used to strengthen the
                    security or to improve the functionality of our Service, or
                    we are legally obligated to retain this data for longer time
                    periods.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular mt-4">
                <h2>Transfer of Data</h2>
              </div>
            </Col>
            <Col md={12}>
              <div>
                <p>
                  Your information, including Personal Data, may be transferred
                  to – and maintained on – computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ from those of your
                  jurisdiction.
                </p>
                <p>
                  If you are located outside India and choose to provide
                  information to us, please note that we transfer the data,
                  including Personal Data, to India and process it there.
                </p>
                <p>
                  Your consent to this Privacy Policy followed by your
                  submission of such information represents your agreement to
                  that transfer.
                </p>
                <p>
                  IONIA India will take all the steps reasonably necessary to
                  ensure that your data is treated securely and in accordance
                  with this Privacy Policy and no transfer of your Personal Data
                  will take place to an organisation or a country unless there
                  are adequate controls in place including the security of your
                  data and other personal information.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular mt-4">
                <h2>Disclosure of Data</h2>
                <p>
                  We may disclose personal information that we collect, or you
                  provide:
                </p>
              </div>
            </Col>
            <Col>
              <div>
                <p>
                  <strong>0.1. Disclosure for Law Enforcement.</strong>
                </p>
                <p>
                  Under certain circumstances, we may be required to disclose
                  your Personal Data if required to do so by law or in response
                  to valid requests by public authorities.
                </p>
                <p>
                  <strong>0.2. Business Transaction.</strong>
                </p>
                <p>
                  If we or our subsidiaries are involved in a merger,
                  acquisition or asset sale, your Personal Data may be
                  transferred.
                </p>
                <p>
                  <strong>
                    0.3. Other cases. We may disclose your information also:
                  </strong>
                </p>
                <ul>
                  <li>
                    <strong>0.3.1.</strong> to our subsidiaries and affiliates;
                  </li>
                  <li>
                    <strong>0.3.2.</strong> to contractors, service providers,
                    and other third parties we use to support our business;
                  </li>
                  <li>
                    <strong>0.3.3.</strong> to fulfill the purpose for which you
                    provide it;
                  </li>
                  <li>
                    <strong>0.3.4.</strong> for the purpose of including your
                    company’s logo on our website;
                  </li>
                  <li>
                    <strong> 0.3.5.</strong> for any other purpose disclosed by
                    us when you provide the information;
                  </li>
                  <li>
                    <strong>0.3.6.</strong> with your consent in any other
                    cases;
                  </li>
                  <li>
                    <strong> 0.3.7. </strong>if we believe disclosure is
                    necessary or appropriate to protect the rights, property, or
                    safety of the Company, our customers, or others.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular">
                <h2> Security of Data</h2>
              </div>
              <p>
                The security of your data is important to us but remember that
                no method of transmission over the Internet or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">
                  Your Data Protection Rights Under General Data Protection
                  Regulation (GDPR)
                </h3>
              </div>
            </Col>
            <Col>
              <div>
                <p>
                  If you are a resident of the European Union (EU) and European
                  Economic Area (EEA), you have certain data protection rights,
                  covered by GDPR.
                </p>
                <p>
                  We aim to take reasonable steps to allow you to correct,
                  amend, delete, or limit the use of your Personal Data.
                </p>
                <p>
                  If you wish to be informed what Personal Data we hold about
                  you and if you want it to be removed from our systems, please
                  email us at <strong>info@ionia.co.in.</strong>
                </p>
                <p>
                  In certain circumstances, you have the following data
                  protection rights:
                </p>
                <ul>
                  <li>
                    0.1. the right to access, update or to delete the
                    information we have on you;
                  </li>
                  <li>
                    0.2. the right of rectification. You have the right to have
                    your information rectified if that information is inaccurate
                    or incomplete;
                  </li>
                  <li>
                    0.3. the right to object. You have the right to object to
                    our processing of your Personal Data;
                  </li>
                  <li>
                    0.4. the right of restriction. You have the right to request
                    that we restrict the processing of your personal
                    information;
                  </li>
                  <li>
                    0.5. the right to data portability. You have the right to be
                    provided with a copy of your Personal Data in a structured,
                    machine-readable and commonly used format;
                  </li>
                  <li>
                    0.6. the right to withdraw consent. You also have the right
                    to withdraw your consent at any time where we rely on your
                    consent to process your personal information;
                  </li>
                </ul>
                <p>
                  Please note that we may ask you to verify your identity before
                  responding to such requests. Please note, we may not able to
                  provide Service without some necessary data.
                </p>
                <p>
                  You have the right to complain to a Data Protection Authority
                  about our collection and use of your Personal Data. For more
                  information, please contact your local data protection
                  authority in the European Economic Area (EEA).
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">
                  Your Data Protection Rights under the California Privacy
                  Protection Act (CalOPPA)
                </h3>
              </div>
              <Col>
                <div>
                  <p>
                    CalOPPA is the first state law in the nation to require
                    commercial websites and online services to post a privacy
                    policy. The law’s reach stretches well beyond California to
                    require a person or company in the United States (and
                    conceivable the world) that operates websites collecting
                    personally identifiable information from California
                    consumers to post a conspicuous privacy policy on its
                    website stating exactly the information being collected and
                    those individuals with whom it is being shared, and to
                    comply with this policy.
                  </p>
                  <p>According to CalOPPA we agree to the following:</p>
                  <ul>
                    <li>0.1. users can visit our site anonymously;</li>
                    <li>
                      0.2. our Privacy Policy link includes the word “Privacy”,
                      and can easily be found on the home page of our website;
                    </li>
                    <li>
                      0.3. users will be notified of any privacy policy changes
                      on our Privacy Policy Page;
                    </li>
                    <li>
                      0.4. users are able to change their personal information
                      by emailing us at <strong>info@ionia.co.in.</strong>
                    </li>
                  </ul>
                  <p>Our Policy on “Do Not Track” Signals:</p>
                  <p>
                    We honor Do Not Track signals and do not track, plant
                    cookies, or use advertising when a Do Not Track browser
                    mechanism is in place. Do Not Track is a preference you can
                    set in your web browser to inform websites that you do not
                    want to be tracked.
                  </p>
                  <p>
                    You can enable or disable Do Not Track by visiting the
                    Preferences or Settings page of your web browser.
                  </p>
                </div>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">
                  Your Data Protection Rights under the California Consumer
                  Privacy Act (CCPA)
                </h3>
              </div>
            </Col>
            <Col>
              <div>
                <p>
                  If you are a California resident, you are entitled to learn
                  what data we collect about you, ask to delete your data and
                  not to sell (share) it. To exercise your data protection
                  rights, you can make certain requests and ask us:
                </p>
                <p>
                  <strong>
                    0.1. What personal information we have about you. If you
                    make this request, we will return to you:
                  </strong>
                </p>
                <ul>
                  <li>
                    0.0.1. The categories of personal information we have
                    collected about you.
                  </li>
                  <li>
                    0.0.2. The categories of sources from which we collect your
                    personal information.
                  </li>
                  <li>
                    0.0.3. The business or commercial purpose for collecting or
                    selling your personal information.
                  </li>
                  <li>
                    0.0.4. The categories of third parties with whom we share
                    personal information.
                  </li>
                  <li>
                    0.0.5. The specific pieces of personal information we have
                    collected about you.
                  </li>
                  <li>
                    0.0.6. A list of categories of personal information that we
                    have sold, along with the category of any other company we
                    sold it to. If we have not sold your personal information,
                    we will inform you of that fact.
                  </li>
                  <li>
                    0.0.7. A list of categories of personal information that we
                    have disclosed for a business purpose, along with the
                    category of any other company we shared it with.
                  </li>
                </ul>
                <p>
                  Please note, you are entitled to ask us to provide you with
                  this information up to two times in a rolling twelve-month
                  period. When you make this request, the information provided
                  may be limited to the personal information we collected about
                  you in the previous 12 months.
                </p>
                <p>
                  <strong>
                    0.2. To delete your personal information. If you make this
                    request, we will delete the personal information we hold
                    about you as of the date of your request from our records
                    and direct any service providers to do the same. In some
                    cases, deletion may be accomplished through
                    de-identification of the information. If you choose to
                    delete your personal information, you may not be able to use
                    certain functions that require your personal information to
                    operate.
                  </strong>
                </p>
                <p>
                  <strong>
                    0.3. To stop selling your personal information. We don’t
                    sell or rent your personal information to any third parties
                    for any purpose. We do not sell your personal information
                    for monetary consideration. However, under some
                    circumstances, a transfer of personal information to a third
                    party, or within our family of companies, without monetary
                    consideration may be considered a “sale” under California
                    law. You are the only owner of your Personal Data and can
                    request disclosure or deletion at any time.
                  </strong>
                </p>
                <p>
                  If you submit a request to stop selling your personal
                  information, we will stop making such transfers.
                </p>
                <p>
                  Please note, if you ask us to delete or stop selling your
                  data, it may impact your experience with us, and you may not
                  be able to participate in certain programs or membership
                  services which require the usage of your personal information
                  to function. But in no circumstances, we will discriminate
                  against you for exercising your rights.
                </p>
                <p>
                  To exercise your California data protection rights described
                  above, please send your request(s) by email: info@ionia.co.in.
                </p>
                <p>
                  Your data protection rights, described above, are covered by
                  the CCPA, short for the California Consumer Privacy Act. To
                  find out more, visit the official California Legislative
                  Information website. The CCPA took effect on 01/01/2020.
                </p>
              </div>
            </Col>
            <Col md={12}>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">Service Providers</h3>
              </div>
              <p>
                We may employ third party companies and individuals to
                facilitate our Service (“<strong>Service Providers</strong>”),
                provide Service on our behalf, perform Service-related services
                or assist us in analysing how our Service is used.
              </p>
              <p>
                These third parties have access to your Personal Data only to
                perform these tasks on our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">Analytics</h3>
              </div>
              <p>
                We may use third-party Service Providers to monitor and analyze
                the use of our Service.
              </p>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">CI/CD tools</h3>
              </div>
              <p>
                We may use third-party Service Providers to automate the
                development process of our Service.
              </p>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">Behavioral Remarketing</h3>
              </div>
              <p>
                We may use remarketing services to advertise on third party
                websites to you after you visited our Service. We and our
                third-party vendors use cookies to inform, optimise and serve
                ads based on your past visits to our Service.
              </p>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">Links to Other Sites</h3>
              </div>
              <p>
                Our Service may contain links to other sites that are not
                operated by us. If you click a third party link, you will be
                directed to that third party’s site. We strongly advise you to
                review the Privacy Policy of every site you visit.
              </p>
              <p>
                We have no control over and assume no responsibility for the
                content, privacy policies or practices of any third party sites
                or services.
              </p>
              <p>
                For example, the outlined <Link to="">privacy policy</Link> has
                been made using
                <Link to="">PolicyMaker.io</Link>, a free tool that helps create
                high-quality legal documents. PolicyMaker’s{" "}
                <Link to="">privacy policy generator</Link> is an easy-to-use
                tool for creating a <Link to="">privacy policy for blog</Link>,
                website, e-commerce store or mobile app.
              </p>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">Children’s Privacy</h3>
              </div>
              <p>
                Our Services are not intended for use by children under the age
                of 18 (“Child” or “Children”).
              </p>
              <p>
                We do not knowingly collect personally identifiable information
                from Children under 18. If you become aware that a Child has
                provided us with Personal Data, please contact us. If we become
                aware that we have collected Personal Data from Children without
                verification of parental consent, we take steps to remove that
                information from our servers.
              </p>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">Changes to This Privacy Policy</h3>
              </div>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page.
              </p>
              <p>
                We will let you know via email and/or a prominent notice on our
                Service, prior to the change becoming effective and update
                “effective date” at the top of this Privacy Policy.
              </p>
              <p>
                You are advised to review this Privacy Policy periodically for
                any changes. Changes to this Privacy Policy are effective when
                they are posted on this page.
              </p>
              <div className="title__wrap regular mt-4">
                <h3 className="fw-bold">Contact Us</h3>
              </div>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us by email: <strong>info@ionia.co.in</strong>.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
}

export default Privacyndpolicy;
