import { Button, Col, Container, Form, Row, Modal } from "react-bootstrap";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollToTop from "../components/ScrollToTop";
import Patent from "../components/Patent";
import ExportingForCountries from "../components/ExportingForCountries";
import Certificates from "../components/Certificates";
import en7p from "../assets/images/products/en-7p/img4.webp";
import io400 from "../assets/images/products/io-400/img3.webp";
import smS230 from "../assets/images/products/sm-S230/img5.webp";
import XBlue from "../assets/images/products/x-blue/img2.webp";
import WOW from "wowjs";
import { useEffect, useState } from "react";
import comparison from "../assets/images/franchaises/comparison.webp";
import slide2 from "../assets/images/franchaises/franchise_banner1.webp";
import axios from "axios";
import { FaCircleCheck } from "react-icons/fa6";
import loader from "../assets/loader.gif";
import splash from "../assets/images/splash.webp";

export default function Franchises() {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  // const [isDisabled, setIsDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    address: "",
    pincode: "",
    annual_turnover: "",
    profession: "",
    invest_capacity: "",
    business_detail: "",
    invest_time: "",
    header:"Ionia Franchise Enquiry"
  });
  const [isFormValid, setIsFormValid] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const handleClose = () => {
    setModalShow(false);
  };
  const handleClick = () => {
    setIsDisable(true);
  };
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    if (
      formData.name === "" ||
      formData.city === "" ||
      formData.address === "" ||
      formData.pincode === "" ||
      formData.annual_turnover === "" ||
      formData.profession === "" ||
      formData.invest_capacity === "" ||
      formData.business_detail === "" ||
      formData.invest_time === "" ||
      formData.phone === "" ||
      formData.phone.length < 10
    ) {
      setIsFormValid(false);
      return;
    }
    setIsDisable(true);
    await axios
      .post(
        "https://api.interakt.ai/v1/public/message/",
        {
          countryCode: "+91",
          phoneNumber: formData.phone,
          callbackData: "hello",
          type: "Template",
          template: {
            name: "welcome_message",
            languageCode: "en",
            headerValues: [`${formData.name}`],
            fileName: "file_name.pdf",
            bodyValues: ["body_variable_value"],
          },
        },
        {
          headers: {
            Authorization:
              "Basic V1V0SE5wbW5RdmFsX24yMXppV0ttaGQzOWpnTTk1bnZ0Ym5iNjVuLTlrczo=",
          },
        }
      )
      .then((data) => {
        if (data.data.result) {
          setModalShow(true);
          setFormData({
            name: "",
            email: "",
            phone: "",
            city: "",
            address: "",
            pincode: "",
            annual_turnover: "",
            profession: "",
            invest_capacity: "",
            business_detail: "",
            invest_time: "",
          });
          setIsDisable(false);
        }
      });

    await axios.post(
      "https://commonapi.quickgst.in/event/email_form",
      formData
    );
    setIsFormValid(true);
  };
  console.log(formData);
  return (
    <Layout>
      <Helmet>
        <title>Franchise Opportunities &#8211; Ionia India</title>
        <meta name="description" content="" />
      </Helmet>
      <ScrollToTop />

      <section className="p-0">
        <picture>
          <source srcSet={slide2} type="image/webp" />
          <img src={slide2} alt="" className="w-100" />
        </picture>
      </section>
      <section className="pt-4">
        <Container>
          <Row className="align-items-center">
            <Col md={12}>
              <div className="franchise_form__wrapper__box mt-3 mt-md-0">
                <div className="form__wrapper">
                  <Form onSubmit={handleSubmitForm}>
                    <Row>
                      <Col>
                        <h3 className="text-center font-weight-bold">
                          DealerShip
                        </h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Row>
                          <Col md={12}>
                            <Form.Group className="mb-3" controlId="email">
                              <Form.Label>Name:</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Name"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    name: e.target.value,
                                  })
                                }
                                value={formData.name}
                              />
                              {!isFormValid && (
                                <Form.Text className="text-muted">
                                  <span className="text-danger">
                                    Enter your name{" "}
                                  </span>
                                </Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3" controlId="email">
                              <Form.Label>Email:</Form.Label>

                              <Form.Control
                                type="email"
                                placeholder="Enter Email"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    email: e.target.value,
                                  })
                                }
                                value={formData.email}
                              />
                              {!isFormValid && (
                                <Form.Text className="text-muted">
                                  <span className="text-danger">
                                    Enter valid email
                                  </span>
                                </Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3" controlId="MobileNo">
                              <Form.Label>Mob. Number:</Form.Label>

                              <Form.Control
                                type="tel"
                                placeholder="Enter Mobile No."
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    phone: e.target.value,
                                  })
                                }
                                value={formData.phone}
                              />
                              {!isFormValid && (
                                <Form.Text className="text-muted">
                                  <span className="text-danger">
                                    Enter valid number
                                  </span>
                                </Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3" controlId="Address">
                              <Form.Label>Address:</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Your Address"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    address: e.target.value,
                                  })
                                }
                                value={formData.address}
                              />
                              {!isFormValid && (
                                <Form.Text className="text-muted">
                                  <span className="text-danger">
                                    Enter valid address{" "}
                                  </span>
                                </Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3" controlId="City">
                              <Form.Label>City:</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter City"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    city: e.target.value,
                                  })
                                }
                                value={formData.city}
                              />
                              {!isFormValid && (
                                <Form.Text className="text-muted">
                                  <span className="text-danger">
                                    Enter valid city name{" "}
                                  </span>
                                </Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="annual turnover"
                            >
                              <Form.Label>Annual Turnover:</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Your annual turnover Rs."
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    annual_turnover: e.target.value,
                                  })
                                }
                                value={formData.annual_turnover}
                              />
                              {!isFormValid && (
                                <Form.Text className="text-muted">
                                  <span className="text-danger">
                                    Invalid Amount{" "}
                                  </span>
                                </Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Label>Profession:</Form.Label>
                            <Form.Select
                              aria-label="profession"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  profession: e.target.value,
                                })
                              }
                              value={formData.profession}
                            >
                              <option value="">Select</option>
                              <option value="Businessmen">Businessman</option>
                              <option value="Service">Service</option>
                              <option value="Professional">Professional</option>
                            </Form.Select>
                            {!isFormValid && (
                              <Form.Text className="text-muted">
                                <span className="text-danger">Select valid profession</span>
                              </Form.Text>
                            )}
                          </Col>
                          <Col md={12} className="mb-3">
                            <Form.Label>Investment Capacity:</Form.Label>
                            <Form.Select
                              id="Rs"
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  invest_capacity: e.target.value,
                                })
                              }
                              value={formData.invest_capacity}
                            >
                              <option value="">Select</option>
                              <option value="Rs.2,50,000 to Rs.6,00,000">
                                Rs.2,50,000 to Rs.6,00,000
                              </option>
                              <option value="Rs.6,00,000 to Rs.10,00,000">
                                Rs.6,00,000 to Rs.10,00,000
                              </option>
                              <option value="Rs.10,00,000 to Rs.25,00,000">
                                Rs.10,00,000 to Rs.25,00,000
                              </option>
                              <option value="Rs.50,00,000 & Rs.1,00,00,000">
                                Rs.50,00,000 to Rs.1,00,00,000
                              </option>
                            </Form.Select>
                            {!isFormValid && (
                              <Form.Text className="text-muted">
                                <span className="text-danger">
                                  Select valid capacity{" "}
                                </span>
                              </Form.Text>
                            )}
                          </Col>
                          <Col md={12}>
                            <div className="mb-2">
                              <Form.Label>
                                How Much Time You Need To Invest:
                              </Form.Label>
                              <Form.Select
                                id="time investment"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    invest_time: e.target.value,
                                  })
                                }
                                value={formData.invest_time}
                              >
                                <option value="">Select</option>
                                <option value="7 Days">7 Days</option>
                                <option value="15 Days">15 Days</option>
                                <option value="30 Days">30 Days</option>
                              </Form.Select>
                              {!isFormValid && (
                                <Form.Text className="text-muted">
                                  <span className="text-danger">
                                   Select valid time{" "}
                                  </span>
                                </Form.Text>
                              )}
                            </div>
                          </Col>
                          <Col md={12}>
                            <Form.Group className="mb-3 " controlId="Pincode">
                              <Form.Label>Pincode:</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Your Pincode"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    pincode: e.target.value,
                                  })
                                }
                                value={formData.pincode}
                              />
                              {!isFormValid && (
                                <Form.Text className="text-muted">
                                  <span className="text-danger">
                                   Enter valid pin{" "}
                                  </span>
                                </Form.Text>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={12}>
                        <Form.Group controlId="comment">
                          <Form.Label>Datails About Your Business:</Form.Label>
                          <Form.Control
                            as="textarea"
                            placeholder="Please Provide your Details about Your Business....."
                            style={{ height: "100px" }}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                business_detail: e.target.value,
                              })
                            }
                            value={formData.business_detail}
                          />
                          {!isFormValid && (
                            <Form.Text className="text-muted">
                              <span className="text-danger">
                                Add valid details{" "}
                              </span>
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Button
                          className="readmore__btn mt-4"
                          disabled={isDisable}
                          // onClick={handleClick}
                          type="submit"
                        >
                          Submit
                        </Button>
                        {isDisable && (
                          <picture>
                            <source srcSet={loader} type="gif/image" />
                            <img
                              src={loader}
                              alt="loader"
                              className="loader ms-4"
                            />
                          </picture>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* section third */}
      {/* <section className="live__bg__ionia pb-0">
        <Container>
          <Row>
            <Col className="text-center text-black">
              <div className="items">
                <h2 className="text-black">
                  <strong>Live Better with Live Water</strong>
                </h2>
              </div>
              <Link to="" className="readmore__btn">
                Franchise Opportunities
              </Link>
            </Col>
          </Row>
        </Container>
      </section> */}
      {/* section fourth */}
      <section className="comparison__water">
        <Container>
          <Row>
            <Col>
              <div className="text_camparison">
                <h2 className="text-center">
                  <strong>Let's have some Comparison</strong>
                </h2>
              </div>
              <div className="text-center">
                <picture>
                  <source srcSet={comparison} type="image/webp" />
                  <img
                    src={comparison}
                    alt="ionia"
                    className="h-75 comparision_img m-auto"
                  />
                </picture>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section className="who_we_are__wrapper">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <div className="title__wrap">
                <h2>Why Lorem Ipsum Is Used?</h2>
              </div>
              <p>
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                ornare tempus aliquet. Pellentesque finibus, est et iaculis
                suscipit, dolor nulla commodo dui, nec ultricies arcu nisl
                tristique eros. Morbi eros est, pulvinar eget ornare ac,
                ultrices eget risus. Ut lobortis pellentesque pretium. Praesent
                sollicitudin vestibulum iaculis.
              </p>
              <p>
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                ornare tempus aliquet. Pellentesque finibus, est et iaculis
                suscipit, dolor nulla commodo dui, nec ultricies arcu nisl
                tristique eros. Morbi eros est, pulvinar eget ornare ac,
                ultrices eget risus. Ut lobortis pellentesque pretium. Praesent
                sollicitudin vestibulum iaculis.
              </p>
            </Col>

            <Col
              md={6}
              className="
              wow
              zoomIn
              center"
              data-wow-delay="0.5s"
            >
              <picture>
                <source srcSet={aboutImage} type="image/webp" />
                <img
                  src={aboutImage}
                  alt="ionia"
                  width={"100%"}
                  height={"100%"}
                  className="w-100 h-100s about_image mt-5 mt-md-0"
                />
              </picture>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="product__wrapper__franchise">
        <Container>
          <Row>
            <Col md={3}>
              <div className="first__product__en7p text-center">
                <picture>
                  <source srcSet={splash} type="image/webp" />
                  <img
                    src={splash}
                    alt=""
                    className="w-100 mb-3   wow
                   zoomIn
                   center splash_bg"
                    data-wow-delay="0.5s"
                  />
                </picture>
                <picture>
                  <source srcSet={en7p} type="image/webp" />
                  <img src={en7p} alt="" className="w-100 h-100 mb-3  " />
                </picture>
                <Link to="" className="readmore__btn__pd mt-5">
                  EN7P
                </Link>
              </div>
            </Col>
            <Col md={3}>
              <div className="second__product__1o400u text-center">
                <picture>
                  <source srcSet={splash} type="image/webp" />
                  <img
                    src={splash}
                    alt=""
                    className="w-100 mb-3   wow
                   zoomIn
                   center splash_bg"
                    data-wow-delay="0.5s"
                  />
                </picture>
                <picture>
                  <source srcSet={io400} type="image/webp" />
                  <img
                    src={io400}
                    alt=""
                    className="w-100 mb-3"
                    data-wow-delay="1.5s"
                  />
                </picture>
                <Link to="" className="readmore__btn__pd mt-5">
                  IO400U
                </Link>
              </div>
            </Col>
            <Col md={3}>
              <div className="third__product__sms230 text-center">
                <picture>
                  <source srcSet={splash} type="image/webp" />
                  <img
                    src={splash}
                    alt=""
                    className="w-100 mb-3   wow
                   zoomIn
                   center splash_bg"
                    data-wow-delay="0.5s"
                  />
                </picture>
                <picture>
                  <source srcSet={smS230} type="image/webp" />
                  <img
                    src={smS230}
                    alt=""
                    className="w-100 mb-3"
                    data-wow-delay="2s"
                  />
                </picture>
                <Link to="" className="readmore__btn__pd mt-5">
                  SMS230
                </Link>
              </div>
            </Col>
            <Col md={3}>
              <div className="foruth__product__xblue text-center">
                <picture>
                  <source srcSet={splash} type="image/webp" />
                  <img
                    src={splash}
                    alt=""
                    className="w-100 mb-3   wow
                   zoomIn
                   center splash_bg"
                    data-wow-delay="0.5s"
                  />
                </picture>
                <picture>
                  <source srcSet={XBlue} type="image/webp" />
                  <img
                    src={XBlue}
                    alt=""
                    className="w-100 mb-3"
                    data-wow-delay="2.5s"
                  />
                </picture>
                <Link to="" className="readmore__btn__pd mt-5">
                  X-BLUE
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <ExportingForCountries />
      <Patent />
      <Certificates />
      <section className="p-0 lh-0">
        <div className="map lh-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.7294259338732!2d77.0977698!3d28.637870399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4ccf744ffe7%3A0xa1fc9e8b33fa563c!2sIonia%20India%20-%20Alkaline%20water%20ionizer%20South%20Korea!5e0!3m2!1sen!2sin!4v1688120167485!5m2!1sen!2sin"
            width="100%"
            height="375"
            title="googleaddress"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>

      <Modal
        size="md"
        aria-labelledby="connect-us"
        centered
        show={modalShow}
        onHide={handleClose}
        onClick={handleClick}
        backdropClassName="connect__overlay"
      >
        <Modal.Body>
          <div className="connect_box w-100 text-center">
            <h5 className="m-0">
              Thank you for choosing <span>IONIA!</span>
            </h5>
            <p>We will get back to your soon.</p>
            <FaCircleCheck
              style={{
                color: "#12c665",
                fontSize: "30px",
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}
