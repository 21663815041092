import Layout from "../../components/Layout";
import CommonBanner from "../../components/CommonBanner";
import {
  Col,
  Container,
  Row,
  Carousel,
  Table,
  // Form,
  // Button,
  // FloatingLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import RelatedProducts from "../../components/RelatedProducts";
import ScrollToTop from "../../components/ScrollToTop";
import img1 from "../../assets/images/products/x-blue/img1.webp";
import img2 from "../../assets/images/products/x-blue/img2.webp";
import xblue2 from "../../assets/images/products/x-blue/xblue2.webp";
import xblue3 from "../../assets/images/products/x-blue/xblue3.webp";
import xblue4 from "../../assets/images/products/x-blue/xblue4.webp";
import xblue5 from "../../assets/images/products/x-blue/xblue5.webp";
import xblue6 from "../../assets/images/products/x-blue/xblue6.webp";
import ioniaxblue from "../../assets/images/products/x-blue/ionia-x-blue.mp4";
import ReactPlayer from "react-player";
import BannerImg from "../../assets/images/banner.webp";

export default function XBlue() {
  return (
    <Layout>
      <Helmet>
        <title>
          Ionia X-BLUE &#8211; Alkaline Water Machine &#8211; Ionia India
        </title>
        <meta
          name="description"
          content="5 plate (Planized Titanium)
 	Luxury exterior design
 	Automatic reversed cleaning system causes platinum coated with titanium electrode to have at least 20 years longevity
 	Color LCD display easily identifies 3 different functions"
        />
      </Helmet>
      <ScrollToTop />
      <section className="inner__banner health_benefit_banner">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" />
        </picture>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1>Ionia X-BLUE – Alkaline Water Machine</h1>
            </Col>
            <Col md={6}>
              <div className="rounded-4 overflow-hidden lh-0 video_box">
                <ReactPlayer
                  url={ioniaxblue}
                  playing
                  loop
                  controls={false}
                  muted
                  width="100%"
                  height="100%"
                  autoPlay
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product_detail_info silder__wrapper">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <Carousel indicators={false} pause={false}>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={img2} type="image/webp" />
                          <img
                            src={img2}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={xblue2} type="image/webp" />
                          <img
                            src={xblue2}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={xblue3} type="image/webp" />
                          <img
                            src={xblue3}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={xblue4} type="image/webp" />
                          <img
                            src={xblue4}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={xblue5} type="image/webp" />
                          <img
                            src={xblue5}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={xblue6} type="image/webp" />
                          <img
                            src={xblue6}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col md={6}>
              <div className="title__wrap regular mb-5 mt-3 mt-md-0">
                <h2>Ionia X-BLUE – Alkaline Water Machine</h2>
              </div>
              <div className="tag">
                <h4>
                  <strong>Category</strong> : Water Ionizer
                </h4>
                <h4>
                  <strong>Product ID</strong> : 1734
                </h4>
              </div>
              <ul className="product_detail_info_list">
                <li>5 plate (Planized Titanium)</li>
                <li>Luxury exterior design</li>
                <li>
                  Automatic reversed cleaning system causes platinum coated with
                  titanium electrode to have at least 20 years longevity
                </li>
                <li>
                  Color LCD display easily identifies 3 different functions
                </li>
              </ul>
              <Link to="/rnd" className="readmore__btn ">
                Reseaerch On This Product
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product_detail_desc p-0">
        <Container>
          <Row className="mt-4 mb-3">
            <Col md={12}>
              <div className="title__wrap regular mb-4">
                <h2 className="underline">Description</h2>
              </div>
              <p>
                <strong>5P Planum – Titanium Plates:</strong> 5 Proprietary
                electrodes with 4 layers of planum ensuring the best ionizer
                enriched water
              </p>
              <p>
                <strong>Color LED Display:</strong> It is designed to generate
                ionized water ranged at stage from 1 through 4 by manipulating
                buttons in the control panel in the front side Color LCD display
                easily identifies 3 different functions (Blue Alkaline water,
                Orange: Acidic water, Green: Filtered water)
              </p>
              <p>
                <strong>All-in-one type of water supply valve:</strong> It is
                easy to use and convenient to control the fine flow rate as the
                water supply valve (valve to turn water on/off) is integrated
                into the product body.
              </p>
              <p>
                <strong>
                  The difference of filter technology makes water cleaner and
                  healthier:
                </strong>{" "}
                It filters the harmful substance and keeps the beneficial
                mineral in water to make water clean and rich in minerals
              </p>

              <div className="title__wrap regular mb-4 mt-5">
                <h2 className="underline">Specification</h2>
              </div>
              <div className="speci">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th colSpan={4}>Specification</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Power Supply</strong>
                      </td>
                      <td>Transformer</td>
                      <td>
                        <strong>Power Consumption</strong>
                      </td>
                      <td>Max 150W</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Dimension</strong>
                      </td>
                      <td>272 x 356 x 160(W x H x D)mm</td>
                      <td>
                        <strong>Electrode</strong>
                      </td>
                      <td>5 plate (Planized Titanium)</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Electrolysis Setting</strong>
                      </td>
                      <td>
                        Alkaline (4 Level), Purified Water, Acidic (4 Level)
                      </td>
                      <td>
                        <strong>pH value</strong>
                      </td>
                      <td>pH 5.0(±0.5)~10(±0.5)</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Cleaning</strong>
                      </td>
                      <td>Automatic Cleaning</td>
                      <td>
                        <strong>Display Method</strong>
                      </td>
                      <td>One Module</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Number Of Filters</strong>
                      </td>
                      <td>Two Filter</td>
                      <td>
                        <strong>Filter Media</strong>
                      </td>
                      <td>Activated Carbon Filter</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Filter Indicator</strong>
                      </td>
                      <td>LED Indicator</td>
                      <td>
                        <strong>ON/OFF Valve</strong>
                      </td>
                      <td>Manual Valve</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <RelatedProducts
        product1={false}
        product2={false}
        product3={false}
        product4={true}
      />
    </Layout>
  );
}
