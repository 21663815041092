import Layout from "../../components/Layout";
import CommonBanner from "../../components/CommonBanner";
import {
  Col,
  Container,
  Row,
  Carousel,
  Table,
  Form,
  Button,
  FloatingLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import RelatedProducts from "../../components/RelatedProducts";
import ScrollToTop from "../../components/ScrollToTop";
// import img1 from "../../assets/images/products/sm-S230/img1.webp";
// import img2 from "../../assets/images/products/sm-S230/img2.webp";
import img3 from "../../assets/images/products/sm-S230/img3.webp";
import img4 from "../../assets/images/products/sm-S230/img4.webp";
import img5 from "../../assets/images/products/sm-S230/img5.webp";
import sms1 from "../../assets/images/products/sm-S230/sms1.webp";
import sms2 from "../../assets/images/products/sm-S230/sms2.webp";
import sms3 from "../../assets/images/products/sm-S230/sms3.webp";
import ReactPlayer from "react-player";
import ioniasm230 from "../../assets/images/products/sm-S230/ionia-sm230.mp4";
import BannerImg from "../../assets/images/banner.webp";

export default function Sm230() {
  return (
    <Layout>
      <Helmet>
        <title>
          Ionia SM-S230TL &#8211; Alkaline Water Machine &#8211; Ionia India
        </title>
        <meta
          name="description"
          content="12(5P+7P) Platinum-Titanium Plates
 	Premium Dual Filtration
 	350W SMPS Power Control System
 	RFID Genuine filter chip
 	Super Strong Acidic (pH2.5~pH12)"
        />
      </Helmet>
      <ScrollToTop />
      <section className="inner__banner health_benefit_banner">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" />
        </picture>
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h1>Ionia SM-S230TL – Alkaline Water Machine</h1>
            </Col>
            <Col md={6}>
              <div className="rounded-4 overflow-hidden lh-0 video_box">
                <ReactPlayer
                  url={ioniasm230}
                  playing
                  loop
                  controls={false}
                  muted
                  width="100%"
                  height="100%"
                  autoPlay
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product_detail_info silder__wrapper">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <Carousel indicators={false} pause={false}>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={img5} type="image/webp" />
                          <img
                            src={img5}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={sms1} type="image/webp" />
                          <img
                            src={sms1}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          <source srcSet={sms2} type="image/webp" />
                          <img
                            src={sms2}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          <source srcSet={sms3} type="image/webp" />
                          <img
                            src={sms3}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item interval={4000}>
                  <Row className="justify-content-center">
                    <Col>
                      <div className="product_slide_image">
                        <picture>
                          {" "}
                          <source srcSet={img3} type="image/webp" />
                          <img
                            src={img3}
                            className="thumb__image"
                            alt="Electrodes"
                          />
                        </picture>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col md={6}>
              <div className="title__wrap regular mb-5 mt-3 mt-md-0">
                <h2>Ionia SM-S230TL – Alkaline Water Machine</h2>
              </div>
              <div className="tag">
                <h4>
                  <strong>Category</strong> : Water Ionizer
                </h4>
                <h4>
                  <strong>Product ID</strong> : 1805
                </h4>
              </div>
              <ul className="product_detail_info_list">
                <li>12(5P+7P) Platinum-Titanium Plates</li>
                <li>Premium Dual Filtration</li>
                <li>350W SMPS Power Control System</li>
                <li>RFID Genuine filter chip</li>
                <li>Super Strong Acidic (pH2.5~pH12)</li>
              </ul>
              <Link to="/rnd" className="readmore__btn ">
                Reseaerch On This Product
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product_detail_desc p-0">
        <Container>
          <Row className="mt-4 mb-3">
            <Col md={12}>
              <div className="title__wrap regular mb-4">
                <h2 className="underline">Description</h2>
              </div>
              <p>
                <strong>Performance of Electrodes</strong> In their latest
                models are five of the most advanced planum-titanium electrodes
                in the world. When a cross-section of an electrode is examined
                at 700 times magnification, you can see that the electrodes are
                now covered in a super fine mesh with very district points and
                valleys. This greatly increases the surface area without having
                to increase the size. We guarantee that no other models,
                regardless of price, will produce under similar conditions, such
                a high and low pH or ORP (Oxidation-reduction potential).
              </p>
              <p>
                <strong>Super Strong Alkaline & Acidic pH2.5˜pH12:</strong>
                By using 12plates, maximized electrolysis from pH 2.5 to pH 12,
                so that strong alkaline and strong acidic can be discharged.
              </p>
              <p>
                <strong>
                  <h4>3.2″ TFT LCD</h4>
                </strong>
              </p>
              <p>
                <strong>Convenience of Use</strong> The one-touch water supply
                system (3.2″ TFT LCD) maximize the convenience of use the water
                supply system supplies a certain amount of water to maintain
                constant water pH.
              </p>
              <p>
                <strong>11 Step Level Configurable</strong> It is possible to
                set 11 steps in total (Acidic water 4 levels, Purified Water
                Strong Acidic Water, and Strong Alkaline Water).
              </p>
              <div className="title__wrap regular mb-4">
                <h2 className="underline">Dual Filteration</h2>
              </div>
              <p>
                The difference of filter technology makes water cleaner and
                healthier: It filters the harmful substances and keeps the
                beneficial in water to make water clean and rich in minerals.
              </p>
              <div className="title__wrap regular mb-4 mt-5">
                <h2 className="underline">Specification</h2>
              </div>
              <div className="speci">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th colSpan={4}>Specification</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Power Supply</strong>
                      </td>
                      <td>SMS</td>
                      <td>
                        <strong>Power Consumption</strong>
                      </td>
                      <td>Max 250W</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Dimension</strong>
                      </td>
                      <td>376 x 435 x 185 (W x H x D)mm</td>
                      <td>
                        <strong>Electrode</strong>
                      </td>
                      <td>12 plate (Planized Titanium) / 5P + 7P</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Electrolysis Setting</strong>
                      </td>
                      <td>
                        Alkaline (4 Level), Purified Water, Acidic (4 Level),
                        Strong Alkaline Water, Strong Acidic Water
                      </td>
                      <td>
                        <strong>pH value</strong>
                      </td>
                      <td>pH 2.5(±0.5)~11.5(±0.5)</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Cleaning</strong>
                      </td>
                      <td>Automatic Cleaning</td>
                      <td>
                        <strong>Display Method</strong>
                      </td>
                      <td>TFT LCD (3.2 inch)</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Number Of Filters</strong>
                      </td>
                      <td>Two Filter</td>
                      <td>
                        <strong>Filter Media</strong>
                      </td>
                      <td>Activated Carbon Filter, UF Membrane Filter</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Filter Indicator</strong>
                      </td>
                      <td>LCD Graphic Display</td>
                      <td>
                        <strong>ON/OFF Valve</strong>
                      </td>
                      <td>Manual Valve</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <RelatedProducts
        product1={false}
        product2={false}
        product3={true}
        product4={false}
      />
    </Layout>
  );
}
