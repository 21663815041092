import SubPageFooter from "./SubPageFooter";
import SubPageHeader from "./SubPageHeader";

export default function SubPageLayout({ children, name, number, address }) {
  return (
  <>
      <SubPageHeader franchiseName={name} mobileNumber={number}  />

      {children}

      <SubPageFooter CityAddress={address}/>
      </>
  );
}
