/* global gtag */
import axios from "axios";
import { useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { FaCircleCheck } from "react-icons/fa6";
import loader from "../assets/loader.gif";

export default function GetInTouch() {
	const [formData, setFormData] = useState({
		name: "",
		phone: "",
		comment: "",
		header: "Ionia Enquiry",
	});
	const [isInputValid, setIsInputValid] = useState(true);
	const [modalShow, setModalShow] = useState(false);
	const [isDisable, setIsDisable] = useState(false);
	const handleClose = () => {
		setModalShow(false);
	};
	const fetchAllocation = async () => {
		const obj = {
			notes: "From Website",
			processName: "Default Process",
			assignedTo: "+919654455530",
		};
		obj.customer = {
			name: formData.name,
			phoneNumber: formData.phone,
			notes: formData.comment,
		};
		try {
			await axios
				.post("https://api.runo.in/v1/crm/allocation", obj, {
					headers: {
						"Content-Type": "application/json",
						"Auth-Key": "MWIybG41b2h5Mm9nNXFkM3U=",
					},
				})
				.then((data) => {
					console.log(data);
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (err) {
			console.log(err);
		}
	};
	const handleSubmitForm = async (e) => {
		e.preventDefault();
		if (
			formData.name === "" ||
			formData.phone === "" ||
			formData.comment === "" ||
			formData.phone.length < 10
		) {
			setIsInputValid(false);
			return;
		}
		setIsDisable(true);
		fetchAllocation();
		// await axios
		// 	.post(
		// 		"https://api.interakt.ai/v1/public/message/",
		// 		{
		// 			countryCode: "+91",
		// 			phoneNumber: formData.phone,

		// 			callbackData: "hello",
		// 			type: "Template",
		// 			template: {
		// 				name: "welcome_message",
		// 				languageCode: "en",
		// 				headerValues: [`${formData.name}`],
		// 				fileName: "file_name.pdf",
		// 				bodyValues: ["body_variable_value"],
		// 			},
		// 		},
		// 		{
		// 			headers: {
		// 				Authorization:
		// 					"Basic V1V0SE5wbW5RdmFsX24yMXppV0ttaGQzOWpnTTk1bnZ0Ym5iNjVuLTlrczo=",
		// 			},
		// 		}
		// 	)
		// 	.then((data) => {
		// 		if (data.data.result) {
		// 			setModalShow(true);
		// 			setFormData({
		// 				name: "",
		// 				phone: "",
		// 				comment: "",
		// 			});
		// 			setIsDisable(false);
		// 		}
		// 	});
		await axios.post("https://commonapi.quickgst.in/event/email", formData);
		setIsInputValid(true);
		setModalShow(true);
					setFormData({
						name: "",
						phone: "",
						comment: "",
					});
					setIsDisable(false);
	};
	return (
		<section className="contact_us__wrapper">
			<Modal
				size="md"
				aria-labelledby="connect-us"
				centered
				show={modalShow}
				onHide={handleClose}
				backdropClassName="connect__overlay"
			>
				<Modal.Body>
					<div className="connect_box w-100 text-center">
						<h5 className="m-0">
							Thank you for choosing <span>IONIA!</span>
						</h5>
						<p>We will get back to your soon.</p>
						<FaCircleCheck
							style={{
								color: "#12c665",
								fontSize: "30px",
							}}
						/>
					</div>
				</Modal.Body>
			</Modal>
			<Container>
				<Row>
					<Col md={6}>
						<div className="map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.7294259338732!2d77.0977698!3d28.637870399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4ccf744ffe7%3A0xa1fc9e8b33fa563c!2sIonia%20India%20-%20Alkaline%20water%20ionizer%20South%20Korea!5e0!3m2!1sen!2sin!4v1688120167485!5m2!1sen!2sin"
								width="100%"
								height="100%"
								title="googleaddress"
								allowFullScreen=""
								loading="lazy"
								referrerPolicy="no-referrer-when-downgrade"
							></iframe>
						</div>
					</Col>
					<Col md={6}>
						<div className="title__wrap regular text-end">
							<span>Contact form</span>
							<h2>
								Its Time to Protect <br />
								Your Family
							</h2>
						</div>
						<Form onSubmit={handleSubmitForm}>
							<Row>
								<Col md={6}>
									<Form.Group className="mb-3" controlId="email">
										<Form.Control
											type="text"
											placeholder="Enter name"
											onChange={(e) =>
												setFormData({ ...formData, name: e.target.value })
											}
											value={formData.name}
											name="name"
										/>
										{!isInputValid && (
											<Form.Text className="text-muted">
												<span className="text-danger">
													Please enter your name
												</span>
											</Form.Text>
										)}
									</Form.Group>
								</Col>
								<Col md={6}>
									<Form.Group className="mb-3" controlId="email">
										<Form.Control
											type="tel"
											maxLength="10"
											placeholder="Enter contact no."
											onChange={(e) =>
												setFormData({ ...formData, phone: e.target.value })
											}
											value={formData.phone}
											name="phone"
										/>
										{!isInputValid && (
											<Form.Text className="text-muted">
												<span className="text-danger">
													Please enter valide contact no.
												</span>
											</Form.Text>
										)}
									</Form.Group>
								</Col>
								<Col md={12}>
									<Form.Group className="mb-3" controlId="comment">
										<Form.Control
											as="textarea"
											placeholder="Add Comments"
											style={{ height: "100px" }}
											value={formData.comment}
											onChange={(e) =>
												setFormData({
													...formData,
													comment: e.target.value,
												})
											}
											name="comment"
										/>
										{!isInputValid && (
											<Form.Text className="text-muted">
												<span className="text-danger">
													Please enter your comment
												</span>
											</Form.Text>
										)}
									</Form.Group>
								</Col>
								{/* <Col md={12}>
                  <Form.Group className="mb-3" controlId="agree">
                    <Form.Check
                      type="checkbox"
                      label="I agree that my submitted data is being collected and stored."
                    />
                  </Form.Group>
                </Col> */}
							</Row>
							<div className="d-flex align-items-center">
							<Button
                    variant="primary"
                    className="submit"
                    type="submit"
                    disabled={isDisable}
                    onClick={(e) => {
                      handleSubmitForm(e);
                      if (typeof gtag === "function") {
                        gtag("event", "conversion", {
                          send_to: "AW-437309363/JRVdCMOlsMkDELOfw9AB",
                        });
                      } else {
                        console.error(
                          "gtag is not defined. Make sure it is properly included in your project."
                        );
                      }
                    }}
                  >
                    Submit
                  </Button>
								{isDisable && (
									<picture>
										<source srcSet={loader} type="gif/image" />
										<img src={loader} alt="loader" className="loader ms-4" />
									</picture>
								)}
							</div>
						</Form>
					</Col>
				</Row>
			</Container>
		</section>
	);
}
