import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import thumb from "../../assets/images/blogs/thumb5.webp";
import BannerImg from "../../assets/images/banner.webp";
import ScrollToTop from "../../components/ScrollToTop";
import { Link } from "react-router-dom";

export default function WaterIonizerMachine() {
  return (
    <Layout>
      <Helmet>
        <title> Water Ionizer Machine Incredible benefits</title>
        <meta name="description" content="" />
      </Helmet>
      <ScrollToTop />
      <section className="inner__banner blog_detail_banner">
        <picture>
          <source srcSet={BannerImg} type="image/webp" />
          <img src={BannerImg} alt="Wave" />
        </picture>
        <Container>
          <Row>
            <Col>
              <h1>
                {" "}
                <span>Water Ionizer Machine:</span> Incredible benefits
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="p-0">
        <Container>
          <Row>
            <Col>
              <Breadcrumb className="blog_breadcrumb">
                <Breadcrumb.Item active>
                  <Link to="/blog" className="text-decoration-none">
                    Blog
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  Water Ionizer Machine Incredible benefits{" "}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog_detail__wrapper">
        <Col
          md={8}
          className="shadow rounded-3 m-auto bg-white overflow-hidden"
        >
          <Row>
            <picture>
              <source srcSet={thumb} type="image/webp" />
              <img src={thumb} className="w-100" alt="" />
            </picture>
          </Row>
          <Row>
            <Col className="p-4">
              {/* COntent from here  */}
              <span className="tagline">
                Water Filtration & Ionizer Machine
              </span>
              <h1 className="blog_title">
                Water Ionizer Machine: Incredible benefits
              </h1>
              <div className="blog_info">
                <strong>Ionia Team - </strong>
                <span>February 22, 2023</span>
              </div>
              <div className="content">
                <p>
                  All that matters is consuming the good, may it be pure water,
                  fresh air, or healthy food. In the same context, alkaline
                  water is the new invention for the betterment of the rest of
                  our lives. Boosting our immunity and neutralizing body acidity
                  are not the only benefits of the{" "}
                  <span>water ionizer machine,</span>
                  however, it comes with a plethora of more benefits too. Scroll
                  down to know a few of those.
                </p>
                <ul>
                  <li>
                    The machine assists us in the maintenance of our body’s pH
                    balance along with providing required buffers to the body.
                    To live a healthy life, the protective mechanisms of
                    ionizers can surely be a wonder as the induced buffers help
                    us in getting rid of fat that is again an upshot.
                  </li>
                  <li>
                    Secondly, water ionizers add antioxidant properties that
                    help in relieving a lot of illnesses. Consuming alkaline
                    water daily can bring many health benefits to our bodies. I
                    used Ionia machine and witnessed incredible results myself,
                    so one must try it.
                  </li>
                  <li>
                    The best to know is that alkaline water coming from a{" "}
                    <span>water ionizer machine</span>tastes very much better
                    than filtered water or any other form of drinkable water.
                  </li>
                  <li>
                    With bulk healing properties, alkaline water enables our
                    body to fight diseases and sickness. The consumption of a
                    lot of acidic components in the form of junk and oily food
                    may affect our health badly but healthy water can help a
                    person in balancing everything.
                  </li>
                  <li>
                    Moreover, it is also essential to know that ionized water
                    has a higher absorption rate than normal water. The hydrogen
                    present in it makes the alkaline water a great antioxidant
                    that breaks into slender plasters very effortlessly. Leading
                    to this, slim plasters easily pass through the cell
                    membranes and enter into the cells with ease.
                  </li>
                </ul>
                <p>
                  Being known to fact that our body comprises 70% water, it
                  becomes essential for us to go for a{" "}
                  <span>water ionizer machine</span> as alkaline water as it
                  keeps our brain and body free from most of the diseases.
                </p>
              </div>
              {/* COntent END here  */}
            </Col>
          </Row>
        </Col>
      </section>
    </Layout>
  );
}
